<div class="d-flex flex-row w-100">
	<button
		class="btn btn-primary flex-grow-1 lh-adjust"
		*ngIf="(selectedVariant?.inventory_quantity > 0 && selectedVariant?.inventory_policy !== 'continue') || selectedVariant?.inventory_policy === 'continue'"
		(click)="addToCart($event)"
	>
		{{ _message }} <span class="mx-2">•</span> {{ selectedVariant.price * quantity | currency }}
	</button>

	<button class="btn btn-no-stock w-100 lh-adjust disabled" disabled *ngIf="selectedVariant?.inventory_quantity <= 0 && selectedVariant?.inventory_policy !== 'continue'">
		Out of stock
	</button>

	<button class="btn btn-primary lh-adjust" *ngIf="!selectedVariant">Select Option</button>
	<app-wishlist-toggle [product]="product" *ngIf="showWishlistToggle"></app-wishlist-toggle>
</div>
<div class="d-flex mt-2 outOfStock" *ngIf="selectedVariant?.available == false && selectedVariant?.inventory_policy !== 'continue'">
	<div class="input-group" *ngIf="!_outOfStockResult">
		<input [disabled]="_outOfStockLoading" placeholder="Email" class="form-control" [(ngModel)]="email" />
		<button [disabled]="_outOfStockLoading" class="btn btn-primary flex-shrink-1" (click)="outOfStock()">
			{{ _outOfStockLoading ? 'Submitting' : 'Notify Me' }}
		</button>
	</div>
	<div *ngIf="_outOfStockResult" class="result mt-2 text-center p-3 w-100" [ngClass]="{ error: _outOfStockError }">
		{{ _outOfStockResult }}
	</div>
</div>
