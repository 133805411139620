import { Component, EventEmitter, Input, Output } from '@angular/core';

export type LineItem = { 
	title: string
	quantity: number,
	image: string, 
	customAttributes: {
	  key: string,
	  value: string
	},
	price: number,
	currency: string,
	imageUrl: string,
	altText: string,
	weight: number
}

export type Order = { 
	name: string,
	fulfillmentStatus: string,
	payment: string,
	orderNumber: string,
	processedAt: string,
	trackingNumber: string,
	totalPriceV2: { amount: number } // The sum of all the prices of all the items in the order, taxes and discounts included (must be positive).
	summary: LineItem[],
	subtotal: number,
	currency: string
}

@Component({
  selector: 'app-recent-orders',
  templateUrl: './recent-orders.component.html',
  styleUrls: ['./recent-orders.component.scss']
})
export class RecentOrdersComponent {
	@Input() orders: Order[] = []
	@Input() showViewAllBtn = false
	@Output() viewOrder = new EventEmitter<Order>()
  	@Output() viewAllOrders = new EventEmitter()

	view(order: Order) {
		this.viewOrder.emit(order)
	}

}
