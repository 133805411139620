import { Directive, ElementRef, HostListener } from '@angular/core'
import { SafariCollectionFixService } from 'src/app/services/safaricollectionfix.service'
import { UtilityService } from 'src/app/services/utility.service'

@Directive({ selector: 'img:not(.notlazy)' })
export class LazyImgDirective {
	window: any
	constructor(private element: ElementRef<HTMLImageElement>, private utilityService: UtilityService, private safariCollectionFix: SafariCollectionFixService) {
		this.window = this.utilityService.getWindow()
		if (this.utilityService.getWindow()) {
			if (!this.element.nativeElement.src) {
				this.element.nativeElement.src = 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
			}
			const supports = 'loading' in HTMLImageElement.prototype
			if (supports && safariCollectionFix.shouldUseLazyDirective()) {
				if (this.isElementInViewport(this.element.nativeElement)) {
					this.element.nativeElement.setAttribute('loading', 'eager')
				} else {
					this.element.nativeElement.setAttribute('loading', 'lazy')
				}
			}
		}
	}

	@HostListener('error')
	error() {
		this.element.nativeElement.src = 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII='
	}

	isElementInViewport(el: HTMLImageElement) {
		if (this.window && el?.getBoundingClientRect) {
			var rect = el.getBoundingClientRect()

			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (this.window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (this.window.innerWidth || document.documentElement.clientWidth)
			)
		}
		return false
	}
}
