<app-outdated-browser></app-outdated-browser>
<div *ngIf="theme?.body1">
	<header #headerEl class="relative py-2 pt-lg-3 pb-lg-2">
		<div class="container-fluid">
			<div class="row">
				<div class="col-3 col-md-3 d-flex justify-content-start">
					<app-mobile-menu-toggle class="my-auto pe-2 d-lg-none actionable"></app-mobile-menu-toggle>
					<app-search-toggle class="my-auto ps-lg-0 ps-2 actionable"></app-search-toggle>
					<a class="fs-10 lh-1 my-auto ms-3 d-none d-lg-block relative" routerLink="pages/wishlist">
						Wishlist
						<div *ngIf="wishlist?.events?.length > 0" class="dot"></div>
					</a>
				</div>

				<div class="col-6 col-md-6 justify-content-center d-flex align-items-center">
					<div #logo id="logo">
						<a [routerLink]="['/']" class="no-special">
							<svg
								version="1.1"
								id="Layer_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 1020.47 240.94"
								width="100"
								style="enable-background: new 0 0 1020.47 240.94"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M889.85,217.36c-56.68,0-99.43-41.55-99.43-96.64v-0.51c0-54.47,43.9-97.15,99.94-97.15c56.69,0,99.44,41.55,99.44,96.64
				C989.8,174.69,945.89,217.36,889.85,217.36z M890.36,33.64c-50.11,0-89.36,38.03-89.36,86.57v0.51c0,49.06,38.2,86.07,88.85,86.07
				c50.11,0,89.37-38.03,89.37-86.57C979.22,70.64,941.02,33.64,890.36,33.64z M890.46,200.05c-44.43,0-79.24-35.07-79.24-79.83
				c0-45.8,33.76-79.83,78.54-79.83c44.44,0,79.25,35.07,79.25,79.83v0.68C969.01,166.02,935.24,200.05,890.46,200.05z M889.76,50.95
				c-38.75,0-67.97,29.48-67.97,68.58c0,40.16,29.52,69.94,68.67,69.94c38.75,0,67.97-29.48,67.97-68.58v-0.68
				C958.43,80.73,928.91,50.95,889.76,50.95z M890.36,181.33c-33.13,0-59.08-26.84-59.08-61.12c0-35.06,25.18-61.12,58.57-61.12
				c33.14,0,59.09,26.84,59.09,61.12v0.51C948.94,155.27,923.76,181.33,890.36,181.33z M889.85,69.67c-27.37,0-48,21.51-48,50.03
				c0,29.32,20.85,51.05,48.51,51.05c27.37,0,48.01-21.51,48.01-50.03v-0.51C938.37,91.4,917.51,69.67,889.85,69.67z"
										/>
									</g>
									<g>
										<path
											d="M700.99,217.36c-54.04,0-96.39-42.45-96.39-96.64c0-55.89,42.09-97.66,97.91-97.66c30.69,0,53.44,8.92,73.75,28.93
				l-7.42,7.53c-18.42-18.14-38.26-25.89-66.33-25.89c-49.79,0-87.33,37.22-87.33,86.57c0,49.57,36.89,86.57,85.81,86.57
				c29.03,0,49.02-8.7,68.97-30.04l7.72,7.22C755.8,207.37,732.86,217.36,700.99,217.36z M700.89,200.05
				c-43.59,0-76.47-34.32-76.47-79.83c0-45.06,33.59-79.83,76.47-79.83c22.09,0,41.85,7.82,62.2,24.62l-6.73,8.15
				c-18.6-15.35-35.7-22.2-55.46-22.2c-37.56,0-65.89,29.48-65.89,68.58c0,40.16,28.33,69.94,65.89,69.94
				c25.14,0,40.78-11.48,57.21-25.35l6.82,8.08C747.54,186.88,729.56,200.05,700.89,200.05z M702.26,181.33
				c-32.38,0-56.8-26.28-56.8-61.12c0-34.5,24.95-61.12,56.8-61.12c17.55,0,32.65,6.61,48.98,21.42l-7.1,7.83
				c-14.23-12.92-27.15-18.67-41.87-18.67c-26.35,0-46.22,21.51-46.22,50.03c0,29.32,19.87,51.05,46.22,51.05
				c18.3,0,29.27-6.83,43.13-19.43l7.12,7.82C737.45,172.84,723.79,181.33,702.26,181.33z"
										/>
									</g>
									<path d="M165.37,216.08H30.67V31.39h14.1v170.59h120.59V216.08z M165.37,180.55H69.76V31.39h14.1v135.06h81.51V180.55z" />
									<g>
										<path
											d="M291.12,183.09c13.77,0,26.14-4.35,36.08-11.78l-10.15-10.15c-7.3,4.98-16.17,7.84-25.93,7.84
				c-26.65,0-46.75-20.97-46.75-49.29c0-9.27,2.3-17.73,6.29-24.93l-10.34-10.34c-6.39,10-10.05,22.24-10.05,35.78
				C230.27,155.47,257,183.09,291.12,183.09z"
										/>
										<path
											d="M237.86,52.05c14.57-10.49,32.86-16.66,53.26-16.66c49.65,0,87.1,36.24,87.1,84.81c0,20.66-6.92,39.11-18.69,53.51
				l9.76,9.76c14.44-17.12,23.03-39.24,23.03-63.78c0-56.1-43.5-98.4-101.2-98.4c-24.06,0-45.87,7.8-63.1,20.91L237.86,52.05z"
										/>
										<path
											d="M351.47,120.21c0-35.26-26.73-62.88-60.85-62.88c-13.66,0-25.94,4.28-35.84,11.64l10.15,10.15
				c7.26-4.88,16.03-7.69,25.68-7.69c26.66,0,46.75,20.97,46.75,48.78v0.51c0,9.16-2.24,17.54-6.15,24.68l10.39,10.39
				c6.27-9.87,9.86-21.87,9.86-35.08V120.21z"
										/>
										<path
											d="M344.1,188.2c-14.61,10.59-32.98,16.82-53.49,16.82c-49.65,0-87.09-36.24-87.09-84.3v-0.51c0-20.76,6.98-39.3,18.86-53.73
				l-9.78-9.78c-14.53,17.07-23.18,39.12-23.18,63.51v0.51c0,56.1,43.5,98.4,101.19,98.4c24.16,0,46.04-7.86,63.31-21.1L344.1,188.2z
				"
										/>
									</g>
									<path
										d="M578.44,141.97l-22.43-22.43l-23.96,23.96l-50.19-50.2c-4.14-4.13-6.42-9.63-6.42-15.47c0-5.85,2.28-11.34,6.42-15.48
			c8.28-8.27,22.68-8.27,30.95,0l22.43-22.43c-10.12-10.13-23.59-15.7-37.91-15.7c-14.32,0-27.79,5.58-37.91,15.7
			c-10.13,10.13-15.71,23.59-15.71,37.91c0,12.8,4.49,24.9,12.67,34.56l-14.18,14.18c-10.13,10.13-15.71,23.59-15.71,37.91
			c0,14.32,5.58,27.79,15.71,37.91c10.12,10.13,23.59,15.7,37.91,15.7s27.79-5.58,37.91-15.7l14.02-14.02l23.4,23.4l22.43-22.43
			l-23.4-23.4L578.44,141.97z M495.59,179.95c-8.28,8.27-22.68,8.27-30.95,0c-4.14-4.13-6.42-9.63-6.42-15.48
			c0-5.85,2.28-11.34,6.42-15.48l14.02-14.02l30.96,30.96L495.59,179.95z"
									/>
								</g>
							</svg>
						</a>
					</div>
				</div>
				<div class="col-3 col-md-3 d-flex justify-content-end">
					<a class="my-auto me-3 fs-10 text d-none lh-1 d-lg-block" routerLink="/accounts">Account</a>

					<select class="me-3 d-none d-sm-inline form-select form-select--desktop" *ngIf="selectedStore" [(ngModel)]="selectedStore" (change)="redirect()">
						<option [ngValue]="store" *ngFor="let store of stores" [attr.selected]="isSsr && store.country === selectedStore.country ? 'selected' : null">{{ store.currency }} {{ store.currencySymbol }} - {{ store.name }}</option>
					</select>
					<select class="me-3 d-sm-none d-inline form-select" *ngIf="selectedStore" [(ngModel)]="selectedStore" (change)="redirect()">
						<option [ngValue]="store" *ngFor="let store of stores" [attr.selected]="isSsr && store.country === selectedStore.country ? 'selected' : null">{{ store.country }}{{ store.currencySymbol }} </option>
					</select>

					<app-cart-toggle class="actionable my-auto"></app-cart-toggle>
					<a class="fs-10 my-auto ms-3 d-block d-lg-none relative heart" routerLink="pages/wishlist">
						<svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.8em" viewBox="0 0 24 20">
							<path
								d="M17.488 1.11h-.146a6.552 6.552 0 0 0-5.35 2.81A6.57 6.57 0 0 0 6.62 1.116 6.406 6.406 0 0 0 .09 7.428c0 7.672 11.028 15.028 11.497 15.338a.745.745 0 0 0 .826 0c.47-.31 11.496-7.666 11.496-15.351a6.432 6.432 0 0 0-6.42-6.306zM12 21.228C10.018 19.83 1.59 13.525 1.59 7.442c.05-2.68 2.246-4.826 4.934-4.826h.088c2.058-.005 3.93 1.251 4.684 3.155.226.572 1.168.572 1.394 0 .755-1.907 2.677-3.17 4.69-3.16h.02c2.7-.069 4.96 2.118 5.01 4.817 0 6.089-8.429 12.401-10.41 13.8z"
								fill="black"
							/>
						</svg>
						<div *ngIf="wishlist?.events?.length > 0" class="dot"></div>
					</a>
					<app-mobile-menu-toggle class="my-auto pe-2 ps-3 d-none d-lg-block actionable"></app-mobile-menu-toggle>
				</div>

				<div class="col-12 d-none d-lg-flex justify-content-center">
					<desktop-menu [menuItems]="theme.body1"></desktop-menu>
				</div>
			</div>
		</div>
	</header>

	<div #spacerEl class="spacer" [style.height]="height"></div>
</div>
<div class="relative d-block">
	<div @fade *ngIf="window && theme?.messages" class="ribbon bg-black text-white text-center d-flex justify-content-start align-items-center">
		<div class="ticker d-flex align-items-center no-special">
			<ng-container *ngFor="let message of theme?.messages">
				<div class="innerHtml ticker-item wysiwyg no-special actionable" [innerHTML]="message.entry | asRichText"></div>
			</ng-container>
			<ng-container *ngFor="let message of theme?.messages">
				<div class="innerHtml ticker-item wysiwyg no-special actionable" [innerHTML]="message.entry | asRichText"></div>
			</ng-container>
		</div>
	</div>
</div>
<app-search-input></app-search-input>
