<section class="p-5 border h-100">
    <p class="fw-bold fs-6">Recent orders</p>
    <hr/>
    <p *ngIf="orders?.length === 0" class="fs-6 mt-4 mb-4">You haven't made any orders yet!</p>

    <div *ngIf="orders && orders.length > 0" class="container p-0">

        <div class="table-responsive d-none d-md-block">
            <table class="table table-bordered w-100">
                <thead class="d-md-table-header">
                    <tr>
                        <th class="border p-3 ps-2">Order</th>
                        <th class="border p-3 ps-2">Date</th>
                        <th class="border p-3 ps-2">Payment</th>
                        <th class="border p-3 ps-2">Fulfilment</th>
                        <th class="border p-3 ps-2">Tracking</th>
                        <th class="border p-3 ps-2">Total</th>
                        <th class="border p-3 ps-2">View Order</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of orders" class="mb-4">
                        <td class="border p-3 ps-2">{{ order.name }}</td>
                        <td class="border p-3 ps-2">{{ order.processedAt | date:'yyyy-MM-dd' }}</td>
                        <td class="border p-3 ps-2">{{ order.payment }}</td>
                        <td class="border p-3 ps-2">{{ order.fulfillmentStatus }}</td>
                        <td class="border p-3 ps-2">{{ order.trackingNumber }}</td>
                        <td class="border p-3 ps-2">{{ order.totalPriceV2.amount | currency }}</td>
                        <td class="border p-3">
                            <button class="btn btn-primary w-100 view-btn" (click)="view(order)">View</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- For small or mobile screens -->
        <div class="d-md-none">
            <div *ngFor="let order of orders" class="mb-4">
                <table class="table table-bordered w-100">
                    <tr>
                        <th class="border p-3 ps-2">Order</th>
                        <td class="border p-3 ps-2">{{ order.name }}</td>
                    </tr>
                    <tr>
                        <th class="border p-3 ps-2">Date</th>
                        <td class="border p-3 ps-2">{{ order.processedAt | date:'yyyy-MM-dd' }}</td>
                    </tr>
                    <tr>
                        <th class="border p-3 ps-2">Payment</th>
                        <td class="border p-3 ps-2">{{ order.payment }}</td>
                    </tr>
                    <tr>
                        <th class="border p-3 ps-2">Fulfilment</th>
                        <td class="border p-3 ps-2">{{ order.fulfillmentStatus }}</td>
                    </tr>
                    <tr>
                        <th class="border p-3 ps-2">Tracking</th>
                        <td class="border p-3 ps-2">{{ order.trackingNumber }}</td>
                    </tr>
                    <tr>
                        <th class="border p-3 ps-2">Total</th>
                        <td class="border p-3 ps-2">{{ order.totalPriceV2.amount | currency }}</td>
                    </tr>
                    <tr>
                        <td class="p-3 ps-2"></td>
                        <td class="pt-3">
                            <button class="btn btn-primary w-100 h-100 view-btn" (click)="view(order)">View</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="showViewAllBtn" class="row mt-3">
            <div class="text-end">
                <button class="btn btn-primary view-all-btn" (click)="viewAllOrders.emit()">View all orders</button>
            </div>
        </div>
    
    </div>

</section>