<div
	class="outdated d-flex justify-content-center align-items-center"
	*ngIf="isOutdatedBrowser && window.innerWidth >= 768"
>
	<div class="text-white">
		Your web browser is out of date. Update your browser for more security, speed and the best experience on this
		site.

		<a
			href="https://bestvpn.org/outdatedbrowser/en.html"
			class="btn btn-update text-uppercase ms-3 mt-2 mt-md-0"
			target="_blank"
			>update</a
		>
		<button (click)="ignore()" class="btn btn-ignore text-uppercase ms-3 mt-2 mt-md-0">ignore</button>
	</div>
</div>
<div
	class="outdated d-flex justify-content-center align-items-center"
	*ngIf="isOutdatedBrowser && window.innerWidth < 768"
>
	<div class="row">
		<div class="text-white col-12">
			Your web browser is out of date. Update your browser for more security, speed and the best experience on
			this site.
		</div>
		<div class="col-12">
			<a
				href="https://bestvpn.org/outdatedbrowser/en.html"
				class="btn btn-update text-uppercase ms-3 mt-2 mt-md-0"
				target="_blank"
				>update</a
			>
			<button (click)="ignore()" class="btn btn-ignore text-uppercase ms-3 mt-2 mt-md-0">ignore</button>
		</div>
	</div>
</div>
