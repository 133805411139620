<div *ngIf="show || standaloneSearch" @fade class="search-input" [ngClass]="{ 'search-overlay': results && !standaloneSearch }" #input>
	<div class="row">
		<div class="col-12 d-flex jusify-content-between search-form text">
			<input @fade class="search-input__input" [ngClass]="{ 'search-input__input--standalone': standaloneSearch }"(click)="clickedInside($event)" [(ngModel)]="searchTerm" (ngModelChange)="onQueryChange($event)" (keydown.enter)="inputFocus.blur()" placeholder="Search Lo & Co" autofocus="autofocus" #inputFocus />
			<svg
				*ngIf="results && results.products"
				(click)="hideSearch()"
				class="closer actionable d-inline-block d-xxl-none my-auto"
				[ngClass]="{ 'd-none': standaloneSearch }"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle _ngcontent-pss-c87="" cx="12" cy="12" r="11.5" fill="white" stroke="#F4F4F4"></circle>
				<path
					_ngcontent-pss-c87=""
					d="M10.683 11.9785L8.33028 14.3313L9.28166 15.2826L11.6344 12.9299L13.9871 15.2826L14.9385 14.3313L12.5858 11.9785L14.9385 9.62578L13.9871 8.6744L11.6344 11.0271L9.28166 8.6744L8.33028 9.62578L10.683 11.9785Z"
					fill="black"
				></path>
			</svg>
		</div>
		<div class="col-12" *ngIf="results && results.products" [ngClass]="{ 'results': !standaloneSearch }">
			<div class="row gx-2 gx-lg-3">
				<div class="col-6 col-md-4 col-xxl-2 mb-3 mb-lg-5 mb-xxl-1" *ngFor="let searchItem of results.products">
					<app-collection-item @fade (click)="hideSearch()" [product]="searchItem"></app-collection-item>
				</div>
				<div *ngIf="standaloneSearch" class="row g-3" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300"
					(scrolled)="loadNextPage()">
				</div>
				<div class="col-6 mt-2" *ngIf="!loading && results.products?.length === 0 && searchTerm.length > 0">
					<h6>No results found. Please try again.</h6>
				</div>
			</div>

			<div class="row" *ngIf="results.products.length && !standaloneSearch">
				<div class="col-12 mb-3">
					<a [routerLink]="['/search']" [queryParams]="{ q: searchTerm }" class="btn btn-secondary btn-lg w-100 px-5 text-uppercase">See more results</a>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="show" @fade #mask class="bg-mask" (click)="hideSearch()"></div>
