<section class="shopify-section">
	<div class="slider">
		<nav class="slider__nav" *ngIf="!insufficientSlides && option.nav">
			<div (click)="slide('prev', $event)" class="direction">
				<svg class="ms-4 actionable" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="25" height="25" rx="12.5" fill="white" />
					<path fill-rule="evenodd" clip-rule="evenodd" d="M14.6019 19L16 17.5867L11.4628 13L16 8.41333L14.6019 7L8.66667 13L14.6019 19Z" fill="black" />
				</svg>
			</div>

			<div (click)="slide('next', $event)" class="direction">
				<svg class="me-4 actionable" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="25" height="25" rx="12.5" fill="white" />
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3981 7L9 8.41333L13.5372 13L9 17.5867L10.3981 19L16.3333 13L10.3981 7Z" fill="black" />
				</svg>
			</div>
		</nav>
		<div class="slider__slides" #slider data-slider>
			<ng-content></ng-content>
		</div>
	</div>
	<div class="slider__indicator" *ngIf="!insufficientSlides && option.dots" #indicator data-indicators>
		<button *ngFor="let dot of indicators"></button>
	</div>
</section>
