import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router'
import { SeoService } from '../services/seo.service'
import { ShopifyService } from '../services/shopify.service'
import { UtilityService } from '../services/utility.service'

@Injectable({ providedIn: 'root' })
export class ProductResolver implements Resolve<any> {
	constructor(private utilityService: UtilityService, private shopifyService: ShopifyService, private seoService: SeoService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot): Promise<any> {
		const handle = route.paramMap.get('handle') as string

		return this.utilityService
			.getProductByHandle(handle)
			.toPromise()
			.then(async data => {
				const product = data
				const selectedVariant = product.variants[0]

				this.seoService.updateSEO(
					product.metafields?.global?.title_tag || product.title,
					product.metafields?.global?.description_tag || product.body_html,
					product.images[0]?.src
				)

				return {
					product,
					selectedVariant,
				}
			})
			.catch(err => {
				this.router.navigate(['/not-found'])
			})
	}
}
