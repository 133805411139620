import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
	selector: 'app-outdated-browser',
	templateUrl: './outdated-browser.component.html',
	styleUrls: ['./outdated-browser.component.scss'],
})
export class OutdatedBrowserComponent implements OnInit {
	window: any
	constructor(private route: ActivatedRoute, private utilityService: UtilityService) {
		this.route.queryParams.subscribe(data => {
			if (data.old == 'true') {
				this.isOutdatedBrowser = true
			}
		})
		this.window = this.utilityService.getWindow()
	}
	isOutdatedBrowser: boolean = false
	ngOnInit(): void {}

	ignore() {
		this.isOutdatedBrowser = false
	}
}
