import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router'
import { Observable, combineLatest, of } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { UtilityService } from '../services/utility.service'
import { SeoService } from '../services/seo.service'
import { RouteStorageService } from '../services/routestorage.service'

@Injectable({ providedIn: 'root' })
export class CollectionResolver implements Resolve<any> {
	routeInfo: any
	price: { minPrice: number | undefined; maxPrice: number | undefined } = { minPrice: undefined, maxPrice: undefined }
	filters: any
	constructor(private utilityService: UtilityService, private router: Router, private seoService: SeoService, private routeStorageService: RouteStorageService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		const segments = route.pathFromRoot.flatMap(r => r.url.map(segment => segment.toString()));
		const fullPath = segments.join('/');
		const handleAndMetafields = this.routeStorageService.findHandleAndReturnMetafields(fullPath);

		if (handleAndMetafields && this.routeStorageService.retrieveHandle(handleAndMetafields.foundKey)) {
			this.seoService.updateSEO(
				handleAndMetafields.metafields?.global?.title_tag || '',
				handleAndMetafields.metafields?.global?.description_tag || ''
			);

			// We have a key *and* it has valid data to re-use
			return of(null);
		}

		return combineLatest([of(route.params), of(route.queryParams)]).pipe(
			switchMap(([params, queryParams]) => {
				let pageNo = queryParams.pageNo ? queryParams.pageNo : 0
				let sort = queryParams.sort || ''
				this.routeInfo = { queryParams, params }
				let filter = this.calculateFilters()
				// let filter: any = []
				return this.utilityService.getFilteredCollection(params.handle, filter, sort, 20, pageNo * 20).then(data => {
					return { data, filters: filter }
				})
			}),
			catchError(err => {
				// handle error, navigate or return Observable of null
				this.router.navigate(['/not-found'])
				return of(null)
			})
		)
	}

	calculateFilters() {
		this.price = {
			minPrice: undefined,
			maxPrice: undefined,
		}
		let selectedOptions = []
		if (this.routeInfo.queryParams.type) {
			let groups = this.routeInfo.queryParams.type.split(',')
			for (let group of groups) {
				let tags = group.split('|')
				selectedOptions.push({
					type: 'type',
					value: tags,
				})
			}
		}

		if (this.routeInfo.queryParams.tag) {
			let groups = this.routeInfo.queryParams.tag.split(',')
			for (let group of groups) {
				let tags = group.split('|')
				selectedOptions.push({
					type: 'tag',
					value: tags,
				})
			}
		}

		if (this.routeInfo.queryParams.minPrice) {
			// price filter goes here
			this.price.minPrice = this.routeInfo.queryParams.minPrice
		}

		if (this.routeInfo.queryParams.maxPrice) {
			// price filter goes here
			this.price.maxPrice = this.routeInfo.queryParams.maxPrice
		}
		if (this.price.maxPrice && this.price.minPrice) {
			selectedOptions.push({
				type: 'price',
				operator: 'BETWEEN',
				value: [(this.price.minPrice * 100).toString(), (this.price.maxPrice * 100).toString()],
			})
		}
		return selectedOptions
	}
}
