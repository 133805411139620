import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { emailValidator } from 'src/app/common/email.validator'
import { Unsub } from 'src/app/common/unsub.class'
import { PrismicService } from 'src/app/services/prismic.service'
import { ShopifyService } from 'src/app/services/shopify.service'

@Component({
	selector: 'app-account-login',
	templateUrl: './account-login.component.html',
	styleUrls: ['./account-login.component.scss'],
})
export class AccountLoginComponent extends Unsub implements OnInit {
	settings: any
	error?: string
	loading: boolean = false
	invalid: boolean = false

	loginForm!: FormGroup;

	constructor(
		private shopifyService: ShopifyService,
		private router: Router,
		private prismicService: PrismicService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
	) {
		super();
	}

	async ngOnInit() {
		this.prismicService.getAccountSettings().then((data) => {
		  this.settings = data;
		});

		this.loginForm = this.formBuilder.group({
		  email: ['', [Validators.required, Validators.minLength(8), emailValidator()]],
		  password: ['', [Validators.required, Validators.minLength(8)]],
		});
	}

	get formControls() {
		return this.loginForm.controls;
	}

	get isFormValid(): boolean {
		return this.loginForm.invalid && this.loginForm.touched
	}

	showValidation(controlName: string): boolean {
		const control = this.formControls[controlName];
		return control.invalid && control.dirty;
	}

	login() {
		this.loading = true

		if (this.loginForm.valid) {
			const { email, password } = this.loginForm.value;

			this.shopifyService.customerAccessTokenCreate({ email, password })
				.pipe(
					takeUntil(this.unsubscribe$)
				)
				.subscribe(
					data => {
						this.route.queryParams.subscribe(params => {
							const checkoutUrl = this.shopifyService.getCheckoutLink();
							if (params['checkout_url'] && params['checkout_url'] !== 'false' && checkoutUrl) {
								window.location.href = checkoutUrl;
							} else {
								this.router.navigate(['/accounts/dashboard'])
							}
						});
					},
					err => {
						console.log('error')
						if (err) {
							this.invalid = true
							this.loading = false
							this.error = err
						}
					}
				)
		}

	}
}
