import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core'
import { Router } from '@angular/router'
import { PrismicService } from 'src/app/services/prismic.service'

@Directive({ selector: '[prismicLinkObject]' })
export class PrismicLinkDirective {
	@Input() prismicLinkObject: any
	constructor(private router: Router, private elementRef: ElementRef, private prismicService: PrismicService) {}

	@HostBinding('attr.href')
	get setup() {
		if (this.prismicLinkObject.link_type == 'Web') {
			if (this.prismicLinkObject.url.includes('loandcointeriors.com/') || this.prismicLinkObject.url.includes('loandcointeriors.com.au/')) {
				const url = new URL(this.prismicLinkObject.url).pathname
				return url
			} else {
				return this.prismicLinkObject.url
			}
		}
		if (this.prismicLinkObject.link_type == 'Media') {
			return this.prismicLinkObject.url
		}
		if (this.prismicLinkObject.link_type == 'Document') {
			let link = this.prismicService.linkResolver(this.prismicLinkObject)
			return link
		}
	}

	@HostListener('click', ['$event'])
	click($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
		if (this.prismicLinkObject.link_type == 'Web') {
			if (this.prismicLinkObject.url.includes('loandcointeriors.com/') || this.prismicLinkObject.url.includes('loandcointeriors.com.au/')) {
				const url = new URL(this.prismicLinkObject.url).pathname
				this.elementRef.nativeElement.href = url
				this.router.navigate([url])
			} else {
				const newWindow = window.open(this.prismicLinkObject.url, '_blank')
				this.elementRef.nativeElement.href = this.prismicLinkObject.url
				if (newWindow) {
					newWindow.opener = null
				}
			}
			return
		}
		if (this.prismicLinkObject.link_type == 'Media') {
			this.elementRef.nativeElement.href = this.prismicLinkObject.url
			window.location.href = this.prismicLinkObject.url
			return
		}
		if (this.prismicLinkObject.link_type == 'Document') {
			let link = this.prismicService.linkResolver(this.prismicLinkObject)
			this.elementRef.nativeElement.href = link
			this.router.navigate([link])
			return
		}
	}
}
