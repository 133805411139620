<ng-container *ngIf="style">
	<svg
		*ngIf="inWishlist"
		class="wishlist-toggle actionable"
		directiveRemoveFromWishlist
		[product]="product"
		(emitInWishlist)="setInWishlist(false)"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12" cy="12" r="11.5" fill="white" stroke="#EDEDED" />
		<path
			d="M8.60841 7.00031C7.6827 7.00031 6.75734 7.35136 6.05415 8.05732C4.64783 9.46914 4.6494 11.7372 6.05415 13.1502L11.776 18.9108H11.7761C11.8334 18.9678 11.9124 19 11.9948 19C12.0773 19 12.1563 18.9678 12.2136 18.9108C14.1238 16.9932 16.0348 15.0769 17.9453 13.1591C19.3516 11.7473 19.3516 9.47814 17.9453 8.06621C16.5389 6.65439 14.2431 6.65439 12.8369 8.06621L12.0045 8.90351L11.1625 8.05701C10.4594 7.35105 9.534 7 8.60829 7L8.60841 7.00031Z"
			fill="black"
		/>
	</svg>
	<svg
		*ngIf="!inWishlist"
		class="wishlist-toggle actionable"
		directiveAddToWishlist
		[product]="product"
		(emitInWishlist)="setInWishlist(true)"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12" cy="12" r="11.5" fill="white" stroke="#F4F4F4" />
		<path
			d="M8.60841 7.50031H8.65286C9.43726 7.51136 10.2148 7.81402 10.8083 8.40986L11.65 9.25611L12.0046 9.61262L12.3591 9.25602L13.1911 8.41907C14.4019 7.20345 16.3801 7.20343 17.591 8.41906C18.803 9.63589 18.803 11.5895 17.591 12.8062C16.628 13.773 15.6711 14.7331 14.7141 15.6932C13.8095 16.6009 12.9049 17.5085 11.995 18.4218L6.4089 12.7978L6.40874 12.7977C5.19755 11.5794 5.19674 9.62657 6.40839 8.41019L6.4084 8.41018C7.01315 7.80305 7.80896 7.50031 8.60841 7.50031Z"
			stroke="black"
		/>
	</svg>
</ng-container>
<ng-container *ngIf="!style">
	<button *ngIf="!inWishlist" [product]="product" class="h-100 btn btn-primary ms-1 px-3" directiveAddToWishlist (emitInWishlist)="setInWishlist(true)">
		<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
			<path
				d="M12 21.593c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447 2.54 0 5.274 1.621 5.274 5.181 0 4.069-5.136 8.625-11 14.402m5.726-20.583c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181"
				fill="white"
			/>
		</svg>
	</button>
	<button *ngIf="inWishlist" [product]="product" class="h-100 btn btn-primary ms-1 px-3" directiveRemoveFromWishlist (emitInWishlist)="setInWishlist(false)">
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
			<path
				d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997
		0-8.118-10-8.999-12-3.568z"
				fill="white"
			/>
		</svg>
	</button>
</ng-container>
