import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { EnvironmentService } from 'src/app/services/environment.service'
import { ShopifyService } from 'src/app/services/shopify.service'

@Component({
	selector: 'app-accounteset-password',
	templateUrl: './account-set-password.component.html',
	styleUrls: ['./account-set-password.component.scss'],
})
export class AccountSetPasswordComponent implements OnInit {
	newPassword: string = ''
	url: string = ''
	error: string = ''
	success: 'clean' | 'failed' | 'success' = 'clean';

	customerId: string = '';
	resetToken: string = '';
	environment: any;

	isBrowser = isPlatformBrowser(this.platformId);

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private shopifyService: ShopifyService,
		private environmentService: EnvironmentService,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.environmentService.observableEnvironment.subscribe((data: any) => {
			this.environment = data;
		});

		this.route.queryParams.subscribe(data => {
			this.customerId = data.customerId;
			this.resetToken = data.resetToken;

			if (!this.customerId || !this.resetToken) {
				this.router.navigate(['/accounts/recover']);
			}
		})
	}

	submit() {
		this.success = 'clean';

		this.shopifyService.customerResetByUrl(`${this.environment.url}account/reset/${this.customerId}/${this.resetToken}`, this.newPassword).subscribe(
			(data: any) => {
				if (data.customerResetByUrl.customerUserErrors.length) {
					this.error = data.customerResetByUrl.customerUserErrors[0].message;
					this.success = 'failed';
					return;
				}

				this.success = 'success';
				setTimeout(() => {
					this.router.navigate(['/accounts/login'])
				}, 4000)
			},
			err => {
				this.success = 'failed';
				this.error = err
			}
		)
	}

	ngOnInit(): void {}
}
