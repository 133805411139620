import { animate, group, state, style, transition, trigger } from '@angular/animations'

export const scrollInOutLeft = trigger('scrollInOutLeft', [
	state(
		'*',
		style({
			transform: 'translateX(0px)',
			pointerEvents: 'all',
			opacity: '*',
		})
	),
	state(
		'void',
		style({
			transform: 'translateX(100%)',
			pointerEvents: 'none',
			opacity: '0',
		})
	),
	transition('* <=> *', [group([animate('0.3s  cubic-bezier(0.645, 0.045, 0.355, 1.000)')])]),
])
