import { animate, AnimationBuilder, AnimationFactory, AnimationMetadata, AnimationPlayer, style } from '@angular/animations'
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Directive({
	selector: '[appear]',
})
export class AppearDirective implements OnInit, OnDestroy {
	@Input() animateInAnimation!: AnimationMetadata | AnimationMetadata[]

	private player?: AnimationPlayer
	private inView = false
	private scrollSubscription?: IntersectionObserver
	private readonly defaults = {
		offset: 0,
	}
	private readonly animationDuration = 1200
	private readonly animationTiming = 'cubic-bezier(0.35, 0, 0.25, 1)'

	constructor(private el: ElementRef, private animationBuilder: AnimationBuilder, private utilityService: UtilityService) {}

	ngOnInit(): void {
		if (this.utilityService.isBrowser()) {
			this.initialize()
			this.observeScrollEvents()
		}
	}

	private initialize(): void {
		const animation = this.animateInAnimation || [style({ opacity: 0 }), animate(`${this.animationDuration}ms ${this.animationTiming}`, style({ opacity: 1 }))]

		const animationFactory: AnimationFactory = this.animationBuilder.build(animation)

		this.player = animationFactory.create(this.el.nativeElement)
		this.updateInView() // Check if the element is in view on initialization
	}

	private observeScrollEvents(): void {
		if ('IntersectionObserver' in window) {
			this.scrollSubscription = new IntersectionObserver(
				([entry]) => {
					this.inView = entry.isIntersecting
					if (this.inView) {
						this.playAnimationOnce() // Play animation only when in view
						this.scrollSubscription?.unobserve(this.el.nativeElement)
						this.scrollSubscription?.disconnect()
					}
				},
				{
					rootMargin: `${this.defaults.offset}px`,
				}
			)

			this.scrollSubscription.observe(this.el.nativeElement)
		}
	}

	private updateInView(): void {
		this.inView = this.isInViewport()
		this.playAnimationOnce()
	}

	private playAnimationOnce(): void {
		if (this.inView && this.player && !this.player.hasStarted()) {
			this.player.play()
		}
	}

	private isInViewport(): boolean {
		if (!this.el || !this.el.nativeElement) return false

		const bounding = this.el.nativeElement.getBoundingClientRect()
		const top = bounding.top - (window.innerHeight || document.documentElement.clientHeight)
		const bottom = bounding.top + bounding.height + this.defaults.offset

		return top < 0 && bottom > 0
	}

	ngOnDestroy(): void {
		this.scrollSubscription?.unobserve(this.el.nativeElement)
		this.scrollSubscription?.disconnect()
	}
}
