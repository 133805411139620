import { animate, animateChild, query, state, style, transition, trigger } from '@angular/animations'

export const parentDown = trigger('parentDown', [
	state(
		'*',
		style({
			transform: 'translateY(0px)',
			pointerEvents: 'all',
			opacity: '*',
		})
	),
	state(
		'void',
		style({
			transform: 'translateY(-100%)',
			pointerEvents: 'none',
			opacity: '0',
		})
	),
	transition('void => *', [animate('0.4s ease-in-out'), query('@childUp', animateChild())]),
	transition('* => void', [animate('0.4s ease-in-out')]),
])

export const childUp = trigger('childUp', [
	transition('* => *', [
		query(
			':enter',
			[
				style({
					opacity: 0,
				}),
				animate(
					'.4s',
					style({
						opacity: 1,
					})
				),
			],
			{ optional: true }
		),
	]),
])
