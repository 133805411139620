import { Component } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
	selector: 'app-search-toggle',
	templateUrl: './search-toggle.component.html',
	styleUrls: ['./search-toggle.component.scss'],
})
export class SearchToggleComponent {
	constructor(private utilityService: UtilityService) {}

	toggleSearch($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
		this.utilityService.searchStateSubject.next('open')
	}
}
