<div *ngIf="_active" @fade class="mik-container" (click)="hideCart()">
	<div
		class="mik-modal"
		[ngClass]="{ 'h-auto': cart.lineItems.length < 3, 'h-100': cart.lineItems.length > 2 }"
		[style.top]="windowWidth > 991 ? offsetHeight - 32 + 'px' : offsetHeight + 'px'"
		(click)="clickedInside($event)"
	>
		<div class="h-100" *ngIf="loading">
			<p class="py-3 mb-0 fw-bold justify-content-center d-flex">Loading...</p>
		</div>
		<div class="h-100 d-flex flex-column" @fade *ngIf="!loading">
			<div *ngIf="cart.lineItems.length == 0" class="empty">
				<div class="row">
					<div class="col text-center">
						<p class="py-3 mb-2 justify-content-center d-flex">Oh no, your bag is empty!</p>

						<button class="btn-primary btn-lg btn w-100" (click)="hideCart()">Continue Shopping</button>
					</div>
				</div>
			</div>
			<div class="overflow-control" *ngIf="cart.lineItems.length > 0">
				<div *ngFor="let item of cart.lineItems; let last = last">
					<div class="row">
						<div class="col-5">
							<img class="w-100 pe-1 ps-1" [src]="item.merchandise.image.url | ShopifyImageUrl: 300:300" />
						</div>

						<div class="col-7 px-0 text">
							<div class="d-flex">
								<h5>{{ item.merchandise.product.title }}</h5>
								<div class="line-price">
									{{ item.merchandise.price.amount | currency }}
								</div>
							</div>

							<div class="item-info">
								<ng-container *ngIf="item?.properties?._colour?.length > 0"
									>Colour: {{ item.properties._colour }}
									<br />
								</ng-container>
								<ng-container *ngIf="item?.properties?._size?.length > 0"
									>Size: {{ item.properties._size }}
									<br />
								</ng-container>
								Qty: {{ item.quantity }}

								<div class="message" *ngIf="item?.properties?._messaging?.length > 0">
									{{ item.properties._messaging }}
								</div>
							</div>
							<h6 class="align-items-center actionable" (click)="remove(item)">
								<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M3.11204 4.51172L0.556021 7.06774L1.5896 8.10132L4.14562 5.5453L6.70164 8.10132L7.73523 7.06774L5.17921 4.51172L7.73523 1.9557L6.70164 0.922116L4.14562 3.47814L1.5896 0.922116L0.556021 1.9557L3.11204 4.51172Z"
										fill="black"
									/>
								</svg>
								Remove
							</h6>
						</div>
					</div>
					<hr />
				</div>
			</div>
			<div class="section mt-auto" *ngIf="cart.lineItems.length > 0">
				<div class="row">
					<div class="col-8">
						<p class="total">Subtotal</p>
					</div>
					<div class="col-4">
						<p class="price">{{ cart.cost.totalAmount.amount | currency }}</p>
					</div>
					<div class="col-12" (click)="hideCart()">
						<a routerLink="/cart" class="btn btn-outline-primary btn-lg mb-1 w-100">View Bag</a>
					</div>
				</div>
				<a *ngIf="cart.lineItems.length > 0" [href]="checkoutUrl" class="btn-primary btn-lg btn w-100">Checkout</a>
			</div>
		</div>
	</div>
</div>
