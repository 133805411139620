import { isPlatformBrowser } from '@angular/common'
import { Directive, ElementRef, Inject, Input, OnChanges, PLATFORM_ID } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Directive({ selector: '[prismicImageObject]' })
export class PrismicImageDirective implements OnChanges {
	@Input() prismicImageObject: any
	@Input() ratio: any
	window: any
	constructor(private utilityService: UtilityService, private ref: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
		this.window = this.utilityService.getWindow() ?? ({ devicePixelRatio: 1 } as any)
		this.utilityService.resizeObservable.subscribe(data => {
			if (isPlatformBrowser(this.platformId)) {
				this.ref.nativeElement.srcset = [this.ref.nativeElement.srcset, this.generateUrl(this.prismicImageObject.url)].join(', ')
			}
		})
	}

	ngOnChanges(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.ref.nativeElement.srcset = this.generateUrl(this.prismicImageObject.url)
			this.ref.nativeElement.alt = this.prismicImageObject.alt ?? 'Lo & Co Interiors'
		}
	}

	generateUrl(url: URL) {
		// return sizes
		// 	.map((x: number) => {
		let ori = new URL(url)
		if (isPlatformBrowser(this.platformId)) {
			if (this.ref.nativeElement) {
				ori.searchParams.append('w', this.ref.nativeElement.offsetWidth.toString())
				ori.searchParams.append('dpr', this.window.devicePixelRatio.toFixed(1))
			}
		} else {
			if (this.ref.nativeElement) {
				ori.searchParams.append('w', this.ref.nativeElement.width.toString())
				ori.searchParams.append('dpr', '1')
			}
		}
		return ori.href

		// })
		// .join(', ')
	}
}

@Directive({ selector: '[prismicBgObject]' })
export class PrismicBackgroundDirective implements OnChanges {
	@Input() prismicBgObject: any
	@Input() ratio: any

	urls: string[] = []
	constructor(private utilityService: UtilityService, private ref: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
		this.utilityService.resizeObservable.subscribe(data => {
			if (isPlatformBrowser(this.platformId)) {
				this.generateUrl()
				this.ref.nativeElement.style.backgroundImage = this.assembleUrl()
			}
		})
	}

	assembleUrl() {
		return `-image-set(${this.urls.map(x => x + ' ' + (this.utilityService.getWindow() as any).devicePixelRatio.toFixed(0) + 'x').join(`, `)})`
	}

	ngOnChanges(): void {
		this.generateUrl()
		if (isPlatformBrowser(this.platformId)) {
			this.ref.nativeElement.style.backgroundImage = this.urls[0]
			this.ref.nativeElement.style.backgroundImage = this.assembleUrl()
		}
	}

	generateUrl() {
		if (this.prismicBgObject?.url) {
			let ori = new URL(this.prismicBgObject.url)

			if (isPlatformBrowser(this.platformId)) {
				ori.searchParams.set('w', this.ref.nativeElement.offsetWidth.toString())
				ori.searchParams.set('dpr', (this.utilityService.getWindow() as any).devicePixelRatio.toFixed(1))
			} else {
				ori.searchParams.set('w', '300')
			}
			this.urls.push(`url("${ori.href}")`)
		}
	}
}
