<div class="spec-container container p-4 px-md-0">
	<div class="row py-md-5 gx-md-0">
		<div class="col-md-4 offset-md-1 col-12">
			<h6 class="title">Newsletter</h6>
			<ng-container *ngIf="success">
				<h5 class="mt-4">Thanks</h5>
				<p>We've added you to our newsletter!</p>
			</ng-container>
			<form [formGroup]="newsletterForm" (ngSubmit)="submit()" *ngIf="!success">
				<div class="input-group pt-1 border-bottom mb-2" [ngClass]="{ 'was-validated': submitted && newsletterForm.controls.email.invalid }">
					<input
						formControlName="email"
						class="form-control px-1"
						type="email"
						placeholder="Your email address"
						aria-required="true"
						autocorrect="off"
						autocapitalize="off"
					/>
					<button class="button mb-2" type="submit" id="submit-email" name="Submit Email">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#fff" />
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M9.05922 6.47046L8.23682 7.30183L10.9057 9.99987L8.23682 12.6979L9.05922 13.5293L12.5505 9.99987L9.05922 6.47046Z"
								fill="#fff"
							/>
						</svg>
					</button>
					<div *ngIf="submitted && newsletterForm.controls.email.invalid" class="invalid-feedback">
						<div *ngIf="newsletterForm.controls.email.errors?.required" class="ps-1 validation-error">This field is required.</div>
						<div *ngIf="newsletterForm.controls.email.errors?.email" class="ps-1 validation-error">Please enter a valid email address.</div>
					</div>
				</div>
			</form>
			<p class="fs-12 subtext mt-2">Sign up to our newsletter for special things and receive 10% off your next order.</p>
		</div>
		<div class="col-12 col-md-6 offset-md-1 mt-3 mt-md-0">
			<div class="row gx-md-0 justify-content-end">
				<div class="col-6" *ngFor="let list of footer">
					<h6 *ngIf="list.primary.title" class="title">{{ list.primary.title }}</h6>
					<div class="d-flex flex-column">
						<div *ngFor="let link of list.items">
							<a class="actionable" [prismicLinkObject]="link.link"> {{ link.link_text }}</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-5 pt-md-5">
			<div class="col-md-4 offset-md-1 col-12">
				<span class="olive fs-12">
					© {{ date | date: 'YYYY' }} Lo & Co Interiors. All Rights Reserved
				</span>
			</div>
		</div>
	</div>
</div>
