import { Component, OnInit } from '@angular/core'
import { ShopifyService } from 'src/app/services/shopify.service'

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
	loading: boolean = true
	orders: any = [
		{
			orderNumber: '#30',
			processedAt: '07/03/23',
			paymentStatus: 'Paid',
			fulfillmentStatus: 'Approved',
			trackingNumber: '3004924802291',
			totalPrice: '10.000',
			reOrder: 'reorder',
		},
		{
			orderNumber: '#31',
			processedAt: '07/03/23',
			paymentStatus: 'Paid',
			fulfillmentStatus: 'Approved',
			trackingNumber: '3004-1189248',
			totalPrice: '10.000',
			reOrder: 'reorder',
		},
		{
			orderNumber: '#32',
			processedAt: '07/03/23',
			paymentStatus: 'Paid',
			fulfillmentStatus: 'Approved',
			trackingNumber: '300402839248',
			totalPrice: '10.000',
			reOrder: 'reorder',
		},
	]

	constructor(private shopifyService: ShopifyService) {}

	ngOnInit(): void {
		this.loading = true
		this.shopifyService.customerDetails().subscribe(
			(customerData: any) => {
				this.orders = customerData.customer.orders.edges.map((x: any) => x.node)
			},
			err => {
				console.error('Error fetching customer data', err)
			},
			() => {
				this.loading = false
			}
		)
	}
}
