<div class="d-flex flex-column flex-grow-1 h-100">
	<div class="border-top border-left border-right">
		<h4 class="text-uppercase px-4 py-4 mb-0">Account details</h4>
	</div>
	<div class="border flex-grow-1 px-4 py-5">
		<h6 class="text-uppercase">Name</h6>
		<p>{{ details.name }}</p>
		<h6 class="text-uppercase">Email</h6>
		<p>{{ details.email }}</p>
		<h6 class="text-uppercase">Address</h6>
		<p>{{ details.address }}</p>
		<!--<a class="btn btn-primary mt-sm-4" [routerLink]=""> Manage details</a>-->
	</div>
</div>
