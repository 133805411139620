import { isPlatformBrowser } from '@angular/common'
import { Component, ElementRef, HostListener, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core'

@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnChanges {
	@Input() reference: string = ''
	@ViewChild('slider') slider!: ElementRef
	@Input() options: { [key: string]: LightCarouselBreakpointOption } = {
		0: {
			slides: 1,
		},
	}
	@Input() scrollToSlide: number = 0
	indicators: any[] = new Array(1)
	option: any
	insufficientSlides: boolean = true
	less = false
	more = false
	width!: number
	constructor(@Inject(PLATFORM_ID) private platformId: Object, private ref: ElementRef) {}

	async ngOnChanges(changes: SimpleChanges) {
		if (isPlatformBrowser(this.platformId)) {
			await this.waitForElementToExist()
			this.resize()

			if (changes.scrollToSlide) {
				this.scrollTo(changes.scrollToSlide.currentValue)
			} else {
				this.scrollTo(0)
			}
		}
	}

	moreOrLess() {
		this.less = Math.floor(this.slider.nativeElement.scrollLeft).toFixed(0) != '0'
	}

	@HostListener('window:resize')
	resize() {
		this.selectOptionsForBreakpoint()
		this.ref.nativeElement.style.setProperty('--slides', 100 / this.option.slides + '%')
		try {
			this.calculateWidth()
			this.insufficientSlides = this.slider.nativeElement.children.length <= this.option.slides
			this.more = true
			if (this.insufficientSlides == true) {
				this.less = false
				this.more = false
			} else {
				this.moreOrLess()
			}
		} catch {}
	}

	calculateWidth() {
		try {
			this.width = this.slider.nativeElement.getBoundingClientRect().width / this.option.slides
		} catch {}
	}

	selectOptionsForBreakpoint() {
		let length = Object.keys(this.options).length
		if (length == 0) {
			this.option = { 0: { slides: 1, nav: true, dots: true } }
			return
		}
		if (length == 1) {
			this.option = this.options[0]
			return
		}
		for (let entry of Object.keys(this.options)) {
			let check = parseInt(entry)
			if (window.innerWidth > check) {
				this.option = this.options[entry]
				continue
			} else {
				return
			}
		}
	}

	slide(direction: 'prev' | 'next', $event: Event) {
		let left

		$event.preventDefault()
		$event.stopPropagation()
		const { scrollLeft } = this.slider.nativeElement
		const width = this.slider.nativeElement.getBoundingClientRect().width / this.option.slides
		switch (direction) {
			case 'prev':
				left = scrollLeft - width
				break
			case 'next':
			default:
				left = scrollLeft + width
				break
		}

		smoothScroll(this.slider.nativeElement, left).then(data => {
			this.less = this.slider.nativeElement.scrollLeft != 0
			this.more = this.slider.nativeElement.scrollWidth > this.slider.nativeElement.scrollLeft + this.slider.nativeElement.offsetWidth
		})
	}

	scrollTo(slide: number) {
		this.waitForElementToExist().then(data => {
			let left = this.width * slide
			setTimeout(() => {
				this.slider.nativeElement.scrollLeft = left
			}, 15)
		})
	}

	async waitForElementToExist() {
		const element = this.ref.nativeElement.querySelector('.slider__slides')

		return new Promise(resolve => {
			if (element) {
				resolve(element) // Element found, run the callback function
			} else {
				const observer = new MutationObserver((mutationsList, observer) => {
					mutationsList.forEach(mutation => {
						const addedNodes = Array.from(mutation.addedNodes)
						const target = mutation.target as HTMLElement

						if (addedNodes.some(node => node === target.querySelector('.slider__slides'))) {
							observer.disconnect() // Stop observing changes
							resolve(element) // Element found, run the callback function
						}
					})
				})

				observer.observe(this.ref.nativeElement, { childList: true, subtree: true })
			}
		})
	}
}

function smoothScroll(elem: any, left: number) {
	elem.scrollTo({
		left: left,
		behavior: 'smooth',
	})

	return new Promise((resolve, reject) => {
		const failed = setTimeout(() => {
			reject()
		}, 2000)
		const scrollHandler = () => {
			if (Math.floor(elem.scrollLeft) === Math.floor(left)) {
				elem.removeEventListener('scroll', scrollHandler)
				clearTimeout(failed)
				resolve(true)
			}
		}
		if (Math.floor(elem.scrollLeft) === Math.floor(left)) {
			clearTimeout(failed)
			resolve(true)
		} else {
			elem.addEventListener('scroll', scrollHandler)
		}
	})
}
export interface LightCarouselBreakpointOption {
	slides?: number
	nav?: boolean
}
