import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

class SessionStorage implements Storage {
	[name: string]: any
	readonly length!: number
	clear(): void {}
	getItem(key: string): string | null {
		return null
	}
	key(index: number): string | null {
		return null
	}
	removeItem(key: string): void {}
	setItem(key: string, value: string): void {}
}

@Injectable({
	providedIn: 'root',
})
export class SessionStorageService implements Storage {
	private storage: Storage

	constructor(@Inject(PLATFORM_ID) private platformId: any) {
		const isBrowser = isPlatformBrowser(this.platformId);
		this.storage = new SessionStorage()

		if (isBrowser) {
			this.storage = sessionStorage
		}
	}

	[name: string]: any

	length: number = 0

	clear(): void {
		this.storage.clear()
	}

	getItem(key: string): string | null {
		return this.storage.getItem(key)
	}

	key(index: number): string | null {
		return this.storage.key(index)
	}

	removeItem(key: string): void {
		return this.storage.removeItem(key)
	}

	setItem(key: string, value: string): void {
		try {
			return this.storage.setItem(key, value)
		} catch (err) {
			console.log(err)
		}
	}

	getItemJson(key: string): any | null {
		return this.storage.getItem(key) ? JSON.parse(this.storage.getItem(key) as string) : null
	}

	setItemJson(key: string, object: any): void {
		try {
			return this.storage.setItem(key, JSON.stringify(object))
		} catch (err) {
			console.log(err)
		}
	}
}
