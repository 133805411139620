<div class="container-fluid p-md-0 gx-0">
	<div class="row border-bottom gx-0">
		<div
			class="col-md-3 flex-column py-4 ms-md-3 border-md-right border-md-bottom-0 border-bottom px-4 px-md-5 d-flex"
		>
			<h3 class="text-uppercase mx-md-3 my-auto">My Account</h3>
		</div>
		<div class="col-md-6 py-4 px-4">
			<h2 class="text-uppercase mx-md-4 mb-0">BEAN COUNTER REWARDS</h2>
		</div>
	</div>
	<div class="background pb-4">
		<div class="row gx-0">
			<div class="border-bottom text-uppercase justify-content-sm-center d-flex wrap">
				<a class="fw-700 fs-12 p-2 inner" [routerLink]="['/accounts/subscription']">Manage subscription</a>
				<a class="fw-700 fs-12 p-2" [routerLink]="['/accounts/rewards']">Bean counter rewards</a>
				<a class="fw-700 fs-12 p-2">Log out</a>
			</div>
		</div>
		<div class="container-xxl">
			<div class="row my-5 gx-0">
				<div class="col-md-4 px-4 px-md-0">
					<app-details></app-details>
				</div>
				<div class="col-md-8 px-4 px-md-0 ps-md-4"><app-balance></app-balance></div>
			</div>
		</div>
	</div>
</div>
