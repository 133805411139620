import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2 } from '@angular/core'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, from, of } from 'rxjs'
import { Subject } from 'rxjs/internal/Subject'
import { catchError, debounceTime, first, map, switchMap } from 'rxjs/operators'
import { Collection } from '../classes/utility'
import { Product } from '../classes/product'
import { EnvironmentService } from './environment.service'
import { LocalStorageService } from './localstorage.service'
import { SeoService } from './seo.service'
import { SessionStorageService } from './sessionstorage.service'
import { Environment, storeMap } from 'src/environments/environment'
import { REQUEST } from 'src/express.tokens'
import { Request } from 'express'

@Injectable({
	providedIn: 'root',
})
export class UtilityService {
	queryParams = {}
	environment: any

	overlayStateSubject: Subject<{ type: string; meta?: any }> = new Subject<{ type: string; meta?: any }>()
	overlayStateObservable: Observable<{ type: string; meta?: any }> = this.overlayStateSubject.asObservable()

	headerStateSubject: Subject<number> = new Subject<number>()
	headerStateObservable: Observable<number> = this.headerStateSubject.asObservable()

	cartStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	cartStateObservable: Observable<boolean> = this.cartStateSubject.asObservable()

	searchStateSubject: Subject<string | null> = new Subject<string | null>()
	searchStateObservable: Observable<string | null> = this.searchStateSubject.asObservable()

	pageTitleSubject: Subject<string> = new Subject<string>()
	pageTitleObservable: Observable<string> = this.pageTitleSubject.asObservable()

	popupStateSubject: Subject<boolean> = new Subject<boolean>()
	popupStateObservable: Observable<boolean> = this.popupStateSubject.asObservable()

	collectionFilterStateSubject: ReplaySubject<any> = new ReplaySubject<any>()
	collectionFilterStateObservable: Observable<any> = this.collectionFilterStateSubject.asObservable()

	collectionSubject: ReplaySubject<Collection> = new ReplaySubject<Collection>()
	collectionObservable: Observable<Collection> = this.collectionSubject.asObservable()

	currentProductSubject: Subject<any> = new ReplaySubject<any>()
	currentProductObservable: Observable<any> = this.currentProductSubject.asObservable()

	resizeSubject: Subject<any> = new Subject<any>()
	resizeObservable: Observable<any> = this.resizeSubject.pipe(debounceTime(100))

	menuStateSubject: Subject<boolean> = new Subject<boolean>()
	menuStateObservable: Observable<boolean> = this.menuStateSubject.asObservable()

	subjectWishlistCount = new ReplaySubject<number>(1)
	observableWishlistCount = this.subjectWishlistCount.asObservable()

	constructor(
		private http: HttpClient,
		@Optional() @Inject(REQUEST) private request: Request,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: Object,
		private localStorage: LocalStorageService,
		private sessionStorage: SessionStorageService,
		private environmentService: EnvironmentService,
		private seoService: SeoService,
	) {
		this.environment = this.environmentService.environment
		this.environmentService.observableEnvironment.subscribe((data: any) => {
			this.environment = data
		})
	}
	subscribeToList(email: string) {
		return of()
	}
	isBrowser() {
		return isPlatformBrowser(this.platformId)
	}

	getWindow(): Window | null {
		return this.document?.defaultView
	}

	setWishlistCount(count: number) {
		this.subjectWishlistCount.next(count)
	}

	closeOverlay() {
		this.overlayStateSubject.next({ type: 'close' })
	}

	resize() {
		this.resizeSubject.next()
	}

	hexToRgbA(hex: string) {
		var c: any
		if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('')
			if (c.length == 3) {
				c = [c[0], c[0], c[1], c[1], c[2], c[2]]
			}
			c = '0x' + c.join('')
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',.8)'
		}
		throw new Error('Bad Hex')
	}

	contrastingColor(color: string) {
		return this.luma(color) >= 165 ? 'text-black' : 'text-cyan'
	}

	luma(color: string) {
		var rgb = typeof color === 'string' ? this.hexToRGBArray(color) : color
		return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]
	}

	hexToRGBArray(color: string) {
		if (color.length === 3) color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2)
		else if (color.length !== 6) throw 'Invalid hex color: ' + color
		var rgb = []
		for (var i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16)
		return rgb
	}

	loadJsScript(renderer: Renderer2, src: string, type?: string): HTMLScriptElement | null {
		const existingScript = Array.from(this.document.getElementsByTagName('script'))
			.find(script => script.src === src);

		if (existingScript) {
			console.warn(`Script with src "${src}" is already loaded.`);
			return existingScript;
		}

		const script = renderer.createElement('script');
		script.type = type || 'text/javascript';
		script.src = src;
		renderer.appendChild(this.document.body, script);
		return script;
	}

	clearAllModals() {
		this.overlayStateSubject.next({ type: 'close' })
		this.cartStateSubject.next(false)
		this.searchStateSubject.next()
		this.popupStateSubject.next()
		this.menuStateSubject.next(false)
	}

	toggleMenu(state: boolean) {
		this.menuStateSubject.next(state)
	}

	hideSearch() {
		this.searchStateSubject.next(null)
	}

	async executePopup() {
		if (this.isBrowser()) {
			this.clearAllModals()
			if (!this.localStorage.getItem('popup'))
				await new Promise(resolve => {
					setTimeout(() => {
						resolve(true)
					}, 1000)
				})
			this.overlayStateSubject.next({ type: 'popup' })
		}
	}

	closePopup() {
		this.localStorage.setItem('popup', 'true')
		this.closeOverlay()
	}

	async getCurrency(): Promise<string> {
		const environment = await this.environmentService.observableEnvironment.pipe(first()).toPromise();

		if (!this.isBrowser()) {
			return this.getCurrencyForServer(environment);
		}

		if (environment.country === 'US') {
			return this.getCurrencyForUS(environment);
		}

		const localStorageCurrency = this.localStorage.getItem('currency');
		if (environment.country !== 'US') {
			if (localStorageCurrency && storeMap.find(storeMetaItem => storeMetaItem.currency === localStorageCurrency)) {
				return Promise.resolve(localStorageCurrency);
			}

			return Promise.resolve('AUD');
		}

		console.warn("getCurrency falling back to AUD - this should not happen!");
		return Promise.resolve('AUD');
	}

	private getCurrencyForServer(environment: Environment): Promise<string> {
		const countryName = this.getCountryNameFromRequest(environment);
		if (countryName) {
			const foundCountry = storeMap.find(storeMetaItem => storeMetaItem.country === countryName);
			if (foundCountry) {
				return Promise.resolve(foundCountry.currency);
			}
		}

		if (environment.currency) {
			return Promise.resolve(environment.currency);
		}

		console.warn("getCurrencyForServer falling back to AUD - this should not happen!");
		return Promise.resolve('AUD');
	}

	private getCountryNameFromRequest(environment: Environment): string {
		if (environment.country === 'AU' && this.request && 'geoRedirect' in this.request.query) {
			if (typeof this.request.query.geoRedirect === 'string') {
				return this.request.query.geoRedirect;
			}
		}
		return '';
	}

	private getCurrencyForUS(environment: Environment): Promise<string> {
		const usEnvironmentCurrency = storeMap.find(storeMetaItem => storeMetaItem.country === environment.country);
		if (usEnvironmentCurrency) {
			return Promise.resolve(usEnvironmentCurrency.currency);
		}
		return Promise.resolve('USD');
	}

	async getFilteredCollection(handle: string, filters: any, sort?: string, take?: number, skip?: number, currency?: string, updateSEO: boolean = true): Promise<Collection> {
		if (!currency) {
			currency = await this.getCurrency();
		}

		return this.http
			.get('https://mercury.plutocracy.io/search/collection/' + handle, {
				params: {
					...(sort && { sort }),
					bypassToken: this.environment.bypassToken,
					...(filters && { query: JSON.stringify(filters) }),
					...(take && { take }),
					...(skip && { skip }),
					...(currency && { currency }),
				},
			})
			.pipe(
				map((x: any) => ({
					...x,
					products: x.products.map((y: any) => this.mapProductFields(y)),
				}))
			)
			.toPromise()
			.then(async data => {
				if (updateSEO) {
					await this.seoService.updateSEO(
						data?.metafields?.global?.title_tag ? data.metafields.global.title_tag : data.title,
						data?.metafields?.global?.description_tag ? data.metafields.global.description_tag : data.body_html
					)
				}

				return data
			})
	}

	getProductByHandle(handle: string): Observable<Product> {
		this.addToRecentlyViewedProducts(handle)

		console.log('getProductByHandle', handle)

		return from(this.getCurrency()).pipe(
			switchMap(currency => {
				const sessionKey = `${currency}-${handle}`;

				if (this.isBrowser() && this.sessionStorage.getItemJson(sessionKey)?.handle) {
					return of(this.sessionStorage.getItemJson(sessionKey));
				} else {
					return from(this.getCurrency()).pipe(
						switchMap((currency) => {
							return this.http
								.get<Product>('https://mercury.plutocracy.io/api/shopify/product/handle/' + handle, {
									params: {
										bypassToken: this.environment.bypassToken,
										currency: currency,
									},
								})
								.pipe(
									map((product: Product) =>
										this.mapProductFields(product),
									),
								);
						}),
					);
				}
			})
		);
	}

	mapProductFields(product: Product) {
		if (product.metafields?.PRP?.related) {
			try {
				product.metafields.PRP.related = JSON.parse(product.metafields.PRP.related as string)
			} catch {
				console.log('buggger')
			}
		}
		return product
	}

	// "handles" refers to a unique identifier for a resource, often used in URLs.
	// they are user-friendly, human-readable versions of resource names, such as product titles or collection names, and are used to create SEO-friendly URLs
	getProductsByHandles(handles: string[]): Observable<Product[]> {
		return from (this.getCurrency()).pipe(
			switchMap(currencyCode => {
				const cachedProducts: Product[] = []
				const missingHandles: string[] = []

				// Check cache for each handle
				handles.forEach(handle => {
					if (this.sessionStorage.getItemJson(currencyCode + '-' + handle)?.handle) {
						cachedProducts.push(this.sessionStorage.getItemJson(currencyCode + '-' + handle) as Product)
					} else {
						missingHandles.push(handle)
					}
				})
				if (missingHandles.length === 0) {
					return of(cachedProducts)
				}
				return this.http
					.get('https://mercury.plutocracy.io/api/shopify/product/handles/' + missingHandles.join(','), {
						params: {
							bypassToken: this.environment.bypassToken,
							currency: currencyCode,
						}
				}).pipe(
					map((products: any) => {
						products.forEach((product: Product) => {
							this.sessionStorage.setItemJson(currencyCode + '-' + product.handle, this.mapProductFields(product))
						})
						return handles.map(handle => this.sessionStorage.getItemJson(currencyCode + '-' + handle) as Product).filter(x => x !== null)
					}),
					catchError(() => of([])) // Handle errors gracefully using catchError
				)
			})
		);
	}
	popCart() {
		this.cartStateSubject.next(true)
	}

	closeCart() {
		this.cartStateSubject.next(false)
	}

	toggleCart() {
		this.cartStateSubject.next(!this.cartStateSubject.getValue());
	}

	addToRecentlyViewedProducts(handle: string): boolean {
		try {
			let collection = this.localStorage.getItemJson('recently')
			collection = collection && collection.length ? collection : [handle]
			collection.unshift(handle)
			collection = collection.filter((item: any, pos: any, self: any) => {
				return self.indexOf(item) == pos
			})
			try {
				this.localStorage.setItem('recently', JSON.stringify(collection))
			} catch {
				console.log('local storage unavailable')
			}
			return true
		} catch {
			console.log("couldn't add product to recently viewed")
			return false
		}
	}

	getRecentlyViewedProducts(numberOfResults: number = 6, handle?: string): Observable<Product[]> {
		try {
			let recently: string[] = this.localStorage.getItemJson('recently')
			if (handle) {
				recently = recently.filter(x => x != handle)
			}
			return from(
				this.getProductsByHandles(recently.slice(0, numberOfResults))
					.toPromise()
					.then(products => products.filter(x => x.id))
			)
		} catch {
			return from([])
		}
	}

	removeFromRecentlyViewedProducts(handle: string): boolean {
		try {
			let recently = JSON.parse(this.localStorage.getItem('recently') as string)
			if (recently) {
				recently = recently.filter((x: string) => x != handle)
			} else {
				recently = []
			}
			try {
				this.localStorage.setItem('recently', JSON.stringify(recently))
			} catch {
				console.log('local storage unavailable')
			}
			return true
		} catch {
			console.log("couldn't remove product to recently viewed")
			return false
		}
	}
}
