import { animate, query, stagger, style, transition, trigger } from '@angular/animations'

export const listFadeInBasic = trigger('listFadeInBasic', [
	transition('* => *', [
		query(
			':leave',
			[
				stagger(50, [
					animate(
						100,
						style({
							opacity: 0,
						})
					),
				]),
			],
			{ optional: true }
		),
		query(
			':enter',
			[
				style({
					opacity: 0,
				}),
				stagger(50, [
					animate(
						100,
						style({
							opacity: 1,
						})
					),
				]),
			],
			{ optional: true }
		),
	]),
])
