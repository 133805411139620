import { Router, NavigationEnd } from '@angular/router';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SafariCollectionFixService {
    private isFirstNavigation = true;

    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.setupNavigationEndListener();
    }

    private setupNavigationEndListener(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isFirstNavigation = false;
            }
        });
    }

    public shouldUseLazyDirective(): boolean {
        if (!isPlatformBrowser(this.platformId)) {
            return true;
        }

        const url = this.router.url;
        if (
            this.isFirstNavigation &&
            this.isSafari() &&
            url.match(/^\/collections\/[^\/]+$/)
        ) {
            return false;
        }

        return true;
    }

    private isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
}
