<ng-container>
	<button (click)="toggleCart()" [attr.aria-label]="(this.utilityService.cartStateSubject.getValue() ? 'Close' : 'Open') + ' the shopping cart summary modal'">
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0.500203 16H15.5964C15.8385 16 16 15.8346 16 15.5866C16 15.5452 16 15.4625 15.9596 15.4212L13.9414 4.29974C13.9011 4.09302 13.7396 3.96899 13.5378 3.96899H12.5287C12.2058 1.73643 10.349 0 8.04828 0C5.74753 0 3.89078 1.73643 3.56787 3.96899H2.43768C2.23586 3.96899 2.0744 4.09302 2.03404 4.29974L0.0158351 15.5039C-0.024529 15.6279 0.0158346 15.7519 0.0965627 15.8346C0.258019 15.9173 0.379111 16 0.500203 16ZM8.08864 0.785529C9.90503 0.785529 11.4389 2.14987 11.7618 3.92765H4.45588C4.77879 2.14987 6.27226 0.785529 8.08864 0.785529ZM2.84132 4.79587H13.2149L15.0716 15.1731H0.944209L2.84132 4.79587Z"
				fill="black"
			/>
		</svg>
		<div *ngIf="i > 0" class="dot"></div>
	</button>
</ng-container>
