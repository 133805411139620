<div class="row mt-sm-5 pt-md-5 pt-4">
	<div class="col-11 offset-1 offset-sm-0 col-sm-2 my-2 my-sm-0 grey">
		<h1 class="justify-content-sm-end d-flex ver-text times mx-md-auto">16 / Account</h1>
	</div>

	<div class="col-12 col-sm-10 bg-white drop py-4 py-md-5 mb-sm-5">
		<div class="row d-none d-md-block">
			<div class="col-11 offset-md-1 col-12 mt-lg-5 ps-0"></div>
		</div>

		<div class="row">
			<div class="col-lg-5 offset-lg-1 col-12 pe-5 ps-5 ps-lg-0 mb-4">
				<h6 class="mb-2 pt-4 pt-md-0 aktiv fs-5 fw-bold">Sign in</h6>
				<p class="aktiv fs-6 mb-md-5">Sign in below to login</p>

				<form [formGroup]="loginForm" (ngSubmit)="login()">
					
					<div class="form-group"><!-- Email input -->
					  <input formControlName="email" class="form-control p-3 fs-6" placeholder="Email" type="email"/>
					  <div *ngIf="showValidation('email')" class="mt-2">
						<div *ngIf="formControls.email.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
						<div *ngIf="formControls.email.errors?.minlength" class="mt-2 text-danger fs-6">Email must be at least 8 characters long.</div>
						<div *ngIf="formControls.email.errors?.invalidEmail" class="mt-2 text-danger fs-6">Please enter a valid email address.</div>
					  </div>
					</div>
				  
					<div class="form-group pt-3"><!-- Password input -->
					  <input formControlName="password" class="form-control p-3 fs-6" placeholder="Password" type="password"/>
					  <div *ngIf="showValidation('password')" class="mt-2">
						<div *ngIf="formControls.password.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
						<div *ngIf="formControls.password.errors?.minlength" class="mt-2 text-danger fs-6">Password must be at least 8 characters long.</div>
					  </div>
					  <div class="text-danger" *ngIf="error">Incorrect username or password provided</div>
					</div>

					<div class="form-group mt-3 mb-md-5 pb-md-5">
						<button class="w-100 btn btn-primary btn-lg p-3"
							[ngClass]="{ 'ng-invalid': isFormValid }"
							type="submit">
								{{ isFormValid ? 'complete required fields' : 'login' }}
						</button>
						<a class="mt-3 link-dark text-decoration-underline fs-6" href="#" [routerLink]="['/accounts/recover']">
							Forgot password?
						</a>
					</div>
				</form>

			</div>
			<div class="col-lg-5 col-12 pe-5 ps-5 ps-lg-0">
				<app-account-register></app-account-register>
			</div>
		</div>
	</div>
</div>
