// tslint:disable
export const AllowedAddresses = [
	{
		readable: 'Afghanistan',
		provinces: [],
	},
	{
		readable: 'Aland Islands',
		provinces: [],
	},
	{
		readable: 'Albania',
		provinces: [],
	},
	{
		readable: 'Algeria',
		provinces: [],
	},
	{
		readable: 'Andorra',
		provinces: [],
	},
	{
		readable: 'Angola',
		provinces: [],
	},
	{
		readable: 'Anguilla',
		provinces: [],
	},
	{
		readable: 'Antigua And Barbuda',
		provinces: [],
	},
	{
		readable: 'Argentina',
		provinces: [
			['Buenos Aires', 'Buenos Aires'],
			['Catamarca', 'Catamarca'],
			['Chaco', 'Chaco'],
			['Chubut', 'Chubut'],
			['Ciudad Autónoma de Buenos Aires', 'Ciudad Autónoma de Buenos Aires'],
			['Córdoba', 'Córdoba'],
			['Corrientes', 'Corrientes'],
			['Entre Ríos', 'Entre Ríos'],
			['Formosa', 'Formosa'],
			['Jujuy', 'Jujuy'],
			['La Pampa', 'La Pampa'],
			['La Rioja', 'La Rioja'],
			['Mendoza', 'Mendoza'],
			['Misiones', 'Misiones'],
			['Neuquén', 'Neuquén'],
			['Río Negro', 'Río Negro'],
			['Salta', 'Salta'],
			['San Juan', 'San Juan'],
			['San Luis', 'San Luis'],
			['Santa Cruz', 'Santa Cruz'],
			['Santa Fe', 'Santa Fe'],
			['Santiago Del Estero', 'Santiago Del Estero'],
			['Tierra Del Fuego', 'Tierra Del Fuego'],
			['Tucumán', 'Tucumán'],
		],
	},
	{
		readable: 'Armenia',
		provinces: [],
	},
	{
		readable: 'Aruba',
		provinces: [],
	},
	{
		readable: 'Australia',
		provinces: [
			['Australian Capital Territory', 'Australian Capital Territory'],
			['New South Wales', 'New South Wales'],
			['Northern Territory', 'Northern Territory'],
			['Queensland', 'Queensland'],
			['South Australia', 'South Australia'],
			['Tasmania', 'Tasmania'],
			['Victoria', 'Victoria'],
			['Western Australia', 'Western Australia'],
		],
	},
	{
		readable: 'Austria',
		provinces: [],
	},
	{
		readable: 'Azerbaijan',
		provinces: [],
	},
	{
		readable: 'Bahamas',
		provinces: [],
	},
	{
		readable: 'Bahrain',
		provinces: [],
	},
	{
		readable: 'Bangladesh',
		provinces: [],
	},
	{
		readable: 'Barbados',
		provinces: [],
	},
	{
		readable: 'Belarus',
		provinces: [],
	},
	{
		readable: 'Belgium',
		provinces: [],
	},
	{
		readable: 'Belize',
		provinces: [],
	},
	{
		readable: 'Benin',
		provinces: [],
	},
	{
		readable: 'Bermuda',
		provinces: [],
	},
	{
		readable: 'Bhutan',
		provinces: [],
	},
	{
		readable: 'Bolivia',
		provinces: [],
	},
	{
		readable: 'Bosnia And Herzegovina',
		provinces: [],
	},
	{
		readable: 'Botswana',
		provinces: [],
	},
	{
		readable: 'Bouvet Island',
		provinces: [],
	},
	{
		readable: 'Brazil',
		provinces: [
			['Acre', 'Acre'],
			['Alagoas', 'Alagoas'],
			['Amapá', 'Amapá'],
			['Amazonas', 'Amazonas'],
			['Bahia', 'Bahia'],
			['Ceará', 'Ceará'],
			['Distrito Federal', 'Distrito Federal'],
			['Espírito Santo', 'Espírito Santo'],
			['Goiás', 'Goiás'],
			['Maranhão', 'Maranhão'],
			['Mato Grosso', 'Mato Grosso'],
			['Mato Grosso do Sul', 'Mato Grosso do Sul'],
			['Minas Gerais', 'Minas Gerais'],
			['Pará', 'Pará'],
			['Paraíba', 'Paraíba'],
			['Paraná', 'Paraná'],
			['Pernambuco', 'Pernambuco'],
			['Piauí', 'Piauí'],
			['Rio Grande do Norte', 'Rio Grande do Norte'],
			['Rio Grande do Sul', 'Rio Grande do Sul'],
			['Rio de Janeiro', 'Rio de Janeiro'],
			['Rondônia', 'Rondônia'],
			['Roraima', 'Roraima'],
			['Santa Catarina', 'Santa Catarina'],
			['São Paulo', 'São Paulo'],
			['Sergipe', 'Sergipe'],
			['Tocantins', 'Tocantins'],
		],
	},
	{
		readable: 'British Indian Ocean Territory',
		provinces: [],
	},
	{
		readable: 'Virgin Islands, British',
		provinces: [],
	},
	{
		readable: 'Brunei',
		provinces: [],
	},
	{
		readable: 'Bulgaria',
		provinces: [],
	},
	{
		readable: 'Burkina Faso',
		provinces: [],
	},
	{
		readable: 'Burundi',
		provinces: [],
	},
	{
		readable: 'Cambodia',
		provinces: [],
	},
	{
		readable: 'Republic of Cameroon',
		provinces: [],
	},
	{
		readable: 'Canada',
		provinces: [
			['Alberta', 'Alberta'],
			['British Columbia', 'British Columbia'],
			['Manitoba', 'Manitoba'],
			['New Brunswick', 'New Brunswick'],
			['Newfoundland', 'Newfoundland and Labrador'],
			['Northwest Territories', 'Northwest Territories'],
			['Nova Scotia', 'Nova Scotia'],
			['Nunavut', 'Nunavut'],
			['Ontario', 'Ontario'],
			['Prince Edward Island', 'Prince Edward Island'],
			['Quebec', 'Quebec'],
			['Saskatchewan', 'Saskatchewan'],
			['Yukon', 'Yukon'],
		],
	},
	{
		readable: 'Cape Verde',
		provinces: [],
	},
	{
		readable: 'Caribbean Netherlands',
		provinces: [],
	},
	{
		readable: 'Cayman Islands',
		provinces: [],
	},
	{
		readable: 'Central African Republic',
		provinces: [],
	},
	{
		readable: 'Chad',
		provinces: [],
	},
	{
		readable: 'Chile',
		provinces: [
			['Antofagasta', 'Antofagasta'],
			['Araucanía', 'Araucanía'],
			['Arica and Parinacota', 'Arica and Parinacota'],
			['Atacama', 'Atacama'],
			['Aysén', 'Aysén'],
			['Biobío', 'Biobío'],
			['Coquimbo', 'Coquimbo'],
			['Los Lagos', 'Los Lagos'],
			['Los Ríos', 'Los Ríos'],
			['Magallanes', 'Magallanes'],
			['Maule', 'Maule'],
			['Ñuble', 'Ñuble'],
			["O'Higgins", "O'Higgins"],
			['Santiago', 'Santiago'],
			['Tarapacá', 'Tarapacá'],
			['Valparaíso', 'Valparaíso'],
		],
	},
	{
		readable: 'China',
		provinces: [
			['Anhui', 'Anhui'],
			['Beijing', 'Beijing'],
			['Chongqing', 'Chongqing'],
			['Fujian', 'Fujian'],
			['Gansu', 'Gansu'],
			['Guangdong', 'Guangdong'],
			['Guangxi', 'Guangxi'],
			['Guizhou', 'Guizhou'],
			['Hainan', 'Hainan'],
			['Hebei', 'Hebei'],
			['Heilongjiang', 'Heilongjiang'],
			['Henan', 'Henan'],
			['Hubei', 'Hubei'],
			['Hunan', 'Hunan'],
			['Inner Mongolia', 'Inner Mongolia'],
			['Jiangsu', 'Jiangsu'],
			['Jiangxi', 'Jiangxi'],
			['Jilin', 'Jilin'],
			['Liaoning', 'Liaoning'],
			['Ningxia', 'Ningxia'],
			['Qinghai', 'Qinghai'],
			['Shaanxi', 'Shaanxi'],
			['Shandong', 'Shandong'],
			['Shanghai', 'Shanghai'],
			['Shanxi', 'Shanxi'],
			['Sichuan', 'Sichuan'],
			['Tianjin', 'Tianjin'],
			['Xinjiang', 'Xinjiang'],
			['Xizang', 'Xizang'],
			['Yunnan', 'Yunnan'],
			['Zhejiang', 'Zhejiang'],
		],
	},
	{
		readable: 'Christmas Island',
		provinces: [],
	},
	{
		readable: 'Cocos (Keeling) Islands',
		provinces: [],
	},
	{
		readable: 'Colombia',
		provinces: [
			['Bogotá, D.C.', 'Bogotá, D.C.'],
			['Amazonas', 'Amazonas'],
			['Antioquia', 'Antioquia'],
			['Arauca', 'Arauca'],
			['Atlántico', 'Atlántico'],
			['Bolívar', 'Bolívar'],
			['Boyacá', 'Boyacá'],
			['Caldas', 'Caldas'],
			['Caquetá', 'Caquetá'],
			['Casanare', 'Casanare'],
			['Cauca', 'Cauca'],
			['Cesar', 'Cesar'],
			['Chocó', 'Chocó'],
			['Córdoba', 'Córdoba'],
			['Cundinamarca', 'Cundinamarca'],
			['Guainía', 'Guainía'],
			['Guaviare', 'Guaviare'],
			['Huila', 'Huila'],
			['La Guajira', 'La Guajira'],
			['Magdalena', 'Magdalena'],
			['Meta', 'Meta'],
			['Nariño', 'Nariño'],
			['Norte de Santander', 'Norte de Santander'],
			['Putumayo', 'Putumayo'],
			['Quindío', 'Quindío'],
			['Risaralda', 'Risaralda'],
			['San Andrés, Providencia y Santa Catalina', 'San Andrés, Providencia y Santa Catalina'],
			['Santander', 'Santander'],
			['Sucre', 'Sucre'],
			['Tolima', 'Tolima'],
			['Valle del Cauca', 'Valle del Cauca'],
			['Vaupés', 'Vaupés'],
			['Vichada', 'Vichada'],
		],
	},
	{
		readable: 'Comoros',
		provinces: [],
	},
	{
		readable: 'Congo',
		provinces: [],
	},
	{
		readable: 'Congo, The Democratic Republic Of The',
		provinces: [],
	},
	{
		readable: 'Cook Islands',
		provinces: [],
	},
	{
		readable: 'Costa Rica',
		provinces: [],
	},
	{
		readable: 'Croatia',
		provinces: [],
	},
	{
		readable: 'Cuba',
		provinces: [],
	},
	{
		readable: 'Curaçao',
		provinces: [],
	},
	{
		readable: 'Cyprus',
		provinces: [],
	},
	{
		readable: 'Czech Republic',
		provinces: [],
	},
	{
		readable: "Côte d'Ivoire",
		provinces: [],
	},
	{
		readable: 'Denmark',
		provinces: [],
	},
	{
		readable: 'Djibouti',
		provinces: [],
	},
	{
		readable: 'Dominica',
		provinces: [],
	},
	{
		readable: 'Dominican Republic',
		provinces: [],
	},
	{
		readable: 'Ecuador',
		provinces: [],
	},
	{
		readable: 'Egypt',
		provinces: [
			['6th of October', '6th of October'],
			['Al Sharqia', 'Al Sharqia'],
			['Alexandria', 'Alexandria'],
			['Aswan', 'Aswan'],
			['Asyut', 'Asyut'],
			['Beheira', 'Beheira'],
			['Beni Suef', 'Beni Suef'],
			['Cairo', 'Cairo'],
			['Dakahlia', 'Dakahlia'],
			['Damietta', 'Damietta'],
			['Faiyum', 'Faiyum'],
			['Gharbia', 'Gharbia'],
			['Giza', 'Giza'],
			['Helwan', 'Helwan'],
			['Ismailia', 'Ismailia'],
			['Kafr el-Sheikh', 'Kafr el-Sheikh'],
			['Luxor', 'Luxor'],
			['Matrouh', 'Matrouh'],
			['Minya', 'Minya'],
			['Monufia', 'Monufia'],
			['New Valley', 'New Valley'],
			['North Sinai', 'North Sinai'],
			['Port Said', 'Port Said'],
			['Qalyubia', 'Qalyubia'],
			['Qena', 'Qena'],
			['Red Sea', 'Red Sea'],
			['Sohag', 'Sohag'],
			['South Sinai', 'South Sinai'],
			['Suez', 'Suez'],
		],
	},
	{
		readable: 'El Salvador',
		provinces: [],
	},
	{
		readable: 'Equatorial Guinea',
		provinces: [],
	},
	{
		readable: 'Eritrea',
		provinces: [],
	},
	{
		readable: 'Estonia',
		provinces: [],
	},
	{
		readable: 'Eswatini',
		provinces: [],
	},
	{
		readable: 'Ethiopia',
		provinces: [],
	},
	{
		readable: 'Falkland Islands (Malvinas)',
		provinces: [],
	},
	{
		readable: 'Faroe Islands',
		provinces: [],
	},
	{
		readable: 'Fiji',
		provinces: [],
	},
	{
		readable: 'Finland',
		provinces: [],
	},
	{
		readable: 'France',
		provinces: [],
	},
	{
		readable: 'French Guiana',
		provinces: [],
	},
	{
		readable: 'French Polynesia',
		provinces: [],
	},
	{
		readable: 'French Southern Territories',
		provinces: [],
	},
	{
		readable: 'Gabon',
		provinces: [],
	},
	{
		readable: 'Gambia',
		provinces: [],
	},
	{
		readable: 'Georgia',
		provinces: [],
	},
	{
		readable: 'Germany',
		provinces: [],
	},
	{
		readable: 'Ghana',
		provinces: [],
	},
	{
		readable: 'Gibraltar',
		provinces: [],
	},
	{
		readable: 'Greece',
		provinces: [],
	},
	{
		readable: 'Greenland',
		provinces: [],
	},
	{
		readable: 'Grenada',
		provinces: [],
	},
	{
		readable: 'Guadeloupe',
		provinces: [],
	},
	{
		readable: 'Guatemala',
		provinces: [
			['Alta Verapaz', 'Alta Verapaz'],
			['Baja Verapaz', 'Baja Verapaz'],
			['Chimaltenango', 'Chimaltenango'],
			['Chiquimula', 'Chiquimula'],
			['El Progreso', 'El Progreso'],
			['Escuintla', 'Escuintla'],
			['Guatemala', 'Guatemala'],
			['Huehuetenango', 'Huehuetenango'],
			['Izabal', 'Izabal'],
			['Jalapa', 'Jalapa'],
			['Jutiapa', 'Jutiapa'],
			['Petén', 'Petén'],
			['Quetzaltenango', 'Quetzaltenango'],
			['Quiché', 'Quiché'],
			['Retalhuleu', 'Retalhuleu'],
			['Sacatepéquez', 'Sacatepéquez'],
			['San Marcos', 'San Marcos'],
			['Santa Rosa', 'Santa Rosa'],
			['Sololá', 'Sololá'],
			['Suchitepéquez', 'Suchitepéquez'],
			['Totonicapán', 'Totonicapán'],
			['Zacapa', 'Zacapa'],
		],
	},
	{
		readable: 'Guernsey',
		provinces: [],
	},
	{
		readable: 'Guinea',
		provinces: [],
	},
	{
		readable: 'Guinea Bissau',
		provinces: [],
	},
	{
		readable: 'Guyana',
		provinces: [],
	},
	{
		readable: 'Haiti',
		provinces: [],
	},
	{
		readable: 'Heard Island And Mcdonald Islands',
		provinces: [],
	},
	{
		readable: 'Honduras',
		provinces: [],
	},
	{
		readable: 'Hong Kong',
		provinces: [
			['Hong Kong Island', 'Hong Kong Island'],
			['Kowloon', 'Kowloon'],
			['New Territories', 'New Territories'],
		],
	},
	{
		readable: 'Hungary',
		provinces: [],
	},
	{
		readable: 'Iceland',
		provinces: [],
	},
	{
		readable: 'India',
		provinces: [
			['Andaman and Nicobar', 'Andaman and Nicobar'],
			['Andhra Pradesh', 'Andhra Pradesh'],
			['Arunachal Pradesh', 'Arunachal Pradesh'],
			['Assam', 'Assam'],
			['Bihar', 'Bihar'],
			['Chandigarh', 'Chandigarh'],
			['Chattisgarh', 'Chattisgarh'],
			['Dadra and Nagar Haveli', 'Dadra and Nagar Haveli'],
			['Daman and Diu', 'Daman and Diu'],
			['Delhi', 'Delhi'],
			['Goa', 'Goa'],
			['Gujarat', 'Gujarat'],
			['Haryana', 'Haryana'],
			['Himachal Pradesh', 'Himachal Pradesh'],
			['Jammu and Kashmir', 'Jammu and Kashmir'],
			['Jharkhand', 'Jharkhand'],
			['Karnataka', 'Karnataka'],
			['Kerala', 'Kerala'],
			['Lakshadweep', 'Lakshadweep'],
			['Madhya Pradesh', 'Madhya Pradesh'],
			['Maharashtra', 'Maharashtra'],
			['Manipur', 'Manipur'],
			['Meghalaya', 'Meghalaya'],
			['Mizoram', 'Mizoram'],
			['Nagaland', 'Nagaland'],
			['Orissa', 'Odisha'],
			['Puducherry', 'Puducherry'],
			['Punjab', 'Punjab'],
			['Rajasthan', 'Rajasthan'],
			['Sikkim', 'Sikkim'],
			['Tamil Nadu', 'Tamil Nadu'],
			['Telangana', 'Telangana'],
			['Tripura', 'Tripura'],
			['Uttar Pradesh', 'Uttar Pradesh'],
			['Uttarakhand', 'Uttarakhand'],
			['West Bengal', 'West Bengal'],
		],
	},
	{
		readable: 'Indonesia',
		provinces: [
			['Aceh', 'Aceh'],
			['Bali', 'Bali'],
			['Bangka Belitung', 'Bangka Belitung'],
			['Banten', 'Banten'],
			['Bengkulu', 'Bengkulu'],
			['Gorontalo', 'Gorontalo'],
			['Jakarta', 'Jakarta'],
			['Jambi', 'Jambi'],
			['Jawa Barat', 'Jawa Barat'],
			['Jawa Tengah', 'Jawa Tengah'],
			['Jawa Timur', 'Jawa Timur'],
			['Kalimantan Barat', 'Kalimantan Barat'],
			['Kalimantan Selatan', 'Kalimantan Selatan'],
			['Kalimantan Tengah', 'Kalimantan Tengah'],
			['Kalimantan Timur', 'Kalimantan Timur'],
			['Kalimantan Utara', 'Kalimantan Utara'],
			['Kepulauan Riau', 'Kepulauan Riau'],
			['Lampung', 'Lampung'],
			['Maluku', 'Maluku'],
			['Maluku Utara', 'Maluku Utara'],
			['Nusa Tenggara Barat', 'Nusa Tenggara Barat'],
			['Nusa Tenggara Timur', 'Nusa Tenggara Timur'],
			['Papua', 'Papua'],
			['Papua Barat', 'Papua Barat'],
			['Riau', 'Riau'],
			['Sulawesi Barat', 'Sulawesi Barat'],
			['Sulawesi Selatan', 'Sulawesi Selatan'],
			['Sulawesi Tengah', 'Sulawesi Tengah'],
			['Sulawesi Tenggara', 'Sulawesi Tenggara'],
			['Sulawesi Utara', 'Sulawesi Utara'],
			['Sumatra Barat', 'Sumatra Barat'],
			['Sumatra Selatan', 'Sumatra Selatan'],
			['Sumatra Utara', 'Sumatra Utara'],
			['Yogyakarta', 'Yogyakarta'],
		],
	},
	{
		readable: 'Iran, Islamic Republic Of',
		provinces: [],
	},
	{
		readable: 'Iraq',
		provinces: [],
	},
	{
		readable: 'Ireland',
		provinces: [
			['Carlow', 'Carlow'],
			['Cavan', 'Cavan'],
			['Clare', 'Clare'],
			['Cork', 'Cork'],
			['Donegal', 'Donegal'],
			['Dublin', 'Dublin'],
			['Galway', 'Galway'],
			['Kerry', 'Kerry'],
			['Kildare', 'Kildare'],
			['Kilkenny', 'Kilkenny'],
			['Laois', 'Laois'],
			['Leitrim', 'Leitrim'],
			['Limerick', 'Limerick'],
			['Longford', 'Longford'],
			['Louth', 'Louth'],
			['Mayo', 'Mayo'],
			['Meath', 'Meath'],
			['Monaghan', 'Monaghan'],
			['Offaly', 'Offaly'],
			['Roscommon', 'Roscommon'],
			['Sligo', 'Sligo'],
			['Tipperary', 'Tipperary'],
			['Waterford', 'Waterford'],
			['Westmeath', 'Westmeath'],
			['Wexford', 'Wexford'],
			['Wicklow', 'Wicklow'],
		],
	},
	{
		readable: 'Isle Of Man',
		provinces: [],
	},
	{
		readable: 'Israel',
		provinces: [],
	},
	{
		readable: 'Italy',
		provinces: [
			['Agrigento', 'Agrigento'],
			['Alessandria', 'Alessandria'],
			['Ancona', 'Ancona'],
			['Aosta', 'Aosta'],
			['Arezzo', 'Arezzo'],
			['Ascoli Piceno', 'Ascoli Piceno'],
			['Asti', 'Asti'],
			['Avellino', 'Avellino'],
			['Bari', 'Bari'],
			['Barletta-Andria-Trani', 'Barletta-Andria-Trani'],
			['Belluno', 'Belluno'],
			['Benevento', 'Benevento'],
			['Bergamo', 'Bergamo'],
			['Biella', 'Biella'],
			['Bologna', 'Bologna'],
			['Bolzano', 'Bolzano'],
			['Brescia', 'Brescia'],
			['Brindisi', 'Brindisi'],
			['Cagliari', 'Cagliari'],
			['Caltanissetta', 'Caltanissetta'],
			['Campobasso', 'Campobasso'],
			['Carbonia-Iglesias', 'Carbonia-Iglesias'],
			['Caserta', 'Caserta'],
			['Catania', 'Catania'],
			['Catanzaro', 'Catanzaro'],
			['Chieti', 'Chieti'],
			['Como', 'Como'],
			['Cosenza', 'Cosenza'],
			['Cremona', 'Cremona'],
			['Crotone', 'Crotone'],
			['Cuneo', 'Cuneo'],
			['Enna', 'Enna'],
			['Fermo', 'Fermo'],
			['Ferrara', 'Ferrara'],
			['Firenze', 'Firenze'],
			['Foggia', 'Foggia'],
			['Forlì-Cesena', 'Forlì-Cesena'],
			['Frosinone', 'Frosinone'],
			['Genova', 'Genova'],
			['Gorizia', 'Gorizia'],
			['Grosseto', 'Grosseto'],
			['Imperia', 'Imperia'],
			['Isernia', 'Isernia'],
			["L'Aquila", "L'Aquila"],
			['La Spezia', 'La Spezia'],
			['Latina', 'Latina'],
			['Lecce', 'Lecce'],
			['Lecco', 'Lecco'],
			['Livorno', 'Livorno'],
			['Lodi', 'Lodi'],
			['Lucca', 'Lucca'],
			['Macerata', 'Macerata'],
			['Mantova', 'Mantova'],
			['Massa-Carrara', 'Massa-Carrara'],
			['Matera', 'Matera'],
			['Medio Campidano', 'Medio Campidano'],
			['Messina', 'Messina'],
			['Milano', 'Milano'],
			['Modena', 'Modena'],
			['Monza e Brianza', 'Monza e Brianza'],
			['Napoli', 'Napoli'],
			['Novara', 'Novara'],
			['Nuoro', 'Nuoro'],
			['Ogliastra', 'Ogliastra'],
			['Olbia-Tempio', 'Olbia-Tempio'],
			['Oristano', 'Oristano'],
			['Padova', 'Padova'],
			['Palermo', 'Palermo'],
			['Parma', 'Parma'],
			['Pavia', 'Pavia'],
			['Perugia', 'Perugia'],
			['Pesaro e Urbino', 'Pesaro e Urbino'],
			['Pescara', 'Pescara'],
			['Piacenza', 'Piacenza'],
			['Pisa', 'Pisa'],
			['Pistoia', 'Pistoia'],
			['Pordenone', 'Pordenone'],
			['Potenza', 'Potenza'],
			['Prato', 'Prato'],
			['Ragusa', 'Ragusa'],
			['Ravenna', 'Ravenna'],
			['Reggio Calabria', 'Reggio Calabria'],
			['Reggio Emilia', 'Reggio Emilia'],
			['Rieti', 'Rieti'],
			['Rimini', 'Rimini'],
			['Roma', 'Roma'],
			['Rovigo', 'Rovigo'],
			['Salerno', 'Salerno'],
			['Sassari', 'Sassari'],
			['Savona', 'Savona'],
			['Siena', 'Siena'],
			['Siracusa', 'Siracusa'],
			['Sondrio', 'Sondrio'],
			['Taranto', 'Taranto'],
			['Teramo', 'Teramo'],
			['Terni', 'Terni'],
			['Torino', 'Torino'],
			['Trapani', 'Trapani'],
			['Trento', 'Trento'],
			['Treviso', 'Treviso'],
			['Trieste', 'Trieste'],
			['Udine', 'Udine'],
			['Varese', 'Varese'],
			['Venezia', 'Venezia'],
			['Verbano-Cusio-Ossola', 'Verbano-Cusio-Ossola'],
			['Vercelli', 'Vercelli'],
			['Verona', 'Verona'],
			['Vibo Valentia', 'Vibo Valentia'],
			['Vicenza', 'Vicenza'],
			['Viterbo', 'Viterbo'],
		],
	},
	{
		readable: 'Jamaica',
		provinces: [],
	},
	{
		readable: 'Japan',
		provinces: [
			['Hokkaidō', 'Hokkaidō'],
			['Aomori', 'Aomori'],
			['Iwate', 'Iwate'],
			['Miyagi', 'Miyagi'],
			['Akita', 'Akita'],
			['Yamagata', 'Yamagata'],
			['Fukushima', 'Fukushima'],
			['Ibaraki', 'Ibaraki'],
			['Tochigi', 'Tochigi'],
			['Gunma', 'Gunma'],
			['Saitama', 'Saitama'],
			['Chiba', 'Chiba'],
			['Tōkyō', 'Tōkyō'],
			['Kanagawa', 'Kanagawa'],
			['Niigata', 'Niigata'],
			['Toyama', 'Toyama'],
			['Ishikawa', 'Ishikawa'],
			['Fukui', 'Fukui'],
			['Yamanashi', 'Yamanashi'],
			['Nagano', 'Nagano'],
			['Gifu', 'Gifu'],
			['Shizuoka', 'Shizuoka'],
			['Aichi', 'Aichi'],
			['Mie', 'Mie'],
			['Shiga', 'Shiga'],
			['Kyōto', 'Kyōto'],
			['Ōsaka', 'Ōsaka'],
			['Hyōgo', 'Hyōgo'],
			['Nara', 'Nara'],
			['Wakayama', 'Wakayama'],
			['Tottori', 'Tottori'],
			['Shimane', 'Shimane'],
			['Okayama', 'Okayama'],
			['Hiroshima', 'Hiroshima'],
			['Yamaguchi', 'Yamaguchi'],
			['Tokushima', 'Tokushima'],
			['Kagawa', 'Kagawa'],
			['Ehime', 'Ehime'],
			['Kōchi', 'Kōchi'],
			['Fukuoka', 'Fukuoka'],
			['Saga', 'Saga'],
			['Nagasaki', 'Nagasaki'],
			['Kumamoto', 'Kumamoto'],
			['Ōita', 'Ōita'],
			['Miyazaki', 'Miyazaki'],
			['Kagoshima', 'Kagoshima'],
			['Okinawa', 'Okinawa'],
		],
	},
	{
		readable: 'Jersey',
		provinces: [],
	},
	{
		readable: 'Jordan',
		provinces: [],
	},
	{
		readable: 'Kazakhstan',
		provinces: [],
	},
	{
		readable: 'Kenya',
		provinces: [],
	},
	{
		readable: 'Kiribati',
		provinces: [],
	},
	{
		readable: 'Kosovo',
		provinces: [],
	},
	{
		readable: 'Kuwait',
		provinces: [],
	},
	{
		readable: 'Kyrgyzstan',
		provinces: [],
	},
	{
		readable: "Lao People's Democratic Republic",
		provinces: [],
	},
	{
		readable: 'Latvia',
		provinces: [],
	},
	{
		readable: 'Lebanon',
		provinces: [],
	},
	{
		readable: 'Lesotho',
		provinces: [],
	},
	{
		readable: 'Liberia',
		provinces: [],
	},
	{
		readable: 'Libyan Arab Jamahiriya',
		provinces: [],
	},
	{
		readable: 'Liechtenstein',
		provinces: [],
	},
	{
		readable: 'Lithuania',
		provinces: [],
	},
	{
		readable: 'Luxembourg',
		provinces: [],
	},
	{
		readable: 'Macao',
		provinces: [],
	},
	{
		readable: 'Madagascar',
		provinces: [],
	},
	{
		readable: 'Malawi',
		provinces: [],
	},
	{
		readable: 'Malaysia',
		provinces: [
			['Johor', 'Johor'],
			['Kedah', 'Kedah'],
			['Kelantan', 'Kelantan'],
			['Kuala Lumpur', 'Kuala Lumpur'],
			['Labuan', 'Labuan'],
			['Melaka', 'Melaka'],
			['Negeri Sembilan', 'Negeri Sembilan'],
			['Pahang', 'Pahang'],
			['Perak', 'Perak'],
			['Perlis', 'Perlis'],
			['Pulau Pinang', 'Pulau Pinang'],
			['Putrajaya', 'Putrajaya'],
			['Sabah', 'Sabah'],
			['Sarawak', 'Sarawak'],
			['Selangor', 'Selangor'],
			['Terengganu', 'Terengganu'],
		],
	},
	{
		readable: 'Maldives',
		provinces: [],
	},
	{
		readable: 'Mali',
		provinces: [],
	},
	{
		readable: 'Malta',
		provinces: [],
	},
	{
		readable: 'Martinique',
		provinces: [],
	},
	{
		readable: 'Mauritania',
		provinces: [],
	},
	{
		readable: 'Mauritius',
		provinces: [],
	},
	{
		readable: 'Mayotte',
		provinces: [],
	},
	{
		readable: 'Mexico',
		provinces: [
			['Aguascalientes', 'Aguascalientes'],
			['Baja California', 'Baja California'],
			['Baja California Sur', 'Baja California Sur'],
			['Campeche', 'Campeche'],
			['Chiapas', 'Chiapas'],
			['Chihuahua', 'Chihuahua'],
			['Ciudad de México', 'Ciudad de México'],
			['Coahuila', 'Coahuila'],
			['Colima', 'Colima'],
			['Durango', 'Durango'],
			['Guanajuato', 'Guanajuato'],
			['Guerrero', 'Guerrero'],
			['Hidalgo', 'Hidalgo'],
			['Jalisco', 'Jalisco'],
			['México', 'México'],
			['Michoacán', 'Michoacán'],
			['Morelos', 'Morelos'],
			['Nayarit', 'Nayarit'],
			['Nuevo León', 'Nuevo León'],
			['Oaxaca', 'Oaxaca'],
			['Puebla', 'Puebla'],
			['Querétaro', 'Querétaro'],
			['Quintana Roo', 'Quintana Roo'],
			['San Luis Potosí', 'San Luis Potosí'],
			['Sinaloa', 'Sinaloa'],
			['Sonora', 'Sonora'],
			['Tabasco', 'Tabasco'],
			['Tamaulipas', 'Tamaulipas'],
			['Tlaxcala', 'Tlaxcala'],
			['Veracruz', 'Veracruz'],
			['Yucatán', 'Yucatán'],
			['Zacatecas', 'Zacatecas'],
		],
	},
	{
		readable: 'Moldova, Republic of',
		provinces: [],
	},
	{
		readable: 'Monaco',
		provinces: [],
	},
	{
		readable: 'Mongolia',
		provinces: [],
	},
	{
		readable: 'Montenegro',
		provinces: [],
	},
	{
		readable: 'Montserrat',
		provinces: [],
	},
	{
		readable: 'Morocco',
		provinces: [],
	},
	{
		readable: 'Mozambique',
		provinces: [],
	},
	{
		readable: 'Myanmar',
		provinces: [],
	},
	{
		readable: 'Namibia',
		provinces: [],
	},
	{
		readable: 'Nauru',
		provinces: [],
	},
	{
		readable: 'Nepal',
		provinces: [],
	},
	{
		readable: 'Netherlands',
		provinces: [],
	},
	{
		readable: 'Netherlands Antilles',
		provinces: [],
	},
	{
		readable: 'New Caledonia',
		provinces: [],
	},
	{
		readable: 'New Zealand',
		provinces: [
			['Auckland', 'Auckland'],
			['Bay of Plenty', 'Bay of Plenty'],
			['Canterbury', 'Canterbury'],
			['Gisborne', 'Gisborne'],
			["Hawke's Bay", "Hawke's Bay"],
			['Manawatu-Wanganui', 'Manawatu-Wanganui'],
			['Marlborough', 'Marlborough'],
			['Nelson', 'Nelson'],
			['Northland', 'Northland'],
			['Otago', 'Otago'],
			['Southland', 'Southland'],
			['Taranaki', 'Taranaki'],
			['Tasman', 'Tasman'],
			['Waikato', 'Waikato'],
			['Wellington', 'Wellington'],
			['West Coast', 'West Coast'],
		],
	},
	{
		readable: 'Nicaragua',
		provinces: [],
	},
	{
		readable: 'Niger',
		provinces: [],
	},
	{
		readable: 'Nigeria',
		provinces: [
			['Abia', 'Abia'],
			['Abuja Federal Capital Territory', 'Abuja Federal Capital Territory'],
			['Adamawa', 'Adamawa'],
			['Akwa Ibom', 'Akwa Ibom'],
			['Anambra', 'Anambra'],
			['Bauchi', 'Bauchi'],
			['Bayelsa', 'Bayelsa'],
			['Benue', 'Benue'],
			['Borno', 'Borno'],
			['Cross River', 'Cross River'],
			['Delta', 'Delta'],
			['Ebonyi', 'Ebonyi'],
			['Edo', 'Edo'],
			['Ekiti', 'Ekiti'],
			['Enugu', 'Enugu'],
			['Gombe', 'Gombe'],
			['Imo', 'Imo'],
			['Jigawa', 'Jigawa'],
			['Kaduna', 'Kaduna'],
			['Kano', 'Kano'],
			['Katsina', 'Katsina'],
			['Kebbi', 'Kebbi'],
			['Kogi', 'Kogi'],
			['Kwara', 'Kwara'],
			['Lagos', 'Lagos'],
			['Nasarawa', 'Nasarawa'],
			['Niger', 'Niger'],
			['Ogun', 'Ogun'],
			['Ondo', 'Ondo'],
			['Osun', 'Osun'],
			['Oyo', 'Oyo'],
			['Plateau', 'Plateau'],
			['Rivers', 'Rivers'],
			['Sokoto', 'Sokoto'],
			['Taraba', 'Taraba'],
			['Yobe', 'Yobe'],
			['Zamfara', 'Zamfara'],
		],
	},
	{
		readable: 'Niue',
		provinces: [],
	},
	{
		readable: 'Norfolk Island',
		provinces: [],
	},
	{
		readable: "Korea, Democratic People's Republic Of",
		provinces: [],
	},
	{
		readable: 'North Macedonia',
		provinces: [],
	},
	{
		readable: 'Norway',
		provinces: [],
	},
	{
		readable: 'Oman',
		provinces: [],
	},
	{
		readable: 'Pakistan',
		provinces: [],
	},
	{
		readable: 'Palestinian Territory, Occupied',
		provinces: [],
	},
	{
		readable: 'Panama',
		provinces: [
			['Bocas del Toro', 'Bocas del Toro'],
			['Chiriquí', 'Chiriquí'],
			['Coclé', 'Coclé'],
			['Colón', 'Colón'],
			['Darién', 'Darién'],
			['Emberá', 'Emberá'],
			['Herrera', 'Herrera'],
			['Kuna Yala', 'Kuna Yala'],
			['Los Santos', 'Los Santos'],
			['Ngöbe-Buglé', 'Ngöbe-Buglé'],
			['Panamá', 'Panamá'],
			['Panamá Oeste', 'Panamá Oeste'],
			['Veraguas', 'Veraguas'],
		],
	},
	{
		readable: 'Papua New Guinea',
		provinces: [],
	},
	{
		readable: 'Paraguay',
		provinces: [],
	},
	{
		readable: 'Peru',
		provinces: [
			['Amazonas', 'Amazonas'],
			['Áncash', 'Áncash'],
			['Apurímac', 'Apurímac'],
			['Arequipa', 'Arequipa'],
			['Ayacucho', 'Ayacucho'],
			['Cajamarca', 'Cajamarca'],
			['Callao', 'Callao'],
			['Cuzco', 'Cuzco'],
			['Huancavelica', 'Huancavelica'],
			['Huánuco', 'Huánuco'],
			['Ica', 'Ica'],
			['Junín', 'Junín'],
			['La Libertad', 'La Libertad'],
			['Lambayeque', 'Lambayeque'],
			['Lima (departamento)', 'Lima (departamento)'],
			['Lima (provincia)', 'Lima (provincia)'],
			['Loreto', 'Loreto'],
			['Madre de Dios', 'Madre de Dios'],
			['Moquegua', 'Moquegua'],
			['Pasco', 'Pasco'],
			['Piura', 'Piura'],
			['Puno', 'Puno'],
			['San Martín', 'San Martín'],
			['Tacna', 'Tacna'],
			['Tumbes', 'Tumbes'],
			['Ucayali', 'Ucayali'],
		],
	},
	{
		readable: 'Philippines',
		provinces: [],
	},
	{
		readable: 'Pitcairn',
		provinces: [],
	},
	{
		readable: 'Poland',
		provinces: [],
	},
	{
		readable: 'Portugal',
		provinces: [
			['Açores', 'Açores'],
			['Aveiro', 'Aveiro'],
			['Beja', 'Beja'],
			['Braga', 'Braga'],
			['Bragança', 'Bragança'],
			['Castelo Branco', 'Castelo Branco'],
			['Coimbra', 'Coimbra'],
			['Évora', 'Évora'],
			['Faro', 'Faro'],
			['Guarda', 'Guarda'],
			['Leiria', 'Leiria'],
			['Lisboa', 'Lisboa'],
			['Madeira', 'Madeira'],
			['Portalegre', 'Portalegre'],
			['Porto', 'Porto'],
			['Santarém', 'Santarém'],
			['Setúbal', 'Setúbal'],
			['Viana do Castelo', 'Viana do Castelo'],
			['Vila Real', 'Vila Real'],
			['Viseu', 'Viseu'],
		],
	},
	{
		readable: 'Qatar',
		provinces: [],
	},
	{
		readable: 'Reunion',
		provinces: [],
	},
	{
		readable: 'Romania',
		provinces: [
			['Alba', 'Alba'],
			['Arad', 'Arad'],
			['Argeș', 'Argeș'],
			['Bacău', 'Bacău'],
			['Bihor', 'Bihor'],
			['Bistrița-Năsăud', 'Bistrița-Năsăud'],
			['Botoșani', 'Botoșani'],
			['Brăila', 'Brăila'],
			['Brașov', 'Brașov'],
			['București', 'București'],
			['Buzău', 'Buzău'],
			['Caraș-Severin', 'Caraș-Severin'],
			['Cluj', 'Cluj'],
			['Constanța', 'Constanța'],
			['Covasna', 'Covasna'],
			['Călărași', 'Călărași'],
			['Dolj', 'Dolj'],
			['Dâmbovița', 'Dâmbovița'],
			['Galați', 'Galați'],
			['Giurgiu', 'Giurgiu'],
			['Gorj', 'Gorj'],
			['Harghita', 'Harghita'],
			['Hunedoara', 'Hunedoara'],
			['Ialomița', 'Ialomița'],
			['Iași', 'Iași'],
			['Ilfov', 'Ilfov'],
			['Maramureș', 'Maramureș'],
			['Mehedinți', 'Mehedinți'],
			['Mureș', 'Mureș'],
			['Neamț', 'Neamț'],
			['Olt', 'Olt'],
			['Prahova', 'Prahova'],
			['Sălaj', 'Sălaj'],
			['Satu Mare', 'Satu Mare'],
			['Sibiu', 'Sibiu'],
			['Suceava', 'Suceava'],
			['Teleorman', 'Teleorman'],
			['Timiș', 'Timiș'],
			['Tulcea', 'Tulcea'],
			['Vâlcea', 'Vâlcea'],
			['Vaslui', 'Vaslui'],
			['Vrancea', 'Vrancea'],
		],
	},
	{
		readable: 'Russia',
		provinces: [
			['Altai Krai', 'Altai Krai'],
			['Altai Republic', 'Altai Republic'],
			['Amur Oblast', 'Amur Oblast'],
			['Arkhangelsk Oblast', 'Arkhangelsk Oblast'],
			['Astrakhan Oblast', 'Astrakhan Oblast'],
			['Belgorod Oblast', 'Belgorod Oblast'],
			['Bryansk Oblast', 'Bryansk Oblast'],
			['Chechen Republic', 'Chechen Republic'],
			['Chelyabinsk Oblast', 'Chelyabinsk Oblast'],
			['Chukotka Autonomous Okrug', 'Chukotka Autonomous Okrug'],
			['Chuvash Republic', 'Chuvash Republic'],
			['Irkutsk Oblast', 'Irkutsk Oblast'],
			['Ivanovo Oblast', 'Ivanovo Oblast'],
			['Jewish Autonomous Oblast', 'Jewish Autonomous Oblast'],
			['Kabardino-Balkarian Republic', 'Kabardino-Balkarian Republic'],
			['Kaliningrad Oblast', 'Kaliningrad Oblast'],
			['Kaluga Oblast', 'Kaluga Oblast'],
			['Kamchatka Krai', 'Kamchatka Krai'],
			['Karachay–Cherkess Republic', 'Karachay–Cherkess Republic'],
			['Kemerovo Oblast', 'Kemerovo Oblast'],
			['Khabarovsk Krai', 'Khabarovsk Krai'],
			['Khanty-Mansi Autonomous Okrug', 'Khanty-Mansi Autonomous Okrug'],
			['Kirov Oblast', 'Kirov Oblast'],
			['Komi Republic', 'Komi Republic'],
			['Kostroma Oblast', 'Kostroma Oblast'],
			['Krasnodar Krai', 'Krasnodar Krai'],
			['Krasnoyarsk Krai', 'Krasnoyarsk Krai'],
			['Kurgan Oblast', 'Kurgan Oblast'],
			['Kursk Oblast', 'Kursk Oblast'],
			['Leningrad Oblast', 'Leningrad Oblast'],
			['Lipetsk Oblast', 'Lipetsk Oblast'],
			['Magadan Oblast', 'Magadan Oblast'],
			['Mari El Republic', 'Mari El Republic'],
			['Moscow', 'Moscow'],
			['Moscow Oblast', 'Moscow Oblast'],
			['Murmansk Oblast', 'Murmansk Oblast'],
			['Nizhny Novgorod Oblast', 'Nizhny Novgorod Oblast'],
			['Novgorod Oblast', 'Novgorod Oblast'],
			['Novosibirsk Oblast', 'Novosibirsk Oblast'],
			['Omsk Oblast', 'Omsk Oblast'],
			['Orenburg Oblast', 'Orenburg Oblast'],
			['Oryol Oblast', 'Oryol Oblast'],
			['Penza Oblast', 'Penza Oblast'],
			['Perm Krai', 'Perm Krai'],
			['Primorsky Krai', 'Primorsky Krai'],
			['Pskov Oblast', 'Pskov Oblast'],
			['Republic of Adygeya', 'Republic of Adygeya'],
			['Republic of Bashkortostan', 'Republic of Bashkortostan'],
			['Republic of Buryatia', 'Republic of Buryatia'],
			['Republic of Dagestan', 'Republic of Dagestan'],
			['Republic of Ingushetia', 'Republic of Ingushetia'],
			['Republic of Kalmykia', 'Republic of Kalmykia'],
			['Republic of Karelia', 'Republic of Karelia'],
			['Republic of Khakassia', 'Republic of Khakassia'],
			['Republic of Mordovia', 'Republic of Mordovia'],
			['Republic of North Ossetia–Alania', 'Republic of North Ossetia–Alania'],
			['Republic of Tatarstan', 'Republic of Tatarstan'],
			['Rostov Oblast', 'Rostov Oblast'],
			['Ryazan Oblast', 'Ryazan Oblast'],
			['Saint Petersburg', 'Saint Petersburg'],
			['Sakha Republic (Yakutia)', 'Sakha Republic (Yakutia)'],
			['Sakhalin Oblast', 'Sakhalin Oblast'],
			['Samara Oblast', 'Samara Oblast'],
			['Saratov Oblast', 'Saratov Oblast'],
			['Smolensk Oblast', 'Smolensk Oblast'],
			['Stavropol Krai', 'Stavropol Krai'],
			['Sverdlovsk Oblast', 'Sverdlovsk Oblast'],
			['Tambov Oblast', 'Tambov Oblast'],
			['Tomsk Oblast', 'Tomsk Oblast'],
			['Tula Oblast', 'Tula Oblast'],
			['Tver Oblast', 'Tver Oblast'],
			['Tyumen Oblast', 'Tyumen Oblast'],
			['Tyva Republic', 'Tyva Republic'],
			['Udmurtia', 'Udmurtia'],
			['Ulyanovsk Oblast', 'Ulyanovsk Oblast'],
			['Vladimir Oblast', 'Vladimir Oblast'],
			['Volgograd Oblast', 'Volgograd Oblast'],
			['Vologda Oblast', 'Vologda Oblast'],
			['Voronezh Oblast', 'Voronezh Oblast'],
			['Yamalo-Nenets Autonomous Okrug', 'Yamalo-Nenets Autonomous Okrug'],
			['Yaroslavl Oblast', 'Yaroslavl Oblast'],
			['Zabaykalsky Krai', 'Zabaykalsky Krai'],
		],
	},
	{
		readable: 'Rwanda',
		provinces: [],
	},
	{
		readable: 'Samoa',
		provinces: [],
	},
	{
		readable: 'San Marino',
		provinces: [],
	},
	{
		readable: 'Sao Tome And Principe',
		provinces: [],
	},
	{
		readable: 'Saudi Arabia',
		provinces: [],
	},
	{
		readable: 'Senegal',
		provinces: [],
	},
	{
		readable: 'Serbia',
		provinces: [],
	},
	{
		readable: 'Seychelles',
		provinces: [],
	},
	{
		readable: 'Sierra Leone',
		provinces: [],
	},
	{
		readable: 'Singapore',
		provinces: [],
	},
	{
		readable: 'Sint Maarten',
		provinces: [],
	},
	{
		readable: 'Slovakia',
		provinces: [],
	},
	{
		readable: 'Slovenia',
		provinces: [],
	},
	{
		readable: 'Solomon Islands',
		provinces: [],
	},
	{
		readable: 'Somalia',
		provinces: [],
	},
	{
		readable: 'South Africa',
		provinces: [
			['Eastern Cape', 'Eastern Cape'],
			['Free State', 'Free State'],
			['Gauteng', 'Gauteng'],
			['KwaZulu-Natal', 'KwaZulu-Natal'],
			['Limpopo', 'Limpopo'],
			['Mpumalanga', 'Mpumalanga'],
			['North West', 'North West'],
			['Northern Cape', 'Northern Cape'],
			['Western Cape', 'Western Cape'],
		],
	},
	{
		readable: 'South Georgia And The South Sandwich Islands',
		provinces: [],
	},
	{
		readable: 'South Korea',
		provinces: [
			['Busan', 'Busan'],
			['Chungbuk', 'Chungbuk'],
			['Chungnam', 'Chungnam'],
			['Daegu', 'Daegu'],
			['Daejeon', 'Daejeon'],
			['Gangwon', 'Gangwon'],
			['Gwangju', 'Gwangju'],
			['Gyeongbuk', 'Gyeongbuk'],
			['Gyeonggi', 'Gyeonggi'],
			['Gyeongnam', 'Gyeongnam'],
			['Incheon', 'Incheon'],
			['Jeju', 'Jeju'],
			['Jeonbuk', 'Jeonbuk'],
			['Jeonnam', 'Jeonnam'],
			['Sejong', 'Sejong'],
			['Seoul', 'Seoul'],
			['Ulsan', 'Ulsan'],
		],
	},
	{
		readable: 'South Sudan',
		provinces: [],
	},
	{
		readable: 'Spain',
		provinces: [
			['A Coruña', 'A Coruña'],
			['Álava', 'Álava'],
			['Albacete', 'Albacete'],
			['Alicante', 'Alicante'],
			['Almería', 'Almería'],
			['Asturias', 'Asturias'],
			['Ávila', 'Ávila'],
			['Badajoz', 'Badajoz'],
			['Balears', 'Balears'],
			['Barcelona', 'Barcelona'],
			['Burgos', 'Burgos'],
			['Cáceres', 'Cáceres'],
			['Cádiz', 'Cádiz'],
			['Cantabria', 'Cantabria'],
			['Castellón', 'Castellón'],
			['Ceuta', 'Ceuta'],
			['Ciudad Real', 'Ciudad Real'],
			['Córdoba', 'Córdoba'],
			['Cuenca', 'Cuenca'],
			['Girona', 'Girona'],
			['Granada', 'Granada'],
			['Guadalajara', 'Guadalajara'],
			['Guipúzcoa', 'Guipúzcoa'],
			['Huelva', 'Huelva'],
			['Huesca', 'Huesca'],
			['Jaén', 'Jaén'],
			['La Rioja', 'La Rioja'],
			['Las Palmas', 'Las Palmas'],
			['León', 'León'],
			['Lleida', 'Lleida'],
			['Lugo', 'Lugo'],
			['Madrid', 'Madrid'],
			['Málaga', 'Málaga'],
			['Melilla', 'Melilla'],
			['Murcia', 'Murcia'],
			['Navarra', 'Navarra'],
			['Ourense', 'Ourense'],
			['Palencia', 'Palencia'],
			['Pontevedra', 'Pontevedra'],
			['Salamanca', 'Salamanca'],
			['Santa Cruz de Tenerife', 'Santa Cruz de Tenerife'],
			['Segovia', 'Segovia'],
			['Sevilla', 'Sevilla'],
			['Soria', 'Soria'],
			['Tarragona', 'Tarragona'],
			['Teruel', 'Teruel'],
			['Toledo', 'Toledo'],
			['Valencia', 'Valencia'],
			['Valladolid', 'Valladolid'],
			['Vizcaya', 'Vizcaya'],
			['Zamora', 'Zamora'],
			['Zaragoza', 'Zaragoza'],
		],
	},
	{
		readable: 'Sri Lanka',
		provinces: [],
	},
	{
		readable: 'Saint Barthélemy',
		provinces: [],
	},
	{
		readable: 'Saint Helena',
		provinces: [],
	},
	{
		readable: 'Saint Kitts And Nevis',
		provinces: [],
	},
	{
		readable: 'Saint Lucia',
		provinces: [],
	},
	{
		readable: 'Saint Martin',
		provinces: [],
	},
	{
		readable: 'Saint Pierre And Miquelon',
		provinces: [],
	},
	{
		readable: 'St. Vincent',
		provinces: [],
	},
	{
		readable: 'Sudan',
		provinces: [],
	},
	{
		readable: 'Suriname',
		provinces: [],
	},
	{
		readable: 'Svalbard And Jan Mayen',
		provinces: [],
	},
	{
		readable: 'Sweden',
		provinces: [],
	},
	{
		readable: 'Switzerland',
		provinces: [],
	},
	{
		readable: 'Syria',
		provinces: [],
	},
	{
		readable: 'Taiwan',
		provinces: [],
	},
	{
		readable: 'Tajikistan',
		provinces: [],
	},
	{
		readable: 'Tanzania, United Republic Of',
		provinces: [],
	},
	{
		readable: 'Thailand',
		provinces: [
			['Amnat Charoen', 'Amnat Charoen'],
			['Ang Thong', 'Ang Thong'],
			['Bangkok', 'Bangkok'],
			['Bueng Kan', 'Bueng Kan'],
			['Buriram', 'Buriram'],
			['Chachoengsao', 'Chachoengsao'],
			['Chai Nat', 'Chai Nat'],
			['Chaiyaphum', 'Chaiyaphum'],
			['Chanthaburi', 'Chanthaburi'],
			['Chiang Mai', 'Chiang Mai'],
			['Chiang Rai', 'Chiang Rai'],
			['Chon Buri', 'Chon Buri'],
			['Chumphon', 'Chumphon'],
			['Kalasin', 'Kalasin'],
			['Kamphaeng Phet', 'Kamphaeng Phet'],
			['Kanchanaburi', 'Kanchanaburi'],
			['Khon Kaen', 'Khon Kaen'],
			['Krabi', 'Krabi'],
			['Lampang', 'Lampang'],
			['Lamphun', 'Lamphun'],
			['Loei', 'Loei'],
			['Lopburi', 'Lopburi'],
			['Mae Hong Son', 'Mae Hong Son'],
			['Maha Sarakham', 'Maha Sarakham'],
			['Mukdahan', 'Mukdahan'],
			['Nakhon Nayok', 'Nakhon Nayok'],
			['Nakhon Pathom', 'Nakhon Pathom'],
			['Nakhon Phanom', 'Nakhon Phanom'],
			['Nakhon Ratchasima', 'Nakhon Ratchasima'],
			['Nakhon Sawan', 'Nakhon Sawan'],
			['Nakhon Si Thammarat', 'Nakhon Si Thammarat'],
			['Nan', 'Nan'],
			['Narathiwat', 'Narathiwat'],
			['Nong Bua Lam Phu', 'Nong Bua Lam Phu'],
			['Nong Khai', 'Nong Khai'],
			['Nonthaburi', 'Nonthaburi'],
			['Pathum Thani', 'Pathum Thani'],
			['Pattani', 'Pattani'],
			['Pattaya', 'Pattaya'],
			['Phangnga', 'Phangnga'],
			['Phatthalung', 'Phatthalung'],
			['Phayao', 'Phayao'],
			['Phetchabun', 'Phetchabun'],
			['Phetchaburi', 'Phetchaburi'],
			['Phichit', 'Phichit'],
			['Phitsanulok', 'Phitsanulok'],
			['Phra Nakhon Si Ayutthaya', 'Phra Nakhon Si Ayutthaya'],
			['Phrae', 'Phrae'],
			['Phuket', 'Phuket'],
			['Prachin Buri', 'Prachin Buri'],
			['Prachuap Khiri Khan', 'Prachuap Khiri Khan'],
			['Ranong', 'Ranong'],
			['Ratchaburi', 'Ratchaburi'],
			['Rayong', 'Rayong'],
			['Roi Et', 'Roi Et'],
			['Sa Kaeo', 'Sa Kaeo'],
			['Sakon Nakhon', 'Sakon Nakhon'],
			['Samut Prakan', 'Samut Prakan'],
			['Samut Sakhon', 'Samut Sakhon'],
			['Samut Songkhram', 'Samut Songkhram'],
			['Saraburi', 'Saraburi'],
			['Satun', 'Satun'],
			['Sing Buri', 'Sing Buri'],
			['Sisaket', 'Sisaket'],
			['Songkhla', 'Songkhla'],
			['Sukhothai', 'Sukhothai'],
			['Suphan Buri', 'Suphan Buri'],
			['Surat Thani', 'Surat Thani'],
			['Surin', 'Surin'],
			['Tak', 'Tak'],
			['Trang', 'Trang'],
			['Trat', 'Trat'],
			['Ubon Ratchathani', 'Ubon Ratchathani'],
			['Udon Thani', 'Udon Thani'],
			['Uthai Thani', 'Uthai Thani'],
			['Uttaradit', 'Uttaradit'],
			['Yala', 'Yala'],
			['Yasothon', 'Yasothon'],
		],
	},
	{
		readable: 'Timor Leste',
		provinces: [],
	},
	{
		readable: 'Togo',
		provinces: [],
	},
	{
		readable: 'Tokelau',
		provinces: [],
	},
	{
		readable: 'Tonga',
		provinces: [],
	},
	{
		readable: 'Trinidad and Tobago',
		provinces: [],
	},
	{
		readable: 'Tunisia',
		provinces: [],
	},
	{
		readable: 'Turkey',
		provinces: [],
	},
	{
		readable: 'Turkmenistan',
		provinces: [],
	},
	{
		readable: 'Turks and Caicos Islands',
		provinces: [],
	},
	{
		readable: 'Tuvalu',
		provinces: [],
	},
	{
		readable: 'United States Minor Outlying Islands',
		provinces: [],
	},
	{
		readable: 'Uganda',
		provinces: [],
	},
	{
		readable: 'Ukraine',
		provinces: [],
	},
	{
		readable: 'United Arab Emirates',
		provinces: [
			['Abu Dhabi', 'Abu Dhabi'],
			['Ajman', 'Ajman'],
			['Dubai', 'Dubai'],
			['Fujairah', 'Fujairah'],
			['Ras al-Khaimah', 'Ras al-Khaimah'],
			['Sharjah', 'Sharjah'],
			['Umm al-Quwain', 'Umm al-Quwain'],
		],
	},
	{
		readable: 'United Kingdom',
		provinces: [],
	},
	{
		readable: 'Uruguay',
		provinces: [],
	},
	{
		readable: 'Uzbekistan',
		provinces: [],
	},
	{
		readable: 'Vanuatu',
		provinces: [],
	},
	{
		readable: 'Holy See (Vatican City State)',
		provinces: [],
	},
	{
		readable: 'Venezuela',
		provinces: [],
	},
	{
		readable: 'Vietnam',
		provinces: [],
	},
	{
		readable: 'Wallis And Futuna',
		provinces: [],
	},
	{
		readable: 'Western Sahara',
		provinces: [],
	},
	{
		readable: 'Yemen',
		provinces: [],
	},
	{
		readable: 'Zambia',
		provinces: [],
	},
	{
		readable: 'Zimbabwe',
		provinces: [],
	},
]
