import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { LocalStorageService } from './services/localstorage.service'
import { PrismicService } from './services/prismic.service'
import { SeoService } from './services/seo.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	static isBrowser = new BehaviorSubject<boolean>(false)

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private prismicService: PrismicService,
		private route: ActivatedRoute,
		private localStorage: LocalStorageService,
		private router: Router,
		private seoService: SeoService,
	) {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(async (event: any) => {
			// await this.seoService.setCanonical()
		})
		AppComponent.isBrowser.next(isPlatformBrowser(this.platformId))
		this.route.queryParams.subscribe(data => {
			if (data._locale?.length == 2) {
				this.localStorage.setItem('SELECTED_STORE', (data._locale as string).toLocaleUpperCase())
			}

			if (data.geoRedirect) {
				this.localStorage.setItem('SELECTED_STORE', data.geoRedirect)
			}
		})
	}

	async ngOnInit() {
		await this.prismicService.getThemeSettings()
	}
}
