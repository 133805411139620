import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Variant } from 'src/app/classes/product'
import { EnvironmentService } from 'src/app/services/environment.service'
import { ShopifyService } from 'src/app/services/shopify.service'
import { UtilityService } from 'src/app/services/utility.service'

declare var pintrk: any;

@Component({
	selector: 'app-add-to-cart',
	templateUrl: './add-to-cart.component.html',
	styleUrls: ['./add-to-cart.component.scss'],
})
export class AddToCartComponent implements OnChanges {
	@Input() product!: any
	@Input() showWishlistToggle: boolean = true
	@Input() addToCartMessage: string = 'Add to bag'
	@Input() addingToCartMessage: string = 'Adding to bag'
	@Input() addedToCartMessage: string = 'Added to bag'
	@Input() selectedVariant!: Variant | any
	@Input() quantity: number = 1
	@Input() preorder: string = ''
	@Input() backorder: string = ''
	// @Input() buttonClasses: string = 'btn-black'
	country: any
	_message: string = this.addToCartMessage
	_comment: string = ''
	email: string = ''
	_outOfStockLoading: boolean = false
	_outOfStockResult: string = ''
	_outOfStockError: boolean = false
	constructor(private http: HttpClient, private utilityService: UtilityService, private shopifyService: ShopifyService, private environmentService: EnvironmentService) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.selectedVariant = this.selectedVariant ?? this.product.variants[0]

		this._message = (this.backorder && 'Backorder') || (this.preorder && 'Preorder') || this.addToCartMessage
	}

	selectVariant(variant: Variant | any) {
		this.selectedVariant = variant
	}

	clickedInside($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
	}
	async outOfStock() {
		this._outOfStockLoading = true
		var formData: any = new URLSearchParams()

		formData.set('a', 'TXgzkm')
		formData.set('email', this.email)
		formData.set('variant', this.selectedVariant.id.toString())
		formData.set('platform', 'shopify')
		formData.set('product', (this.product as unknown as any).id)

		this.http
			.post('https://a.klaviyo.com/onsite/components/back-in-stock/subscribe', formData.toString(), {
				headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
			})
			.subscribe(
				data => {
					this._outOfStockLoading = false
					this._outOfStockResult = `We'll let you know`
				},
				err => {
					this._outOfStockLoading = false
					this._outOfStockError = true
					this._outOfStockResult = `There was an issue`
				}
			)
	}

	async addToCart(event: any) {
		event.preventDefault()
		event.stopPropagation()
		event.target.blur()
		this._message = this.addingToCartMessage

		let properties = [
			{ key: '_colour', value: this.product?.metafields?.finish?.colorName ? this.product.metafields.finish.colorName : 'One Colour' },
			{ key: '_size', value: this.selectedVariant.title == 'Default' ? 'One Size' : this.selectedVariant.title },
		]
		if (this.selectedVariant.backorder) {
			properties.push({ key: '_messaging', value: this.selectedVariant.backorder })
		}
		if (this.selectedVariant.preorder) {
			properties.push({ key: '_messaging', value: this.selectedVariant.preorder })
		}

		// TODO: when we merge AUD/NZD changes, we will have to update currency source
		if (typeof pintrk === 'function') {
			pintrk('track', 'addtocart', {
				product_id: this.selectedVariant.id,
				product_price: this.selectedVariant.price,
				currency: this.environmentService.environment.currency || 'AUD',
			});
		}

		this.shopifyService
			.addToCart([{ variantId: this.selectedVariant?.admin_graphql_api_id as any, quantity: this.quantity, customAttributes: properties }])
			.then(data => {
				this.utilityService.cartStateSubject.next(true)
				this._message = this.addedToCartMessage
			})
			.catch(err => {})
			.finally(() => {
				this.shopifyService.updateCheckout()
				setTimeout(() => {
					this._message = this.addToCartMessage
				}, 2000)
			})
	}
}
