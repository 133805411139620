import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value as string;

    if (!email) {
      // If the field is empty, consider it valid
      return null;
    }

    // Use a regular expression to check for at least one period after the '@' symbol
    const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    const isValid = pattern.test(email);

    return isValid ? null : { 'invalidEmail': true };
  };
}
