import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { GraphQLClient, gql } from 'graphql-request'
import { from } from 'rxjs'
import { ReplaySubject } from 'rxjs/internal/ReplaySubject'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { Product as CoreProduct, ProductImage as CoreProductImage, Variant as CoreVariant } from '../classes/product'
import { EnvironmentService } from './environment.service'
import { SessionStorageService } from './sessionstorage.service'
import { UtilityService } from './utility.service'
import { cartBuyerIdentityUpdate, cartCreate, cartLinesAdd, cartLinesRemove, checkoutLineItemsUpdate, customerAccessTokenDelete, getCart } from '../graphql/cart'
import { CartBuyerIdentityUpdateMutation, CartBuyerIdentityUpdateMutationVariables, CartCreateMutation, CartCreateMutationVariables, CartLinesAddMutation, CartLinesAddMutationVariables, CartLinesRemoveMutation, CartLinesRemoveMutationVariables, CartLinesUpdateMutation, CartLinesUpdateMutationVariables, CustomerAccessTokenDeleteMutation, GetCheckoutQuery } from '../graphql/types/storefront.generated'
import { storeMap } from 'src/environments/environment'
import { LocalStorageService } from './localstorage.service'
import { CountryCode, InputMaybe } from '../graphql/types/storefront.types'

@Injectable({
	providedIn: 'root',
})
export class ShopifyService {
	checkoutSubject = new ReplaySubject<any>(1)
	checkoutObservable = this.checkoutSubject.asObservable()
	checkoutLink: string = ''
	client: any
	_store: any
	constructor(private utilityService: UtilityService, private router: Router, private sessionStorage: SessionStorageService, private environmentService: EnvironmentService, private localStorage: LocalStorageService) {
		this.environmentService.observableEnvironment.subscribe((data: any) => {
            this._store = data.currency;
            this.client = new GraphQLClient(
                data.apiUrl + 'api/2024-07/graphql.json',
                {
                    headers: {
                        'X-Shopify-Storefront-Access-Token': data.storefrontKey,
                    },
                },
            );

			if (this.utilityService.isBrowser()) {
				this.createCheckout()
			}
		});
	}

	getCheckout() {
		return this.checkoutObservable.pipe(
			tap(x => {
				if (x?.checkoutUrl) {
					this.checkoutLink = x.checkoutUrl;
				}
			}),
			map((x) => {
				return {
					...x,
					lineItems: x?.lines?.edges?.map((x: any) => {
						const computed = {
							...x.node,
							...x.node.variant,
						}

						computed.lineId = x.node.id

						// TODO: for now, just keep lineItems properties as-is to fit the old Checkout API
						// structure (e.g. variant instead of merchandise), but longer-term look for usages
						// in app and change to update key values
						computed.variant = x.node.merchandise
						computed.variantId = x.node.merchandise.id
						computed.preorder = computed.variant.product.metafields[0] ?? null;
						computed.backorder = computed.variant.product.metafields[1] ?? null;

						computed.on_sale = x.node.merchandise.compareAtPrice !== null ?
							parseFloat(x.node.merchandise.compareAtPrice) > parseFloat(x.node.merchandise.price.amount) :
							false;

						const properties: { [key: string]: any } = {};

						for (let attr of x.node.attributes) {
							properties[attr.key] = attr.value;
						}
						computed.properties = properties;

						return computed;
					}),
				}
			})
		)
	}

	mapGraphQlProductToThemeProduct(graphqlProduct: any): CoreProduct {
		return {
			id: graphqlProduct.id,
			tags: graphqlProduct.tags.join(', '),
			image: graphqlProduct.images.edges[0]?.node ?? null,
			title: graphqlProduct.title,
			handle: graphqlProduct.handle,
			images: graphqlProduct.images.edges.map((x: { node: CoreProductImage }) => x.node),
			status: graphqlProduct.status,
			vendor: graphqlProduct.vendor,
			on_sale: false,
			options: [],
			media: [],
			variants: graphqlProduct.variants.edges.map((x: { node: Variant }) => {
				let variant = x.node
				return {
					title: variant.title,
					id: variant.legacyResourceId,
					grams: variant.weightUnit == 'kg' ? variant.weight : variant.weight * 1000,
					price: parseFloat(variant?.priceV2?.amount) as any,
					compare_at_price: parseFloat(variant?.compareAtPriceV2?.amount) as any,
					option1: variant.selectedOptions[0]?.value ?? undefined,
					option2: variant.selectedOptions[1]?.value ?? undefined,
					option3: variant.selectedOptions[2]?.value ?? undefined,
					admin_graphql_api_id: variant.id,
				} as Partial<CoreVariant>
			}),
			body_html: graphqlProduct.body_html,
			max_price: graphqlProduct.priceRange.maxVariantPrice.amount,
			min_price: graphqlProduct.priceRange.minVariantPrice.amount,
			created_at: new Date(),
			metafields: null,
			updated_at: new Date(),
			total_stock: graphqlProduct.totalInventory,
			product_type: graphqlProduct.productType,
			published_at: new Date(),
			published_scope: 'web',
			template_suffix: '',
			admin_graphql_api_id: graphqlProduct.id,
			compare_at_max_price: '',
			compare_at_min_price: '',
		}
	}

	getVariantsWithMetafields(productHandle: string) {
		// https://shopify.dev/docs/api/storefront/2024-07/queries/product
		const query = gql`
			query GetVariantsWithMetafields($handle: String!) {
				product(handle: $handle) {
					handle
					id
					variants(first: 10) {
						edges {
							node {
								title
								preorder: metafield(namespace: "product", key: "preorder") {
									value
								}
								backorder: metafield(namespace: "product", key: "backorder") {
									value
								}
							}
						}
					}
				}
			}
		`

		return from(this.client.request(query, { handle: productHandle })).pipe(map((value: any) => value.product.variants.edges.map((y: any) => y.node)))
	}

	graphqlSearch(string: string) {
		// https://shopify.dev/docs/api/storefront/2024-07/queries/products
		let query = gql`
			${productFragment}
			query searchProducts($query: String!) {
				products(first: 8, query: $query) {
					edges {
						node {
							... on Product {
								...StandardProduct
							}
						}
					}
				}
			}
		`
		return from(this.client.request(query, { query: `(title:${string}*) OR (tag:${string}*)` })).pipe(
			map((value: any) => value.products.edges.map((edge: { node: any }) => this.mapGraphQlProductToThemeProduct(edge.node)))
		)
	}

	// Specifically get the updated checkout results
	async updateCheckout(checkoutId: string = this.sessionStorage.getItem(this._store + '-cartId') as string) {
		this.checkoutSubject.next({ state: 'loading' })

		// TODO: make this country code dynamic
		// also keep in mind that the @inContext directive only returns NZD
		// if the buyerIdentity also matches, otherwise we just get AUD pricing!
		let query = getCart('NZ');

		return await this.client
			.request(query, { cartId: checkoutId })
			.then(async (x: GetCheckoutQuery) => {
				this.checkoutSubject.next(x.cart)
				this.getCheckout()
				return x.cart
			})
	}

	async createCheckout() {
		const cartId = this.sessionStorage.getItem(this._store + '-cartId') as string;

		// Migration from old Checkout API to new Storefront Cart API (post-deploy, on refresh)
		if (cartId && cartId.includes('Checkout')) {
			console.log("OLD CART - migration!!!");
			this.sessionStorage.removeItem(this._store + '-cartId');
		}

		if (!this.sessionStorage.getItem(this._store + '-cartId')) {
			let query = cartCreate;
			let cartInputVariables: CartCreateMutationVariables = { cartInput: {} };

			const getCountryCode = await this.findCountryCode();

			if (this.sessionStorage.getItem('userToken')) {
				const customerToken = JSON.parse(this.sessionStorage.getItem('userToken') as string).accessToken;

				cartInputVariables = {
					cartInput: {
						buyerIdentity: {
							customerAccessToken: customerToken,
						},
					},
				};
			}

			if (getCountryCode) {
				cartInputVariables.cartInput.buyerIdentity = {};
				cartInputVariables.cartInput.buyerIdentity.countryCode = getCountryCode.country as InputMaybe<CountryCode>;
			}

			return await this.client
				.request(query, {
					cartInput: cartInputVariables.cartInput,
				})

				.then((data: CartCreateMutation) => {
					const checkout = data.cartCreate?.cart;

					if (!checkout) {
						throw new Error('Could not create checkout')
					}

					this.sessionStorage.setItem(this._store + '-cartId', checkout.id.toString())
					this.checkoutSubject.next(checkout)
					return this.getCheckout()
				})
				.catch((err: any) => {
					console.error(err.response)
				})
		} else {
			const cart = await this.updateCheckout();

			const currentCountryCode = await this.findCountryCode();
			const cartCountryCode = cart.buyerIdentity?.countryCode;

			if (cartCountryCode !== currentCountryCode?.country) {
				this.findAndSetCartCountryCode().then((res) => {
					const responseCart = res.cartBuyerIdentityUpdate?.cart;
					this.checkoutSubject.next({ state: 'loading' })

					this.checkoutSubject.next(responseCart)
					this.getCheckout()

					return responseCart;
				});
			}

			return cart;
		}
	}

	async setCartCustomerAccessToken(customerAccessToken: string | null) {
		let query = cartBuyerIdentityUpdate('NZ');
		let cartId = this.sessionStorage.getItem(this._store + '-cartId') as string;

		if (!cartId) {
			console.warn('No cartId found');
			return;
		}

		return await this.client
			.request(query, {
				cartId: cartId,
				buyerIdentity: {
					customerAccessToken: customerAccessToken,
				},
			} as CartBuyerIdentityUpdateMutationVariables)
			.then((data: CartBuyerIdentityUpdateMutation) => {
				console.log('setCartBuyerIdentity', data);
			})
			.catch((err: any) => {
				console.log('setCartBuyerIdentity error', err);
			});
	}

	async deleteCartCustomerAccessToken(customerAccessToken: string) {
		let query = customerAccessTokenDelete;

		if (!customerAccessToken) {
			console.warn('No customerAccessToken supplied');
			return;
		}

		return await this.client
			.request(query, {
				customerAccessToken,
			} as CustomerAccessTokenDeleteInput)
			.then((data: CustomerAccessTokenDeleteMutation) => {
				console.log('deleteCartCustomerAccessToken', data);
			})
			.catch((err: any) => {
				console.log('deleteCartCustomerAccessToken error', err);
			});
	}

	async setCartCountryCode(countryCode: string) {
		let query = cartBuyerIdentityUpdate('NZ');
		let cartId = this.sessionStorage.getItem(this._store + '-cartId') as string;

		if (!cartId) {
			console.warn('No cartId found');
			return;
		}

		return await this.client
			.request(query, {
				cartId: cartId,
				buyerIdentity: {
					countryCode: countryCode,
				},
			} as CartBuyerIdentityUpdateMutationVariables)
			.then((data: CartBuyerIdentityUpdateMutation) => {
				console.log('setCartBuyerIdentity', data);
				return data;
			})
			.catch((err: any) => {
				console.log('setCartBuyerIdentity error', err);
			});
	}

	async findCountryCode() {
		const currencyCode = await this.utilityService.getCurrency();
		const storeMeta = storeMap;

		return storeMeta.find((storeMetaItem) => storeMetaItem.currency === currencyCode);
	}

	async findAndSetCartCountryCode() {
		const currencyCodeToCountryCode = await this.findCountryCode()
		let response;

		if (!currencyCodeToCountryCode) {
			const data = await this.environmentService.observableEnvironment.toPromise();
			response = await this.setCartCountryCode(data.country);
		} else {
			response = await this.setCartCountryCode(currencyCodeToCountryCode.country);
		}

		return response;
	}

	async addToCart(variants: { variantId: string; quantity: number; customAttributes?: { key: string; value: string } | any }[]) {
		this.checkoutSubject.next({ state: 'loading' })

		let query = cartLinesAdd();

		const lineItemsToAdd = variants.map(x => {
			return {
				merchandiseId: btoa(x.variantId),
				quantity: x.quantity ? x.quantity : 1,
				attributes: x.customAttributes,
			}
		})

		await this.client
			.request(query, {
				cartId: this.sessionStorage.getItem(this._store + '-cartId') as string,
				lines: lineItemsToAdd,
			} as CartLinesAddMutationVariables)
			.then((data: CartLinesAddMutation) => {
				const checkout = data.cartLinesAdd;

				if (!checkout) {
					throw new Error('Could not add to cart')
				}

				if (checkout.userErrors.length > 0) {
					throw new Error(checkout.userErrors.toString())
				}

				return data
			})
			.catch((err: any) => {
				console.error(err)
			})
			.then((data: CartLinesAddMutation) => {
				return this.getCheckout()
			})
	}

	async updateQuantityOfLineItems(lineItems: any) {
		let query = checkoutLineItemsUpdate('NZ');

		this.checkoutSubject.next({ state: 'loading' })
		return await this.client
			.request(query, {
				cartId: this.sessionStorage.getItem(this._store + '-cartId') as string,
				lines: lineItems.map((x: any) => {
					return {
						id: x.lineId,
						attributes: x.attributes,
						quantity: x.quantity,
						merchandiseId: x.merchandise.id,
					}
				}),
			} as CartLinesUpdateMutationVariables)
			.catch((err: any) => {
				console.log('error')
			})
			.then((data: CartLinesUpdateMutation) => {
				const checkout = data.cartLinesUpdate?.cart;

				if (!checkout) {
					throw new Error('Could not update cart')
				}

				this.checkoutSubject.next(checkout)
				this.getCheckout()
			})
	}

	async removeFromCart(variantIds: string[]) {
		let query = cartLinesRemove('NZ');

		this.checkoutSubject.next({ state: 'loading' })
		return await this.client
			.request(query, {
				cartId: this.sessionStorage.getItem(this._store + '-cartId') as string,
				lineIds: variantIds,
			} as CartLinesRemoveMutationVariables)
			.catch((err: any) => {
				console.log('error', err)
			})
			.then((data: CartLinesRemoveMutation) => {
				const checkout = data.cartLinesRemove?.cart;

				if (!checkout) {
					throw new Error('Could not remove from cart')
				}

				this.checkoutSubject.next(checkout)
				this.getCheckout()
			})
	}

	getCheckoutLink() {
		return this.checkoutLink
	}

	async goToCheckout() {
		return await this.checkoutObservable.toPromise().then(data => data.webUrl)
	}

	getRecommendationsByProductId(productId: string) {
		return from(this.utilityService.getCurrency()).pipe(
			switchMap((currencyCode) => {
				const storeMeta = storeMap;
				let countryCode = '';

				const currencyCodeToCountryCode = storeMeta.find((storeMetaItem) => storeMetaItem.currency === currencyCode);

				if (currencyCodeToCountryCode) countryCode = currencyCodeToCountryCode.country;

				return from(this.client.request(getRecommendationsByProductId(countryCode), { productId })).pipe(
					map((value: any) => value.productRecommendations.map((product: any) => this.mapGraphQlProductToThemeProduct(product)))
				)
			})
		);
	}

	customerCreate(createUser: CustomerCreateInput) {
		return from(
			this.client
				.request(customerCreate, {
					input: {
						...createUser,
					},
				})
				.catch((err: any) => {
					throw new Error(err)
				})
		)
	}

	customerDetails() {
		let customerAccessToken = JSON.parse(this.sessionStorage.getItem('userToken') as string).accessToken
		return from(this.client.request(customerDetails, { customerAccessToken }))
	}

	customerReset(id: string, resetUser: CustomerResetInput) {
		return from(
			this.client.request(customerReset, {
				id,
				input: {
					...resetUser,
				},
			})
		)
	}

	customerResetByUrl(resetUrl: string, password: string) {
		return from(
			this.client.request(customerResetByUrl, {
				resetUrl,
				password,
			})
		)
	}

	customerRecover(recoverUser: CustomerRecoverInput) {
		return from(
			this.client.request(customerRecover, {
				...recoverUser,
			})
		)
	}

	customerActivate(activateUser: CustomerActivateInput) {
		return from(
			this.client.request(customerRecover, {
				...activateUser,
			})
		)
	}

	customerDefaultAddressUpdate(addressId: string) {
		if (this.sessionStorage.getItem('userToken') as string) {
			return from(
				this.client.request(customerDefaultAddressUpdate, {
					customerAccessToken: JSON.parse(this.sessionStorage.getItem('userToken') as string).accessToken,
					addressId,
				})
			)
		} else {
			throw new Error('User Not Logged In')
		}
	}

	customerAccessTokenCreate(customerAccessTokenCreateInput: CustomerAccessTokenCreateInput) {
		return from(
			this.client
				.request(customerAccessTokenCreate, {
					input: {
						...customerAccessTokenCreateInput,
					},
				})
				.then(async (response: any) => {
					if (response.customerAccessTokenCreate.userErrors.length > 0) {
						throw new Error(response.customerAccessTokenCreate.userErrors[0].message)
					} else {
						this.sessionStorage.setItem('userToken', JSON.stringify(response.customerAccessTokenCreate.customerAccessToken))
						await this.setCartCustomerAccessToken(response.customerAccessTokenCreate.customerAccessToken.accessToken)

						this.router.navigate(['account'])
					}
				})
		)
	}

	customerActivateByUrl(customerActivateByUrlInput: CustomerActivateByUrlInput) {
		return from(
			this.client.request(customerActivateByUrl, {
				...customerActivateByUrlInput,
			})
		)
	}

	customerAddressUpdate(id: string, address: MailingAddressInput) {
		if (this.sessionStorage.getItem('userToken') as string) {
			return from(
				this.client.request(customerAddressUpdate, {
					customerAccessToken: JSON.parse(this.sessionStorage.getItem('userToken') as string).accessToken,
					id,
					address,
				})
			)
		} else {
			throw new Error('User Not Logged In')
		}
	}

	customerAddressDelete(id: string) {
		if (this.sessionStorage.getItem('userToken') as string) {
			return from(
				this.client.request(customerAddressDelete, {
					customerAccessToken: JSON.parse(this.sessionStorage.getItem('userToken') as string).accessToken,
					id,
				})
			)
		} else {
			throw new Error('User Not Logged In')
		}
	}

	customerAddressCreate(address: MailingAddressInput) {
		const userToken = this.sessionStorage.getItem('userToken') as string;
		console.log('customerAddressCreate, address: ', address);
		if (userToken) {
		  const accessToken = JSON.parse(userToken).accessToken;
		  return from(this.client.request(customerAddressCreate, { address, customerAccessToken: accessToken }));
		} else {
		  throw new Error('User Not Logged In');
		}
	}

	// common objects
	getShopDetails(){
		const userToken = this.sessionStorage.getItem('userToken') as string;
		console.log('getShopDetails, userToken: ', userToken);
		return from(this.client.request(getShopDetails))
	}
}

const getShopDetails = gql`
	query {
		shop {
			shipsToCountries
		}
	  }
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerCreate
const createCustomer = gql`
	mutation customerCreate($input: CustomerCreateInput!) {
		customerCreate(input: $input) {
			userErrors {
				field
				message
			}
			customer {
				id
			}
		}
	}
`

const productFragment = gql`
	fragment StandardProduct on Product {
		id
		vendor
		handle
		productType
		priceRange {
			minVariantPrice {
				amount
			}
			maxVariantPrice {
				amount
			}
		}
		tags
		totalInventory
		images(first: 2) {
			edges {
				node {
					src
				}
			}
		}
		featuredImage {
			url
		}
		title
		related: metafield(namespace: "PRP", key: "related") {
			value
		}
		preorder: metafield(namespace: "product", key: "preorder") {
			value
		}
		variants(first: 10) {
			edges {
				node {
					title
					id
					compareAtPriceV2 {
						amount
						currencyCode
					}
					weight
					weightUnit
					priceV2 {
						amount
						currencyCode
					}
					selectedOptions {
						name
						value
					}
				}
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/queries/productRecommendations
const getRecommendationsByProductId = (countryCode?: string) => {
	return gql`
		${productFragment}
		query productRecommendations($productId: ID!)${countryCode ? ` @inContext(country: ${countryCode})` : ''} {
			productRecommendations(productId: $productId) {
				... on Product {
					...StandardProduct
				}
			}
		}
	`
}

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerDefaultAddressUpdate
const customerDefaultAddressUpdate = gql`
	mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
		customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
			customer {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

const orderById = gql`
	query orderById($id: ID!) {
		node(id: $id) {
			... on Order {
				id
				email
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerAddressDelete
const customerAddressDelete = gql`
	mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
		customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
			customerUserErrors {
				code
				field
				message
			}
			deletedCustomerAddressId
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/queries/customer
const customerDetails = gql`
	query customerDetails($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			id
			defaultAddress {
				address1
				address2
				city
				company
				country
				countryCode
				firstName
				formatted
				id
				lastName
				latitude
				longitude
				name
				phone
				province
				provinceCode
				zip
			}
			addresses(first: 5) {
				edges {
					node {
						address1
						address2
						city
						company
						country
						countryCode
						firstName
						formatted
						id
						lastName
						latitude
						longitude
						name
						phone
						province
						provinceCode
						zip
					}
				}
			}
			orders(first: 30) {
				edges {
					node {
						id
						name
						orderNumber
						fulfillmentStatus
						successfulFulfillments{
							trackingInfo{
								number
							}
						}
						financialStatus
						totalPriceV2 {
							amount
						}
						processedAt
						lineItems(first: 30) {
							edges {
								node {
									title
									quantity,
									customAttributes {
										key
										value
									}
									discountedTotalPrice {
										amount
										currencyCode
									}
									variant{
										image{
											url
											altText
										}
										weight
									}
								}
							}
							pageInfo {
								hasPreviousPage
								hasNextPage
							}
						}
					}
				}
				pageInfo {
					hasPreviousPage
					hasNextPage
				}
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerCreate
const customerCreate = gql`
	mutation customerCreate($input: CustomerCreateInput!) {
		customerCreate(input: $input) {
			userErrors {
				field
				message
			}
			customer {
				id
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerResetByUrl
const customerResetByUrl = gql`
	mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
		customerResetByUrl(resetUrl: $resetUrl, password: $password) {
			customer {
				id
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerRecover
const customerRecover = gql`
	mutation customerRecover($email: String!) {
		customerRecover(email: $email) {
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerActivate
const customerActivate = gql`
	mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
		customerActivate(id: $id, input: $input) {
			customer {
				id
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerReset
const customerReset = gql`
	mutation customerReset($id: ID!, $input: CustomerResetInput!) {
		customerReset(id: $id, input: $input) {
			customer {
				id
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerAccessTokenCreate
const customerAccessTokenCreate = gql`
	mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
		customerAccessTokenCreate(input: $input) {
			userErrors {
				field
				message
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerAddressUpdate
const customerAddressUpdate = gql`
	mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
		customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
			customerAddress {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerAddressCreate
const customerAddressCreate = gql`
	mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
		customerAddressCreate( address: $address, customerAccessToken: $customerAccessToken) {
			customerAddress {
				id
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerActivateByUrl
const customerActivateByUrl = gql`
	mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
		customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
			customer {
				id
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`
export interface Checkout {
	appliedGiftCards: AppliedGiftCard
	availableShippingRates: AvailableShippingRates
	completedAt: Date
	createdAt: Date
	currencyCode: any
	customAttributes: any
	customer: Customer
	email: string
	id: number
	lineItems: CheckoutLineItemConnection
	note: string
	order: Order
	orderStatusUrl: URL
	paymentDue: number
	ready: boolean
	requiresShipping: boolean
	shippingAddress: MailingAddress
	shippingLine: ShippingRate
	subtotalPrice: number
	taxExempt: boolean
	taxesIncluded: boolean
	totalPrice: number
	totalTax: number
	updatedAt: Date
	webUrl: URL
}

export interface CheckoutLineItemConnection {
	edges: CheckoutLineItemEdge[]
	pageInfo: PageInfo
}

export interface PageInfo {
	hasNextPage: boolean
	hasPreviousPage: boolean
}

export interface CheckoutLineItemEdge {
	cursor: string
	node: CheckoutLineItem
}

export interface CheckoutLineItem {
	customAttributes: any
	id: number
	quantity: number
	title: string
	variant: Variant
}

export interface Variant {
	available: boolean
	id: string
	image: ShopifyImage
	priceV2: MoneyV2
	compareAtPriceV2: MoneyV2
	product: StorefrontProduct
	selectedOptions: SelectedOption[]
	title: string
	weight: number
	weightUnit: string
	legacyResourceId: number
}

export interface MoneyV2 {
	amount: string
	currencyCode: string
}

export interface ShopifyImage {
	altText: string
	id: number
	src: string
}

export interface SelectedOption {
	name: string
	value: string
}

export interface StorefrontProduct {
	collections: CollectionConnection
	createdAt: Date
	description: string
	descriptionHtml: string
	descriptionPlainSummary: string
	handle: string
	id: number
	images: any
	options: any
	productType: string
	publishedAt: Date
	tags: string
	title: string
	updatedAt: Date
	variants: any
	vendor: string
}

export interface CollectionConnection {
	edges: CollectionEdge[]
	pageInfo: PageInfo
}

export interface CollectionEdge {
	cursor: string
	node: StorefrontCollection
}

export interface StorefrontCollection {
	description: string
	descriptionHtml: string
	descriptionPlainSummary: string
	handle: string
	id: number
	image: any
	products: any
	title: string
	updatedAt: Date
}

export interface MailingAddress {
	address1: string
	address2: string
	city: string
	company: string
	country: string
	countryCode: string
	firstName: string
	formatted: string
	id: string
	lastName: string
	latitude: number
	longitude: number
	name: string
	phone: string
	province: string
	provinceCode: string
	zip: string
}

export interface ShippingRate {
	handle: string
	price: number
	title: string
}

export interface AvailableShippingRates {
	ready: boolean
	shippingRates: ShippingRate
}

export interface Customer {
	acceptsMarketing: boolean
	addresses: MailingAddressConnection
	createdAt: Date
	defaultAddress: MailingAddress
	displayName: string
	email: string
	firstName: string
	id: number
	lastName: string
	orders: OrderConnection
	phone: string
	updatedAt: Date
}

export interface MailingAddressConnection {
	edges: MailingAddressEdge[]
	pageInfo: PageInfo
}

export interface MailingAddressEdge {
	cursor: string
	node: MailingAddress
}

export interface OrderConnection {
	edges: OrderEdge[]
	pageInfo: PageInfo
}

export interface OrderEdge {
	cursor: string
	node: Order
}

export interface Order {
	cancelReason: any
	cancelledAt: Date
	createdAt: Date
	currencyCode: any
	customerUrl: URL
	displayFinancialStatus: any
	displayFulfillmentStatus: any
	id: number
	lineItems: OrderLineItemConnection
	orderNumber: number
	processedAt: Date
	shippingAddress: MailingAddress
	subtotalPrice: number
	totalPrice: number
	totalRefunded: number
	totalShippingPrice: number
	totalTax: number
	updatedAt: Date
}

export interface OrderLineItemConnection {
	name: OrderLineItemConnection
	edges: OrderLineItemEdge[]
	pageInfo: PageInfo
}

export interface OrderLineItemEdge {
	name: OrderLineItemEdge
	cursor: string
	node: OrderLineItem
}

export interface OrderLineItem {
	name: OrderLineItem
	customAttributes: any
	quantity: number
	title: string
	variant: Variant
}

export interface AppliedGiftCard {
	amountUsed: number
	balance: number
	id: number
	lastCharacters: string
}

export interface QueryRoot {
	customer: Customer
	node: Node
	shop: Shop
}

export interface Shop {
	collections: CollectionConnection
	currencyCode: any
	description: string
	moneyFormat: string
	name: string
	primaryDomain: Domain
	privacyPolicy: ShopPolicy
	products: any
	refundPolicy: ShopPolicy
	termsOfService: ShopPolicy
}

export interface Domain {
	host: string
	sslEnabled: boolean
	url: URL
}

export interface ShopPolicy {
	body: string
	id: number
	title: string
	url: URL
}

export interface CheckoutAttributesUpdatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface UserError {
	field: string
	message: string
}

export interface CheckoutAttributesUpdateInput {
	checkoutId: number
	note: string
	customAttributes: any
	allowPartialAddresses: boolean
}

export interface CheckoutCompleteFreePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutCompleteFree {
	checkoutId: number
}

export interface CheckoutCompleteWithCreditCardPayload {
	checkout: Checkout
	payment: Payment
	userErrors: UserError
}

export interface Payment {
	amount: number
	billingAddress: MailingAddress
	checkout: Checkout
	creditCard: CreditCard
	errorMessage: string
	id: number
	idempotencyKey: string
	ready: boolean
	test: boolean
	transaction: Transaction
}

export interface CreditCard {
	brand: string
	expiryMonth: number
	expiryYear: number
	firstDigits: string
	firstName: string
	lastDigits: string
	lastName: string
	maskedNumber: string
}

export interface Transaction {
	amount: number
	kind: any
	status: any
	test: boolean
}

export interface CheckoutCompleteWithCreditCardInput {
	checkoutId: number
	amount: number
	idempotencyKey: string
	billingAddress: MailingAddressInput
	vaultId: string
	test: boolean
}

export interface MailingAddressInput {
	address1: string
	address2: string
	city: string
	company: string
	country: string
	firstName: string
	lastName: string
	phone: string
	province: string
	zip: string
}

export interface CheckoutCompleteWithTokenizedPaymentPayload {
	checkout: Checkout
	payment: Payment
	userErrors: UserError
}

export interface CheckoutCompleteWithTokenizedPaymentInput {
	checkoutId: number
	amount: number
	idempotencyKey: string
	billingAddress: MailingAddressInput
	type: string
	paymentData: string
	test: boolean
	identifier: string
}

export interface CheckoutCreatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutCreateInput {
	email: string
	lineItems: CheckoutLineItemInput
	shippingAddress: MailingAddressInput
	note: string
	customAttributes: any
	allowPartialAddresses: boolean
}

export interface CheckoutLineItemInput {
	variantId: number
	quantity: number
	customAttributes: any
}

export interface CheckoutCustomerAssociatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutCustomerAssociateInput {
	checkoutId: number
	customerAccessToken: string
}

export interface CheckoutCustomerDisassociatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutCustomerDisassociateInput {
	checkoutId: number
}

export interface CheckoutEmailUpdatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutEmailUpdateInput {
	checkoutId: number
	email: string
}

export interface CheckoutGiftCardApplyPayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutGiftCardApplyInput {
	giftCardCode: string
	checkoutId: number
}

export interface CheckoutLineItemsAddPayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutLineItemsAddInput {
	lineItems: CheckoutLineItemInput
	checkoutId: number
}

export interface CheckoutLineItemsRemovePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutLineItemsRemoveInput {
	checkoutId: number
	lineItemIds: number
}

export interface CheckoutLineItemsUpdatePayload {
	name: CheckoutLineItemsUpdatePayload
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutLineItemUpdateInput {
	id: number
	variantId: number
	quantity: number
	customAttributes: any
}

export interface CheckoutShippingAddressUpdatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutShippingAddressUpdateInput {
	shippingAddress: MailingAddressInput
	checkoutId: number
}

export interface CheckoutShippingLineUpdatePayload {
	checkout: Checkout
	userErrors: UserError
}

export interface CheckoutShippingLineUpdateInput {
	checkoutId: number
	shippingRateHandle: string
}

export interface CustomerAccessTokenCreatePayload {
	customerAccessToken: CustomerAccessToken
	userErrors: UserError
}

export interface CustomerAccessToken {
	accessToken: string
	expiresAt: Date
}

export interface CustomerAccessTokenCreateInput {
	email: string
	password: string
}

export interface CustomerActivateByUrlInput {
	activationUrl: string
	password: string
}

export interface CustomerAccessTokenDeletePayload {
	deletedAccessToken: string
	deletedCustomerAccessTokenId: string
	userErrors: UserError
}

export interface CustomerAccessTokenDeleteInput {
	customerAccessToken: string
}

export interface CustomerAccessTokenRenewPayload {
	customerAccessToken: CustomerAccessToken
	userErrors: UserError
}

export interface CustomerAccessTokenRenewInput {
	customerAccessToken: string
}

export interface CustomerActivatePayload {
	customer: Customer
	userErrors: UserError
}

export interface CustomerActivateInput {
	id: number
	resetToken: string
	password: string
}

export interface CustomerAddressCreatePayload {
	customerAddress: MailingAddress
	userErrors: UserError
}

export interface CustomerAddressCreateInput {
	customerAccessToken: string
	address: MailingAddressInput
}

export interface CustomerAddressDeletePayload {
	deletedCustomerAddressId: string
	userErrors: UserError
}

export interface CustomerAddressDeleteInput {
	id: number
	customerAccessToken: string
}

export interface CustomerAddressUpdatePayload {
	customerAddress: MailingAddress
	userErrors: UserError
}

export interface CustomerAddressUpdateInput {
	customerAccessToken: string
	id: string
	address: MailingAddressInput
}

export interface CustomerCreatePayload {
	customer: Customer
	userErrors: UserError
}

export interface CustomerCreateInput {
	firstName: string
	lastName: string
	email: string
	password: string
	acceptsMarketing: boolean
}

export interface CustomerRecoverPayload {
	userErrors: UserError
}

export interface CustomerRecoverInput {
	email: string
}

export interface CustomerResetPayload {
	customer: Customer
	userErrors: UserError
}

export interface CustomerResetInput {
	resetToken: string
	password: string
}

export interface CustomerUpdatePayload {
	customer: Customer
	userErrors: UserError
}

export interface CustomerUpdateInput {
	firstName: string
	lastName: string
	email: string
	password: string
	acceptsMarketing: boolean
}

export interface MailingAddressInput {
	address1: string
	address2: string
	city: string
	company: string
	country: string
	firstName: string
	lastName: string
	phone: string
	province: string
	zip: string
}
