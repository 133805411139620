import { NgModule } from '@angular/core'
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router'
import { PreviewComponent } from './common/components'
import { RegularComponent } from './common/layouts/regular/regular.component'
import { CollectionResolver } from './resolver/collection.resolver'
import { FlexibleContentResolver } from './resolver/flexible.resolver'
import { ProductResolver } from './resolver/product.resolver'
import { SetPasswordGuard } from './accounts/set-password.guard'
import { ShopifyLoginGuard } from './accounts/shopify-login.guard'
import { AppRoutingReuseStrategy } from './app-routing-reuse.strategy'
const routes: Routes = [
	{
		path: '',
		component: RegularComponent,
		children: [
			{
				path: 'preview',
				component: PreviewComponent,
			},
			{
				path: 'accounts',
				loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
			},
			{
				path: 'account/login',
				canActivate: [ShopifyLoginGuard],
			},
			{
				path: 'account/reset/:customerId/:resetToken',
				canActivate: [SetPasswordGuard],
			},
			{
				path: '',
				resolve: { content: FlexibleContentResolver },
				runGuardsAndResolvers: 'paramsOrQueryParamsChange',
				loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
			},
			{
				path: 'products/:handle',
				resolve: { content: ProductResolver },
				runGuardsAndResolvers: 'paramsOrQueryParamsChange',
				loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
			},
			{
				path: 'collections/:handle',
				resolve: { content: CollectionResolver },
				runGuardsAndResolvers: 'paramsOrQueryParamsChange',
				loadChildren: () => import('./collection/collection.module').then(m => m.CollectionModule),
				data: { reuseRoute: true },
			},
			{
				path: 'blogs/journal',
				loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
			},
			{
				path: 'cart',
				title: 'Cart | Lo & Co',
				loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
			},
			{
				path: 'pages/wishlist',
				loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule),
				resolve: { content: FlexibleContentResolver },
			},
			{
				path: 'pages/:handle',
				loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
				runGuardsAndResolvers: 'paramsOrQueryParamsChange',
				resolve: { content: FlexibleContentResolver },
			},
			{
				path: 'search',
				loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
			},
			{
				path: 'styleguide',
				title: 'Styleguide | Lo & Co',
				loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleguideModule),
			},
			{
				path: '**',
				title: '404 Error | Lo & Co',
				loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
			},
		],
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
	exports: [RouterModule],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: AppRoutingReuseStrategy,
		},
	],
})
export class AppRoutingModule {}
