import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, Input } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { EnvironmentService } from 'src/app/services/environment.service'
import { PrismicService } from 'src/app/services/prismic.service'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	date: Date = new Date()

	newsletterForm!: FormGroup

	@Input() footer!: any
	response: string = ''
	success?: boolean
	submitted = false
	error?: boolean

	theme: any
	_klaviyo: string = ''
	_list: string = ''

	constructor(
		private environmentService: EnvironmentService,
		private formBuilder: FormBuilder,
		private utilityService: UtilityService,
		private prismicService: PrismicService,
		private http: HttpClient,
		private gtmService: GoogleTagManagerService
	) {
		this.prismicService.themeObservable.subscribe(theme => {
			this.theme = theme
		})
		this.environmentService.observableEnvironment.subscribe((data: any) => {
			if (data.country == 'AU') {
				this._klaviyo = 'TXgzkm'
				this._list = 'XbaDwt'
			} else {
				this._klaviyo = 'RpKqTa'
				this._list = 'ULvaZ2'
			}
		})
		this.newsletterForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		})
	}

	closeOverlay() {
		this.utilityService.closePopup()
		this.utilityService.closeOverlay()
	}

	submit() {
		this.submitted = true

		if (this.newsletterForm.invalid) {
			return
		}
		const email = this.newsletterForm.controls.email.value

		const options = {
			headers: new HttpHeaders({
				revision: '2023-12-15',
				'content-type': 'application/json',
			}),
			body: {
				data: {
					type: 'subscription',
					attributes: {
						custom_source: window.location.href,
						profile: {
							data: {
								type: 'profile',
								attributes: {
									email: email,
								},
							},
						},
					},
					relationships: {
						list: {
							data: {
								type: 'list',
								id: this._list,
							},
						},
					},
				},
			},
		}
		const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=' + this._klaviyo
		this.http.post(url, options.body, { headers: options.headers }).subscribe(
			(data: any) => {
				this.success = true
				this.gtmService.pushTag({ event: 'FormSubmitted', formid: 'SignupForm' })
			},
			err => {
				this.success = false
				this.error = err
			},
			() => {
				setTimeout(() => {
					this.success = false
					this.newsletterForm.reset()
				}, 6000)
			}
		)
	}
}
