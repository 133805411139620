<div class="row mt-sm-5 pt-sm-5 pt-4">
    <div class="col-11 offset-1 offset-sm-0 col-sm-2 my-2 my-sm-0 grey">
        <h1 class="justify-content-sm-end d-flex ver-text times mx-md-auto">
            16 / Account
        </h1>
    </div>

    <div class="col-12 col-sm-10 bg-white drop py-4 py-md-5 mb-sm-5">
        <div class="row d-none d-md-block">
            <div class="col-11 offset-md-1 col-12 mt-lg-5 ps-0">
                <h2 class="title times pt-4">Reset Your Password</h2>
            </div>
        </div>

        <div class="row">
            <div
                class="col-md-8 col-lg-6 col-xl-5 offset-md-1 col-12 px-5 px-md-0"
            >
                <h2 class="title times pt-4 pb-2 d-block d-md-none">
                    Forgot Password
                </h2>
                <p class="aktiv fs-6 mb-md-5">
                    Enter a new password for your account
                </p>

				@if (isBrowser) {
					<form
							class="recover-form needs-validation"
							[ngClass]="{ 'was-validated': !setForm.form.valid && setForm.form.dirty }"
							#setForm="ngForm"
						>
						<div class="input-group" [ngClass]="{ 'was-validated': !password.valid && password.dirty }">
							<input
								required
								class="form-control p-3 fs-6"
								password
								minlength="8"
								name="password"
								id="password"
								type="password"
								placeholder="Password"
								#password="ngModel"
								[(ngModel)]="newPassword"
								autocomplete="new-password"
								aria-required="true"
								autocorrect="off"
								autocapitalize="off"
							/>

							<div *ngIf="password.invalid && password.dirty" class="invalid-feedback">
								<div *ngIf="password.errors?.required" class="ps-1 validation-error">
									This field is required.
								</div>
								<div *ngIf="password.errors?.minlength" class="ps-1 validation-error">
									Password must be at least 8 characters long.
								</div>
							</div>
						</div>

						@if (success === 'success' && (setForm.form.valid && setForm.form.dirty)) {
							<label for="forgot-email" class="account-set-password__alert account-set-password__alert--success pe-3 ps-3">
								<p>Success! Your password has been changed successfully.</p>
							</label>
						} @else if (success === 'failed') {
							<label for="forgot-email" class="account-set-password__alert account-set-password__alert--error pe-3 ps-3">
								<p>Error! {{ error }}</p>
							</label>
						}

						<div class="form-group mt-3 mb-md-5 pb-md-5">
							<button
								[disabled]="!setForm.form.valid || !setForm.form.dirty"
								class="w-100 btn btn-primary btn-lg p-3"
								(click)="submit()"
							>
								Set Password
							</button>
						</div>
					</form>
				} @else {
					<div class="input-group">
						<input
							required
							placeholder="Password"
							class="form-control p-3 fs-6"
							type="password"
							value=""
							disabled
						/>
					</div>

					<div class="form-group mt-3 mb-md-5 pb-md-5">
						<button
							[disabled]="true"
							class="w-100 btn btn-primary btn-lg p-3"
							(click)="submit()"
						>
							Set Password
						</button>
					</div>
				}

            </div>
        </div>
    </div>
</div>
