import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { ShopifyService } from './shopify.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerStoreService {
  private customerDataSubject = new BehaviorSubject<any>(null);
  customerData$ = this.customerDataSubject.asObservable();
  protected isInitialized = false;

  constructor(private shopifyService: ShopifyService) {}

  getInitializedState(): boolean {
    return this.isInitialized;
  }

  loadCustomerData() {
    this.shopifyService.customerDetails()
      .pipe(
        switchMap((response) => {
          const { customer } = response as { customer: any };
          const addresses = customer.addresses.edges.map((x: any) => x.node);
          const orders = customer.orders.edges.map((orderEdge: any) => {
            const order = orderEdge.node;
            const lineItems = order.lineItems.edges.map((itemEdge: any) => itemEdge.node);

            const subtotal = lineItems.reduce((sum: number, item: { discountedTotalPrice: { amount: string } }) => {
              const itemAmount = parseFloat(item.discountedTotalPrice.amount);
              return sum + (isNaN(itemAmount) ? 0 : itemAmount);
            }, 0);

            const currency = lineItems[0].discountedTotalPrice.currencyCode //assuming all line items share the same currency
            return {
              fulfillmentStatus: order.fulfillmentStatus,
              id: order.id,
              name: order.name,
              orderNumber: order.orderNumber,
              processedAt: order.processedAt,
              totalPriceV2: order.totalPriceV2,
              payment: order.financialStatus,
              trackingNumber: order.successfulFulfillments.length ? order.successfulFulfillments[0].trackingInfo[0].number : null,
              summary: lineItems.map((item: any) => ({
                title: item.title,
                quantity: item.quantity,
                price: item.discountedTotalPrice.amount,
                currency: item.discountedTotalPrice.currencyCode,
                imageUrl: item.variant.image.url,
                altText: item.variant.image.altText,
                weight: item.variant.weight,
              })),
              subtotal,
              currency
            };
          });
          const data = { ...customer, addresses, orders };
          return of(data);
        })
      )
      .subscribe(data => {
        console.log('data: ', data)
        this.isInitialized = true;
        this.customerDataSubject.next(data)
      });
  }

}