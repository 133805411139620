import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-balance',
	templateUrl: './balance.component.html',
	styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
	window = window
	@Input() content: any
	selected: null | any
	constructor() {}
	options = [
		{
			value: 'redeem your points',
			name: 'redeem your points',
			icon: '/assets/redeem-points.svg',
		},
		{
			value: 'Earn points',
			name: 'Earn points',
			icon: '/assets/earn-points.svg',
		},
		{
			value: 'Refer a friend',
			name: 'Refer a friend',
			icon: '/assets/refer-friend.svg',
		},
	]
	ngOnInit(): void {}
}
