import { Component, OnInit } from '@angular/core'
import { ShopifyService } from 'src/app/services/shopify.service'

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
	loading: boolean = true
	customer: any
	orders: any
	constructor(private shopifyService: ShopifyService) {}

	ngOnInit(): void {
		this.loading = true
		this.shopifyService.customerDetails().subscribe(
			(customerData: any) => {
				this.customer = customerData.customer
				this.orders = customerData.customer.orders.edges.map((x: any) => x.node)
			},
			err => {
				console.error('Error fetching customer data', err)
			},
			() => {
				this.loading = false
			}
		)
	}
}
