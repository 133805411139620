<div class="row mt-sm-5 pt-sm-5 pt-4">
	<div class="col-11 offset-1 offset-sm-0 col-sm-2 my-2 my-sm-0 grey">
	  <h1 class="justify-content-sm-end d-flex ver-text times mx-md-auto">16 / Account</h1>
	</div>

	<div class="col-12 col-sm-10 bg-white drop py-4 py-md-5 mb-sm-5">
	  <div class="row d-none d-md-block">
		<div class="col-11 offset-md-1 col-12 mt-lg-5 ps-0">
		  <h2 class="title times pt-4">{{ pageModeData[mode].title }}</h2>
		</div>
	  </div>

	  <div class="row">
		<div class="col-md-8 col-lg-6 col-xl-5 offset-md-1 col-12 px-5 px-md-0">
		  <h2 class="title times pt-4 pb-2 d-block d-md-none">{{ pageModeData[mode].title }}</h2>
		  <p class="aktiv fs-6 mb-md-5">{{ pageModeData[mode].subtitle }}</p>

		  <form [formGroup]="resetForm" (ngSubmit)="submit()">

			<div class="form-group" [ngClass]="{ 'was-validated': formControls.email.valid && formControls.email.dirty && success !== 'failed' }">
				<input
					formControlName="email"
					class="form-control p-3 fs-6"
					email
					name="forgot-email"
					type="email"
					placeholder="Email *"
					aria-required="true"
					autocorrect="off"
					autocapitalize="off"
					[readonly]="pageModeData[mode].readOnlyInput"
				/>
				@if (success === 'success') {
					<label for="forgot-email" class="account-reset-password__alert account-reset-password__alert--success pe-3 ps-3">
						<p>Success! Check your email for a reset link.</p>
					</label>
				} @else if (success === 'failed') {
					<label for="forgot-email" class="account-reset-password__alert account-reset-password__alert--error pe-3 ps-3">
						<p>Error! {{ error }}</p>
					</label>
				}
				<div *ngIf="showValidation('email')" class="mt-2">
					<div *ngIf="formControls.email.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
					<div *ngIf="formControls.email.errors?.minlength" class="mt-2 text-danger fs-6">Email must be at least 8 characters long.</div>
				</div>
			</div>

			<div class="form-group mt-3 mb-md-5 pb-md-5 align-items-center">
			  <button style="height: 48px;" class="w-100 btn btn-primary btn-lg p-3 position-relative"
			  [ngClass]="{ 'ng-invalid': isFormValid }" [disabled]="loading" type="submit">
				@if (loading) {
					<div class=" d-flex flex-column align-items-center justify-content-center">
						<div class="spinner-border spinner-border-sm" role="status">
						</div>
					</div>
				} @else {
					Reset
				}
			</button>

			  <a [routerLink]="pageModeData[mode].linkUrl" class="mt-3 link-dark text-decoration-underline fs-6">{{ pageModeData[mode].linkText }}</a>
			</div>

		  </form>

		</div>
	  </div>
	</div>
  </div>
