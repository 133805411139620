import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { AppComponent } from '../app.component'

class LocalStorage implements Storage {
	[name: string]: any
	readonly length!: number
	clear(): void {}
	getItem(key: string): string | null {
		return null
	}
	key(index: number): string | null {
		return null
	}
	removeItem(key: string): void {}
	setItem(key: string, value: string): void {}
}

class DocumentStorage {
	constructor(private document: Document | any) {}
	setItem(key: string, value: string) {
		return (this.document['storage'][key] = value)
	}
	getItem(key: string) {
		return this.document['storage'][key]
	}
	clear() {
		delete this.document['storage']
	}
	removeItem(key: string) {
		delete this.document['storage'][key]
	}
}

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService implements Storage {
	storage: Storage

	constructor(@Inject(DOCUMENT) document: Document | any, @Inject(PLATFORM_ID) private platformId: any,) {
		const isBrowser = isPlatformBrowser(this.platformId);
		this.storage = new LocalStorage()

		// AppComponent.isBrowser.subscribe(isBrowser => {
		// 	if (isBrowser) {
		// 		try {
		if (isBrowser) {
			this.storage = localStorage
		}
		// 		} catch {
		// 			document['storage'] = new DocumentStorage(document)
		// 		}
		// 	} else {
		// 		document['storage'] = new DocumentStorage(document)
		// 	}
		// })
	}

	[name: string]: any

	length: number = 0

	clear(): void {
		this.storage.clear()
	}

	getItem(key: string): string | null {
		return this.storage.getItem(key)
	}

	key(index: number): string | null {
		return this.storage.key(index)
	}

	removeItem(key: string): void {
		return this.storage.removeItem(key)
	}

	setItem(key: string, value: string): void {
		try {
			return this.storage.setItem(key, value)
		} catch (err) {
			console.log(err)
		}
	}

	getItemJson(key: string): any | null {
		return this.storage.getItem(key) ? JSON.parse(this.storage.getItem(key) as string) : null
	}

	setItemJson(key: string, object: any): void {
		try {
			return this.storage.setItem(key, JSON.stringify(object))
		} catch (err) {
			console.log(err)
		}
	}
}
