<ng-container *ngIf="success">
	<div class="col-12 text-center align-items-center my-auto">
		<h4 class="mb-2 pt-4 pt-md-0 aktiv fs-5 fw-bold">Account Created!</h4>
		<p class="aktiv fs-6 mb-md-5">Please check your email to confirm your account</p>
	</div>
</ng-container>
  
<ng-container *ngIf="!success">
	<h6 class="mb-2 pt-4 pt-md-0 aktiv fs-5 fw-bold">Create account</h6>
	<p class="aktiv fs-6 mb-md-5">Register below to create an account</p>
	<div class="text-danger">{{ errorMessage }}</div>
  
	<form class="needs-validation" [ngClass]="{ 'loading-fields': loading }" [formGroup]="registerForm" (ngSubmit)="submit()">
		<div class="input-group" [ngClass]="{ 'was-validated': formControls.firstName.valid && formControls.firstName.dirty }">
			<input class="form-control p-3 fs-6" placeholder="First name *" formControlName="firstName"/>
		</div>
		<div *ngIf="showValidation('firstName')" class="mt-2">
			<div *ngIf="formControls.firstName.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
			<div *ngIf="formControls.firstName.errors?.minlength" class="mt-2 text-danger fs-6">First name must be at least 2 characters long.</div>
		</div>

		<div class="input-group pt-3" [ngClass]="{ 'was-validated': formControls.lastName.valid && formControls.lastName.dirty }">
			<input
				class="form-control p-3 fs-6"
				placeholder="Last name *"
				formControlName="lastName"
				(change)="formControls.lastName.setValue(formControls.lastName.value.trim())"
			/>
		</div>
		<div *ngIf="showValidation('lastName')">
			<div *ngIf="formControls.lastName.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
			<div *ngIf="formControls.lastName.errors?.minlength" class="mt-2 text-danger fs-6">Last name must be at least 2 characters long.</div>
		</div>

		<div class="input-group pt-3" [ngClass]="{ 'was-validated': formControls.email.valid && formControls.email.dirty }">
			<input class="form-control p-3 fs-6" type="email" placeholder="Email address *" formControlName="email"/>
		</div>
		<div *ngIf="showValidation('email')">
			<div *ngIf="formControls.email.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
			<div *ngIf="formControls.email.errors?.minlength" class="mt-2 text-danger fs-6">Email must be at least 8 characters long.</div>
			<div *ngIf="formControls.email.errors?.invalidEmail" class="mt-2 text-danger fs-6">Please enter a valid email address.</div>
		</div>

		<div class="input-group pt-3" [ngClass]="{ 'was-validated': formControls.password.valid && formControls.password.dirty }">
			<input class="form-control p-3 fs-6" type="password" placeholder="Password *" formControlName="password"/>
		</div>
		<div *ngIf="showValidation('password')">
			<div *ngIf="formControls.password.errors?.required" class="mt-2 text-danger fs-6">This field is required.</div>
			<div *ngIf="formControls.password.errors?.minlength" class="mt-2 text-danger fs-6">Password must be at least 8 characters long.</div>
		</div>

		<div class="form-group mt-3 mb-5 pb-md-5">
			<button class="w-100 btn btn-primary btn-lg p-3"
			[ngClass]="{ 'ng-invalid': isFormValid }"
			[disabled]="isFormValid">Create Account</button>
		</div>
	</form>
</ng-container>
  