import { animate, animateChild, query, sequence, stagger, style, transition, trigger } from '@angular/animations'
import { Component, HostListener, Input, OnInit } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
	selector: 'app-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
	animations: [
		trigger('megaMenu', [
			transition(':enter', [
				query('.mobi-nav', [
					style({ opacity: 0, marginTop: '-100%' }),
					animate('600ms cubic-bezier(0.35,0,0.25,1)', style({ opacity: 1, marginTop: '0%' })),
					query('@linkList', animateChild()),
				]),
			]),
			transition(':leave', [
				sequence([
					query('.animated-link ', [
						style({ opacity: 1, transform: 'none' }),
						stagger(-75, [animate('75ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: 'translateY(30px)' }))]),
					]),
					query('.mobi-nav', [style({ opacity: 1 }), animate('300ms cubic-bezier(0.35,0,0.25,1)', style({ opacity: 0 }))]),
				]),
			]),
		]),
		trigger('linkList', [
			transition(':enter', [
				query('.animated-link ', [
					style({ opacity: 0, transform: 'translateY(30px)' }),
					stagger(150, [animate('250ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))]),
				]),
			]),
		]),
	],
})
export class MobileMenuComponent implements OnInit {
	@Input() theme!: any
	open: boolean = false
	selectedImage: any
	selectedLink: any = null
	mobileMenuImages: any
	window: any
	offsetHeight: number = 0
	constructor(private utilityService: UtilityService) {
		if (this.utilityService.isBrowser()) {
			this.window = this.utilityService.getWindow()
			this.offsetHeight = this.window?.innerWidth <= 991 ? 40 : 0
		}

		this.utilityService.menuStateObservable.subscribe(data => {
			this.open = data
		})
	}

	clickedInside($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
	}

	linkClose() {
		delete this.selectedLink
	}

	menuClose() {
		this.utilityService.menuStateSubject.next(false)
	}

	linkOpen(link: any) {
		if (link.active) {
			delete this.selectedLink
			link.active = false
			return
		}
		this.linkClose()
		this.selectedLink = link
		link.active = true
	}

	@HostListener('window:resize', ['$event'])
	onResize($event: any) {
		this.offsetHeight = this.window?.innerWidth <= 991 ? 40 : 0

		if (this.open) {
			this.menuClose()
		}
	}

	ngOnInit() {}
}
