import { animate, state, style, transition, trigger } from '@angular/animations'

export const openClose = trigger('openClose', [
	state(
		'*',
		style({
			height: '*',
			pointerEvents: 'all',
			opacity: '*',
			paddingTop: '*',
			paddingBottom: '*',
			overflow: 'hidden',
		})
	),
	state(
		'void',
		style({
			height: '0px',
			pointerEvents: 'none',
			opacity: '0',
			paddingTop: '0',
			paddingBottom: '0',
			overflow: 'hidden',
		})
	),
	transition(':enter', [animate('0.5s ease-in-out')]),
	transition(':leave', [animate('0.5s ease-in-out')]),
])
