import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, ReplaySubject, of } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { Product } from '../classes/product'
import { WishlistDto, WishlistEventDto } from '../classes/wishlist.dto'
import { EnvironmentService } from './environment.service'
import { LocalStorageService } from './localstorage.service'

@Injectable()
export class WishlistService {
	apiUrl: string = 'https://prod-api.wishlist.plutocracy.io/api/wishlist'
	environment: any
	wishlistSubject = new ReplaySubject(1)
	wishlist$ = this.wishlistSubject.asObservable()

	constructor(private environmentService: EnvironmentService, private http: HttpClient, private localStorage: LocalStorageService) {
		this.environment = this.environmentService.environment
	}

	assembleApiUrl(pathname: 'event' | 'id' | 'email') {
		return `${this.apiUrl}/${pathname}/${this.environment.wishlistId}`
	}

	itemInWishlist(productHandle: string, email?: any, id?: any): boolean {
		let wishlist = this.getWishlistFromLocalStorage()
		id = id ? id : this.getIdFromStorage()
		email = email ? email : this.getEmailFromStorage()
		if (!wishlist) {
			if (email) {
				this.getWishlistByEmail(email).pipe(tap(wishlist => wishlist))
			}
			if (!email && id) {
				this.getWishlistById(id).pipe(tap(wishlist => wishlist))
			}
		}
		// clean this up -- should only be one layer of event body
		return wishlist?.events?.find((x: any) => x?.eventBody?.productHandle === productHandle) ? true : false
	}

	getIdFromStorage(): string | null {
		let wishlistId = null
		try {
			wishlistId = this.localStorage?.getItem('fl-wishlist-id')
		} catch (err) {
			console.error('Could not get wishlistId from localStorage', err)
		} finally {
			return wishlistId
		}
	}

	setIdInStorage(id: string) {
		if (!id) {
			console.warn('ID is falsey')
			return
		}
		try {
			this.localStorage.setItem('fl-wishlist-id', id)
		} catch (err) {
			console.error('Could not set wishlistId in localStorage')
		} finally {
			return this.localStorage?.getItem('fl-wishlist-id')
		}
	}

	getEmailFromStorage(): string | null {
		let wishlistEmail = null
		try {
			wishlistEmail = this.localStorage?.getItem('fl-wishlist-email')
		} catch (err) {
			console.error('Could not get fl-wishlist-email from localStorage', err)
		} finally {
			return wishlistEmail
		}
	}

	setEmailInStorage(email: string) {
		if (!email) {
			console.warn('Email is falsey')
			return
		}
		try {
			this.localStorage.setItem('fl-wishlist-email', email)
		} catch (err) {
			console.error('Could not set fl-wishlist-email in localStorage')
		} finally {
			return this.localStorage?.getItem('fl-wishlist-email')
		}
	}

	getWishlistFromLocalStorage() {
		const wishlist = JSON.parse(this.localStorage?.getItem('fl-wishlist') as string)
		this.wishlistSubject.next(wishlist)
		return wishlist
	}

	setWishlistInLocalStorage(wishlist: WishlistDto) {
		this.localStorage.setItem('fl-wishlist', JSON.stringify(wishlist))
		return this.getWishlistFromLocalStorage()
	}

	assembleWishlistEvent(
		eventType: 'email/add' | 'wishlist/add' | 'wishlist/remove' | 'tombstone',
		product?: Product | any,
		meta?: any,
		email?: string,
		id?: string
	): WishlistEventDto {
		const wishlistEvent: WishlistEventDto = {
			eventType: eventType,
			event: {
				...(product && {
					id: product.id,
					productId: product.id,
					productHandle: product.handle,
					title: product.title,
					minPrice: product?.min_price,
					maxPrice: product?.max_price,
					imageSrc: product?.images[0]?.src,
					vendor: product?.vendor,
				}),
			},
			meta: meta
				? {
						metaBody: { ...meta },
				  }
				: {},
		}
		if (email) {
			wishlistEvent.email = email
		}
		if (!email && id) {
			wishlistEvent.wishlistId = id
		}
		return wishlistEvent
	}

	getWishlistByEmail(email: string) {
		return this.http.post(this.assembleApiUrl('email'), { email: email }).pipe(
			tap(wishlist => {
				this.wishlistSubject.next(wishlist)
			})
		)
	}

	getWishlistById(wishlistId: string) {
		return this.http.post(this.assembleApiUrl('id'), { wishlistId: wishlistId }).pipe(
			tap(wishlist => {
				this.wishlistSubject.next(wishlist)
			})
		)
	}

	async addEmailToWishlist(email?: string | null | undefined, id?: string) {
		if (!email) {
			email = this.getEmailFromStorage()
		}
		if (email) {
			await this.http
				.post(this.assembleApiUrl('event'), this.assembleWishlistEvent('email/add', undefined, undefined, email, id))
				.pipe(
					tap(() => {
						const id = this.getIdFromStorage()
						const email = this.getEmailFromStorage()

						if (email) {
							return this.getWishlistByEmail(email).pipe(tap(wishlist => wishlist))
						}
						if (id) {
							return this.getWishlistById(id).pipe(tap(wishlist => wishlist))
						}
						return
					})
				)
				.toPromise()
		}
		return
	}

	addToWishlist(product: any, meta?: any, email?: any, id?: string) {
		if (!email) {
			email = this.getEmailFromStorage()
		}
		return this.http.post(this.assembleApiUrl('event'), this.assembleWishlistEvent('wishlist/add', product, meta, email, id)).pipe(
			tap(() => {
				const id = this.getIdFromStorage()
				const email = this.getEmailFromStorage()

				if (email) {
					return this.getWishlistByEmail(email).pipe(tap(wishlist => wishlist))
				}
				if (id) {
					return this.getWishlistById(id).pipe(tap(wishlist => wishlist))
				}
				return
			})
		)
	}

	removeFromWishlist(product: any, meta?: any, email?: any, id?: string): Observable<any> {
		console.log('removeFromWishlist email: ', email, 'id: ', id)
		email = email || this.getEmailFromStorage();
		return this.http.post(
		  this.assembleApiUrl('event'),
		  this.assembleWishlistEvent('wishlist/remove', product, meta, email, id)
		).pipe(
		  switchMap(() => email ? this.getWishlistByEmail(email) : (id ? this.getWishlistById(id) : of(null)))
		);
	}
	  
}
