import { Injectable } from '@angular/core';
import { DetachedRouteHandle } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class RouteStorageService {
    private storedHandles: { [key: string]: DetachedRouteHandle } = {};

    public storeHandle(id: string, handle: DetachedRouteHandle): void {
        this.storedHandles[id] = handle;
    }

    public retrieveHandle(id: string): DetachedRouteHandle | null {
        return this.storedHandles[id] || null;
    }

    public hasHandle(id: string): boolean {
        return !!this.storedHandles[id];
    }

    public findHandle(path: string): string {
        const foundKey = Object.keys(this.storedHandles).find(key => key.includes(path));
        return foundKey || '';
    }

    public findHandleAndReturnMetafields(path: string): { foundKey: string; metafields: { global: { description_tag?: string; title_tag?: string; }, page: {} }} | null {
        const foundKey = Object.keys(this.storedHandles).find(key => key.includes(path));

        let metafields = { global: {}, page: {} };
        if (this.storedHandles[foundKey!]) {
            metafields = (this.storedHandles[foundKey!] as any).componentRef.instance.collection.metafields;
        }

        return foundKey ? { foundKey, metafields } : null;
    }
}
