import { APP_BASE_HREF, CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { LOCALE_ID, NgModule, TransferState } from '@angular/core'
import { BrowserModule, provideClientHydration } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { EnvironmentService } from './services/environment.service'
import { LocalStorageService } from './services/localstorage.service'
import { AsyncApiCallHelperService } from './services/macrotask.service'
import { SeoService } from './services/seo.service'
import { SessionStorageService } from './services/sessionstorage.service'
import { ShopifyService } from './services/shopify.service'
import { UtilityService } from './services/utility.service'
import { WishlistService } from './services/wishlist.service'
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AngularSvgIconModule } from 'angular-svg-icon';


@NgModule({
	declarations: [AppComponent],
	imports: [
		AngularSvgIconModule.forRoot(),
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		CommonModule,
		GoogleTagManagerModule.forRoot({
			id: 'GTM-T7F5JJS',
		}),
	],
	providers: [
		UtilityService,
		SeoService,
		WishlistService,
		LocalStorageService,
		SessionStorageService,
		ShopifyService,
		EnvironmentService,
		TransferState,
		AsyncApiCallHelperService,
		provideClientHydration(),
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: LOCALE_ID, useValue: 'en-AU' },

		// {
		// 	provide: ErrorHandler,
		// 	useValue: Sentry.createErrorHandler({
		// 		showDialog: false,
		// 	}),
		// },
		// {
		// 	provide: Sentry.TraceService,
		// 	deps: [Router],
		// },
		// {
		// 	provide: APP_INITIALIZER,
		// 	useFactory: () => () => {},
		// 	deps: [Sentry.TraceService],
		// 	multi: true,
		// },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
