import { Component, HostListener } from '@angular/core'
import { combineLatest } from 'rxjs'
import { EnvironmentService } from 'src/app/services/environment.service'
import { LocalStorageService } from 'src/app/services/localstorage.service'
import { PrismicService } from 'src/app/services/prismic.service'
import { UtilityService } from 'src/app/services/utility.service'
import { storeMap } from 'src/environments/environment'

@Component({
	selector: 'app-regular',
	templateUrl: './regular.component.html',
	styleUrls: ['./regular.component.scss'],
})
export class RegularComponent {
	theme!: any
	maintenance: boolean = false
	featured: any

	country: any
	current: any
	destination: any
	storeMap = storeMap
	currencyRedirect: any

	currency: any
	currencySymbol: any

	constructor(
		private localStorage: LocalStorageService,
		private enviornmentService: EnvironmentService,
		private prismicService: PrismicService,
		private utilityService: UtilityService
	) {
		this.prismicService.themeObservable.subscribe(theme => {
			if (theme.maintenance) {
				this.maintenance = theme.maintenance
				return
			}
			this.theme = theme

			this.featured = theme.footer_featured_collections
		})
		combineLatest(this.enviornmentService.observableEnvironment, this.enviornmentService.observableCountry).subscribe(async (data: any) => {
			this.current = data[0]
			this.country = data[1]
			if (!this.localStorage.getItem('SELECTED_STORE')) {
				setTimeout(async () => {
					// Handles AUD->NZD redirect *and* AU/NZD -> US redirect
					this.currency = await this.utilityService.getCurrency();
					this.currencySymbol = storeMap.find((store) => store.currency === this.currency)?.currencySymbol;

					this.currencyRedirect = this.getCurrencyRedirectInfo(this.country.country, data[0].country, this.currency)
				}, 2000)
			}
		})
	}

	stayOnThisStore() {
		delete this.currencyRedirect
		this.localStorage.setItem('SELECTED_STORE', this.current.country)
	}

	getCurrencyRedirectInfo(userCountry: string, envCountry: string, currency: string) {
		const currentStore = storeMap.find(store => store.country === envCountry)
		const destinationStore = storeMap.find(store => store.country === userCountry) || storeMap.find(store => store.default)

		if (!currentStore || !destinationStore || currentStore.country === destinationStore.country) {
			return null
		}

		if (currency === destinationStore.currency) {
			return null
		}

		// E.g. on the AU/NZ site, but prompting a currency change
		const sameEnvironment = currentStore.countries.includes(userCountry);

		return {
			currentStore,
			destinationStore,
			sameEnvironment,
		}
	}

	@HostListener('window:resize')
	resize() {
		this.utilityService.resize()
	}
}
