import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { defaultDevEnvironment, environmentAU, hasDefaultDevEnvironment, production, storeMap } from './environments/environment'

// Sentry.init({
// 	dsn: 'https://f1e20345a2a14781be25d87727f78f5b@o1415414.ingest.sentry.io/6756212',
// 	integrations: [
// 		new Sentry.BrowserTracing({
// 			tracingOrigins: ['https://loandcointeriors.com.au/'],
// 			routingInstrumentation: Sentry.routingInstrumentation,
// 		}),
// 		new Sentry.Replay(),
// 	],

// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 0.1,
// })

let hostname = document.location.hostname;
const queryParams = new URLSearchParams(window.location.search);

// This logic supports having the currency set *just* via the presence of 'geoRedirect' in the URL
// (e.g. navigating to the website with the query param in the URL as the first visit). It's an edge
// case but worth supporting I think
if ((hasDefaultDevEnvironment && defaultDevEnvironment.country === 'AU') || hostname?.includes('.au')) {
	if (queryParams.has('geoRedirect')) {
		const validCountry = storeMap.find(store => {
			return store.country === queryParams.get('geoRedirect') &&
			store.url === environmentAU.url
		});

		if (validCountry) {
			localStorage.setItem('currency', validCountry.currency);
		}
	}
}

if (production) {
	enableProdMode()
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err))
