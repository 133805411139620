<ng-container *ngFor="let block of content; let i = index">
	<ng-container [ngSwitch]="block.slice_type">
		<block-hero appear [ngClass]="{ 'mb-5': i > 0 }" [content]="block" [index]="i" *ngSwitchCase="'hero_content'"></block-hero>
		<block-two-texts appear [content]="block" *ngSwitchCase="'two_panel_text'"></block-two-texts>
		<block-ticker class="py-5 mb-5" appear [content]="block.items" *ngSwitchCase="'ticker'"></block-ticker>
		<block-events appear [content]="block" *ngSwitchCase="'featured_events'"></block-events>
		<block-featured appear [content]="block" *ngSwitchCase="'generic_feature'"></block-featured>
		<block-featured-products appear [content]="block" *ngSwitchCase="'featured_products'"></block-featured-products>
		<block-featured-posts appear [content]="block" *ngSwitchCase="'featured_posts'"></block-featured-posts>
		<block-usp appear *ngSwitchCase="'usp'"></block-usp>
		<block-collection-slider class="mb-5" appear [content]="block" [updateSEO]="false" *ngSwitchCase="'product_grid'" id="productGrid"></block-collection-slider>
		<block-instagram appear *ngSwitchCase="'instagram'"></block-instagram>
		<block-content appear [content]="block" *ngSwitchCase="'content'"></block-content>
		<block-contact-form appear [content]="block" *ngSwitchCase="'contact_content'"></block-contact-form>
		<block-locations-content appear [content]="block" *ngSwitchCase="'locations_content'"></block-locations-content>
		<block-returns-content appear [content]="block" *ngSwitchCase="'returns_content'"></block-returns-content>
		<block-faqs appear [content]="block" *ngSwitchCase="'faqs'"></block-faqs>
		<block-our-story appear [content]="block" *ngSwitchCase="'our_story'"></block-our-story>
		<block-finishes appear [content]="block" *ngSwitchCase="'finishes'"></block-finishes>
		<block-stockist-widget [standalone]="true" *ngSwitchCase="'stockist_widget'"></block-stockist-widget>
		<block-tradeform [content]="block" *ngSwitchCase="'tradeform_content'"></block-tradeform>
	</ng-container>
</ng-container>
<app-loader *ngIf="loading"></app-loader>
