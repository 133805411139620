import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SetPasswordGuard {
    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | UrlTree {
        const customerId = route.params['customerId'];
        const resetToken = route.params['resetToken'];

        // Redirect with the parameters as query parameters
        return this.router.createUrlTree(['/accounts/reset'], {
            queryParams: { customerId, resetToken },
        });
    }
}
