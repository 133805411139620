import { DOCUMENT, isPlatformServer } from '@angular/common'
import { Component, ElementRef, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core'
import { EnvironmentService } from 'src/app/services/environment.service'
import { PrismicService } from 'src/app/services/prismic.service'
import { ShopifyService } from 'src/app/services/shopify.service'
import { UtilityService } from 'src/app/services/utility.service'
import { WishlistService } from 'src/app/services/wishlist.service'
import { storeMap } from 'src/environments/environment'
import { fade } from '../../animations/fade.animation'
import { LocalStorageService } from 'src/app/services/localstorage.service'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [fade],
})
export class HeaderComponent implements OnInit, OnChanges {
	@ViewChild('headerEl') headerEl!: ElementRef
	@ViewChild('spacerEl') spacerEl!: ElementRef
	@Input() theme!: any
	@Input() content: any
	menu: any
	window: any
	cart: any
	height: string = '80px'
	_menuActive: boolean = false
	wishlist: any
	selectedStore: any
	stores: any
	isSsr: boolean = false

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
		private utilityService: UtilityService,
		private shopifyService: ShopifyService,
		private prismicService: PrismicService,
		private wishlistService: WishlistService,
		private environmentService: EnvironmentService,
		private renderer: Renderer2,
		private localStorage: LocalStorageService,
		private route: ActivatedRoute,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {}

	async ngOnInit() {
		this.isSsr = isPlatformServer(this.platformId);

		this.window = this.utilityService.getWindow()
		this.environmentService.observableEnvironment.subscribe((data: any) => {
			const currency = this.localStorage.getItem('currency');
			const geoRedirect = this.route.snapshot.queryParams['geoRedirect'];

			if (data.country === "US") {
				this.selectedStore = storeMap.find(store => store.country === 'US');
			} else if (currency) {
				this.selectedStore = storeMap.find(store => store.currency === currency) || storeMap.find(store => store.country === data.country);
			} else if (this.isSsr && geoRedirect) {
				this.selectedStore = storeMap.find(store => store.country === geoRedirect) || storeMap.find(store => store.country === data.country);
			} else {
				this.selectedStore = storeMap.find(store => store.country === data.country);
			}

			this.stores = storeMap

			if (this.window) {
				if (data.country == 'AU') {
					this.utilityService.loadJsScript(this.renderer, 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=TXgzkm')
				} else {
					this.utilityService.loadJsScript(this.renderer, 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RpKqTa')
					try {
						const script = this.document.createElement('script')
						script.innerHTML = `
						!function(f,b,e,v,n,t,s){
							if(f.fbq) return; n=f.fbq=function(){n.callMethod?
							n.callMethod.apply(n,arguments):n.queue.push(arguments)};
							if(!f._fbq) f._fbq=n; n.push=n; n.loaded=!0; n.version='2.0';
							n.queue=[]; t=b.createElement(e); t.async=!0;
							t.src=v; s=b.getElementsByTagName(e)[0];
							s.parentNode.insertBefore(t,s)
						}(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
						fbq('init', '873774897437170');
						fbq('track', 'PageView');
						`
						this.document.head.appendChild(script)

						const noscript = this.document.createElement('noscript')
						const img = this.document.createElement('img')
						img.height = 1
						img.width = 1
						img.style.display = 'none'
						img.src = 'https://www.facebook.com/tr?id=873774897437170&ev=PageView&noscript=1'
						noscript.appendChild(img)
						this.document.body.appendChild(noscript)
					} catch (err) {
						console.log(err)
					}
				}
			}
		})

		this.shopifyService.checkoutObservable.subscribe((data: any) => {
			this.cart = data
		})
		this.prismicService.menuSubject.subscribe(data => {
			this.menu = data
		})
		this.wishlistService.wishlist$.subscribe((data: any) => {
			this.wishlist = data
		})
	}

	redirect() {
		this.environmentService.observableEnvironment.subscribe((environment) => {
			if (environment.country === 'US') {
				this.handleUSEnvironmentRedirect();
			} else if (environment.country === 'AU') {
				this.handleAUEnvironmentRedirect();
			}
		});
	}

	private handleUSEnvironmentRedirect(): void {
		if (this.selectedStore.country !== 'US') {
			this.redirectWithGeoRedirect(false);
		}
	}

	private handleAUEnvironmentRedirect(): void {
		if (this.selectedStore.country !== 'US') {
			this.localStorage.setItem('currency', this.selectedStore.currency);
			this.redirectWithGeoRedirect(true);
		} else {
			this.redirectWithGeoRedirect(false);
		}
	}

	private redirectWithGeoRedirect(useCurrentUrl: boolean): void {
		const currentUrl = new URL(window.location.href);
		const searchParams = currentUrl.searchParams;
		searchParams.set('geoRedirect', this.selectedStore.country);
		currentUrl.search = searchParams.toString();

		if (!useCurrentUrl) {
			const selectedStoreUrl = new URL(this.selectedStore.url);
			currentUrl.host = selectedStoreUrl.host;
			currentUrl.port = selectedStoreUrl.port;
		}

		location.href = currentUrl.href;
	}

	close() {
		this.utilityService.closeOverlay()
	}

	ngOnChanges() {
		this.theme.messages = this.ensureArrayLength(this.theme.messages)
	}

	ensureArrayLength(arr: any) {
		while (arr.length < 3) {
			arr.push(arr[0])
		}
		return arr.slice(0, 3)
	}

	@HostListener('window:resize')
	resize() {
		if (this.headerEl) {
			let height = this.headerEl.nativeElement.offsetHeight
			this.height = height + 'px'
			if (this.spacerEl) {
				this.spacerEl.nativeElement.style.height = this.height
			}
			this.utilityService.headerStateSubject.next(height)
		}
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.resize()
		}, 30)
	}

	popCart($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
		this.utilityService.cartStateSubject.next(true)
	}
}
