import { Injectable } from '@angular/core';
import { RouteStorageService } from './services/routestorage.service';
import {
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
    UrlSegment,
} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppRoutingReuseStrategy implements RouteReuseStrategy {
    constructor(private routeStorageService: RouteStorageService) {}

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // console.log('should detach', route.data.reuseRoute)
        return route.data.reuseRoute || false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const id = this.createIdentifier(route);
        if (route.data.reuseRoute) {
            this.routeStorageService.storeHandle(id, handle);
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const id = this.createIdentifier(route);
        const handle = this.routeStorageService.retrieveHandle(id);
        const canAttach = !!route.routeConfig && !!handle;
        return canAttach;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const id = this.createIdentifier(route);

        if (!route.routeConfig || !this.routeStorageService.hasHandle(id))
            return false;
        return this.routeStorageService.retrieveHandle(id)!;
    }

    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot,
    ): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    private createIdentifier(route: ActivatedRouteSnapshot) {
        // Build the complete path from the root to the input route
        const segments: UrlSegment[][] = route.pathFromRoot.map((r) => r.url);
        const subpaths = ([] as UrlSegment[])
            .concat(...segments)
            .map((segment) => segment.path);
        // Result: ${route_depth}-${path}

        return segments.length + '-' + subpaths.join('/');
    }
}
