<ng-container *ngIf="currencyRedirect?.destinationStore?.url">
	<div class="geolocator" appear>
		<div class="container py-4">
			<div class="row">
				<div class="col-12 col-md-6 d-flex flex-column justify-content-center">
					<h3>Visiting from {{ country?.name }}?</h3>
					<p class="mb-0">
						@if (currencyRedirect?.sameEnvironment) {
							<ng-container *ngIf="country?.country == 'AU'"
								>Switch to using Australian currency (AUD) for a tailored shopping experience.</ng-container
							>
							<ng-container *ngIf="country?.country == 'NZ'"
								>Switch to using New Zealand currency (NZD) for a tailored shopping experience.</ng-container
							>
						} @else {
							<ng-container *ngIf="country?.name == 'Australia'"
								>Switch to our Australian site for a tailored shopping experience.</ng-container
							>
							<ng-container *ngIf="country?.name == 'New Zealand'"
							>Switch to our New Zealand site for a tailored shopping experience.</ng-container>
							<ng-container *ngIf="country?.name != 'Australia' && country?.name != 'New Zealand'"
								>Switch to our global site to shop outside Australia and New Zealand.</ng-container
							>
						}
					</p>
				</div>
				<div class="col-12 col-md-6">
					<div class="mt-4 mt-md-0 d-flex justify-content-md-end">
						<div class="d-flex flex-column">
							@if (currencyRedirect?.sameEnvironment) {
							<button (click)="stayOnThisStore()" class="btn btn-lg btn-secondary my-2">Continue using {{ currencySymbol }}{{ currency }}</button>
							<a [href]="currencyRedirect?.destinationStore.url + '?geoRedirect=' + currencyRedirect.destinationStore.country" class="btn btn-lg btn-primary my-2"
								>Switch to {{ currencyRedirect?.destinationStore.currencySymbol }}{{ currencyRedirect?.destinationStore.currency }}
							</a>
							} @else {
								<button (click)="stayOnThisStore()" class="btn btn-lg btn-secondary my-2">Stay on Lo & Co {{ current?.name }}</button>
								<a [href]="currencyRedirect?.destinationStore.url + '?geoRedirect=' + currencyRedirect.destinationStore.country" class="btn btn-lg btn-primary my-2"
									>Go to Lo & Co {{ currencyRedirect?.destinationStore.name }}</a
								>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bg" appear></div>
</ng-container>

<ng-container *ngIf="!maintenance && theme?.body">
	<div class="page d-flex flex-column">
		<app-header [theme]="theme"></app-header>

		<app-mobile-menu [theme]="theme"></app-mobile-menu>
		<router-outlet></router-outlet>
	</div>
	<app-footer [footer]="theme.body"></app-footer>
	<app-mini-cart></app-mini-cart>
</ng-container>
<app-maintenance *ngIf="maintenance"></app-maintenance>
