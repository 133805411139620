import { animate, animateChild, query, sequence, stagger, style, transition, trigger } from '@angular/animations'
import { Component, HostListener, Input, OnInit } from '@angular/core'
import { UtilityService } from '../../../services/utility.service'

@Component({
	selector: 'desktop-menu',
	templateUrl: './desktop-menu.component.html',
	styleUrls: ['./desktop-menu.component.scss'],
	animations: [
		trigger('megaMenu', [
			transition(':enter', [
				query('.mega-menu', [
					style({ opacity: 0, marginTop: '-300px' }),
					animate('300ms cubic-bezier(0.35,0,0.25,1)', style({ opacity: 1, marginTop: '0px' })),
					query('@menuLists', animateChild()),
				]),
			]),
			transition(':leave', [
				sequence([
					query('.staggerlink', [
						style({ opacity: 1, transform: 'translateY(0px)' }),
						stagger(-150, [animate('150ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 0, transform: '-30px' }))]),
					]),
					query('.mega-menu', [style({ opacity: 0, marginTop: '0px' }), animate('600ms cubic-bezier(0.35,0,0.25,1)', style({ opacity: 1, marginTop: '-300px' }))]),
				]),
			]),
		]),
		trigger('menuLists', [
			transition(':enter', [
				query('.childlink', [
					style({ opacity: 0 }),
					stagger(150, [animate('150ms cubic-bezier(0.35,0,0.25,1)', style({ opacity: 1 })), query('@linkList', animateChild())]),
				]),
			]),
		]),
		trigger('linkList', [
			transition(':enter', [
				query('.staggerlink', [
					style({ opacity: 0, transform: 'translateY(30px)' }),
					stagger(150, [animate('150ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))]),
				]),
			]),
		]),
	],
})
export class DesktopMenuComponent implements OnInit {
	open: boolean = false
	selectedImage: any
	@Input() menuItems: any
	selectedLink: any = null
	constructor(private utilityService: UtilityService) {}

	ngOnInit() {}

	hideSearch() {
		this.utilityService.hideSearch()
	}

	clickedInside($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
	}

	@HostListener('window:resize')
	@HostListener('document:click')
	clickedOutside() {
		if (this.open) {
			this.menuClose()
		}
	}

	@HostListener('mouseleave')
	menuClose() {
		delete this.selectedLink
		this.open = false
	}

	menuOpen(link: any) {
		this.menuClose()
		this.selectedLink = link
		link.active = true
		this.open = true
		this.utilityService.hideSearch()
	}
}
