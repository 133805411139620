import { Component, Input } from '@angular/core'
import { ImageFieldImage } from '@prismicio/client'

@Component({
	selector: 'prismic-image',
	templateUrl: './prismic-image.component.html',
	styleUrls: ['./prismic-image.component.scss'],
})
export class PrismicImageComponent {
	@Input() image!: ImageFieldImage
	@Input() width?: any
	@Input() height?: any
	@Input() classes!: string
	constructor() {}
}
