// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { AllowedAddresses } from './allowedAddresses'
import { SEO as SEOAU } from './seo.au'
import { SEO as SEOUS } from './seo.us'

export const environmentAU = {
	name: 'AUS & NZ',

	apiUrl: 'https://shop.loandcointeriors.com.au/',
	url: 'https://loandcointeriors.com.au/',
	afterpay: false,
	allowedAddresses: AllowedAddresses,
	bypassToken: '78f37911-4eae-4181-849a-a2f866a4dcb6',
	cache: false,
	headlessCache: true,
	cartCount: {
		dot: false,
		counter: false,
	},
	externalScripts: [
		{ name: 'masonry', src: '//unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js' },
		{ name: 'lottie', src: 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.6/lottie.min.js' },
	],
	currency: 'AUD',
	debugHttp: false,
	debugLiquid: false,
	defaultImg: '',
	filePath: 't/21/assets',
	instagram: '',
	minicart: true,
	responsiveHelper: false,
	storefrontKey: '12471841f778c1717ab1d58f4f8614fa',
	wishlistId: 'adc510d2-f194-49a6-a253-c2e7ebd3f982', // dev site
	lang: 'en-gb',
	freeshipping: true,
	redirectTo: 'https://loandcointeriors.com/',
	countries: ['AU', 'NZ'],
	country: 'AU',
	...SEOAU,
	contactForm: 'xleyjawj',
	production: false,
}

export const environmentUS = {
	name: 'Global',
	contactForm: 'mdorbyjb',
	apiUrl: 'https://shop.loandcointeriors.com/',
	url: 'https://loandcointeriors.com/',
	afterpay: false,
	allowedAddresses: AllowedAddresses,
	bypassToken: 'a7d0f0a1-6161-4dfa-b034-e10a4a884be8',
	cache: false,
	headlessCache: true,
	cartCount: {
		dot: false,
		counter: false,
	},
	externalScripts: [
		{ name: 'masonry', src: '//unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js' },
		{ name: 'lottie', src: 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.6/lottie.min.js' },
	],
	currency: 'USD',
	debugHttp: false,
	debugLiquid: false,
	defaultImg: '',
	filePath: 't/21/assets',
	instagram: '',
	minicart: true,
	responsiveHelper: false,
	storefrontKey: '4cdbb3d288135e2ce7b02a5c8546a75e',
	wishlistId: '74ae394e-a46f-4e03-a9fb-156e56d754cf', // dev site.
	lang: 'en-us',
	countries: [],
	country: 'US',
	freeshipping: false,
	...SEOUS,
	production: false,
}

export type Environment = typeof environmentAU & typeof environmentUS;

export const storeMap = [
	{ country: 'AU', currency: 'AUD', currencySymbol: '$', name: 'Australia', freeShipping: environmentAU.freeshipping, url: environmentAU.url, countries: environmentAU.countries },
	{ country: 'NZ', currency: 'NZD', currencySymbol: '$', name: 'New Zealand', freeShipping: false, url: environmentAU.url, countries: environmentAU.countries },
	{ country: 'US', currency: 'USD', currencySymbol: '$', name: 'Global', freeShipping: environmentUS.freeshipping, url: environmentUS.url, countries: environmentUS.countries as string[], default: true },
]

export const googleMapsApiKey = 'AIzaSyC3vsFOeL7quedOZ1OQQmYPIkdOGkL9M_4';

export const production = false
export const hasDefaultDevEnvironment = false
export const defaultDevEnvironment = environmentAU
