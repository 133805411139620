import { Component, EventEmitter, Input, Output } from '@angular/core'
import { EnvironmentService } from 'src/app/services/environment.service'
import { Router } from '@angular/router'
import { UtilityService } from 'src/app/services/utility.service'
import { first } from 'rxjs/operators'

@Component({
	selector: 'app-collection-item',
	templateUrl: './collection-item.component.html',
	styleUrls: ['./collection-item.component.scss'],
})
export class CollectionItemComponent {
	related: any = []
	current: any = 0
	country: any
	currencyCode?: string;
	environmentCurrencyCode?: string;
	@Input() product: any
	@Input() slideshow: boolean = false
	@Input() displayAddToCart: boolean = false
	@Input() removeFromWishlist: boolean = false
	@Output() removeItem = new EventEmitter<any>()
	constructor(private router: Router, private environmentService: EnvironmentService, private utilityService: UtilityService) {
		this.environmentService.observableCountry.subscribe((country: any) => {
			this.country = country
		})

	}

	async ngOnInit() {
		this.currencyCode = await this.utilityService.getCurrency()

		this.environmentService.observableEnvironment
            .pipe(first())
            .subscribe((environment) => {
                this.environmentCurrencyCode = environment.currency;
            });

		try {
			this.related = JSON.parse(this.product.related || this.product.metafields.PRP.related)
		} catch (err) {}

		this.related.sort((a: any, b: any) => (a.handle > b.handle ? 1 : -1))
	}

	remove(event: any) {
		this.removeItem.emit(true)
	}

	navigate(handle: any) {
		this.router.navigate(['/products/' + handle])
	}

	clickedInside($event: Event) {
		$event.preventDefault()
		$event.stopPropagation()
	}

	removingItem(){
		this.removeItem.emit('removing')
	}

	options = {
		0: { slides: 1, nav: true },
	}
}
