import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    UrlTree,
    Router,
} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ShopifyLoginGuard {
    constructor(private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
    ): boolean | UrlTree {
        const checkoutUrl = route.queryParams['checkout_url'] ?? false;

        // Redirect with the parameters as query parameters
        return this.router.createUrlTree(['/accounts/login'], {
            queryParams: { checkout_url: checkoutUrl },
        });
    }
}
