import { Component, HostListener } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
	selector: 'app-maintenance',
	templateUrl: './maintenance.component.html',
	styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
	online!: boolean
	constructor(private utilityService: UtilityService) {
		this.online = (this.utilityService.getWindow() as any).navigator.onLine || false
	}

	@HostListener('window.online')
	browserOnline() {
		this.online = true
	}

	@HostListener('window.offline')
	browserOffline() {
		this.online = false
	}
}
