import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Unsub } from 'src/app/common/unsub.class';
import { CustomerStoreService } from 'src/app/services/customer.store';
import { PrismicService } from 'src/app/services/prismic.service';
import { ShopifyService } from 'src/app/services/shopify.service';

@Component({
  selector: 'app-account-reset-password',
  templateUrl: './account-reset-password.component.html',
  styleUrls: ['./account-reset-password.component.scss'],
})

export class AccountResetPasswordComponent extends Unsub implements OnInit {
	resetForm: FormGroup;
	success: 'clean' | 'failed' | 'success' = 'clean';
	error: any;
	settings: any;
	accountInfo = { firstName: '', name: '', email: '' }
	loading: boolean = true;
	mode: 'default' | 'change-password' = 'default';

	pageModeData = {
		'default': {
			title: 'Forgot Password',
			subtitle: 'Fill in your email address below to reset your password',
			buttonText: 'Reset',
			linkText: 'Back to login',
			linkUrl: '/accounts/login',
			setInput: false,
			readOnlyInput: false,
		},
		'change-password': {
			title: 'Change Password',
			subtitle: 'Click the reset button to change your password. You will be sent an email with a link to reset your password.',
			buttonText: 'Reset',
			linkText: 'Back to account',
			linkUrl: '/accounts/dashboard',
			setInput: true,
			readOnlyInput: true,
		},
	};

	constructor(
		private route: ActivatedRoute,
		private shopifyService: ShopifyService,
		private prismicService: PrismicService,
		private formBuilder: FormBuilder,
		private customerStoreService: CustomerStoreService,
	) {
		super();

		this.prismicService.getAccountSettings().then((data) => {
			this.settings = data;
		});

		this.resetForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.minLength(8), Validators.email]],
		});
	}

	ngOnInit(): void {
		this.loading = true;

		// Load customer data if not initialized
		if (!this.customerStoreService.getInitializedState()) {
			this.customerStoreService.loadCustomerData();
		}

		this.route.data.subscribe((data: any) => {
			if (data?.mode) {
				this.mode = data.mode;

				this.customerStoreService.customerData$
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe((customerData: any) => {
						if (!customerData) {
							return;
						}

						this.accountInfo = {
							firstName: customerData.firstName,
							name: `${customerData.firstName} ${customerData.lastName}`,
							email: customerData.email
						};

						if (this.pageModeData[this.mode]?.setInput) {
							this.resetForm.get('email')?.setValue(customerData.email);
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}
		});
	}


	get isFormValid(): boolean {
		return this.resetForm.invalid && this.resetForm.touched
	}

	get formControls() {
		return this.resetForm.controls;
	}

	showValidation(controlName: string): boolean {
		const control = this.formControls[controlName];
		return control.invalid && control.dirty;
	}

	submit() {
	if (this.resetForm.valid && !this.loading) {
		this.loading = true;
		const { email } = this.resetForm.value;

		interface CustomerRecoverResponse {
			customerRecover: CustomerRecover | null;
			errors?: CustomerRecoverResponseErrors[];
		}

		interface CustomerRecover {
			customerUserErrors?: CustomerUserError[];
		}

		interface CustomerUserError {
			code:    string;
			field:   string[];
			message: string;
		}

		interface CustomerRecoverResponseErrors {
			message: string;
			path: string[];
			locations: string[];
		}

		this.shopifyService.customerRecover({ email }).subscribe({
			next: (response: any) => {
				const res = response as CustomerRecoverResponse; // lol
				if (res.customerRecover && res.customerRecover.customerUserErrors?.length) {
					this.success = 'failed';
					console.log(res.customerRecover.customerUserErrors)
					this.error = res.customerRecover.customerUserErrors[0].message;
				} else {
					this.success = 'success';
				}

				this.loading = false;
			},
			error: (error) => {
				const err = error as { response: CustomerRecoverResponse };
				if (err.response.errors && err.response.errors.length) {
					this.success = 'failed';
					this.error = err.response.errors[0].message;
				}

				this.loading = false;
			}
		});
	}
	}
}
