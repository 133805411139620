import { Component, HostListener, Renderer2 } from '@angular/core'
import { combineLatest } from 'rxjs'
import { Product } from 'src/app/classes/product'
import { PrismicService } from '../../../services/prismic.service'
import { ShopifyService } from '../../../services/shopify.service'
import { UtilityService } from '../../../services/utility.service'
import { fade } from '../../animations/fade.animation'
@Component({
	selector: 'app-mini-cart',
	templateUrl: './mini-cart.component.html',
	styleUrls: ['./mini-cart.component.scss'],
	animations: [fade],
})
export class MiniCartComponent {
	_active: boolean = false
	_loading: boolean = true
	_upsells: Product[] | undefined
	cart: any
	loading: boolean = false
	windowWidth: number = 0
	offsetHeight: number = 62
	window: any
	checkoutUrl: string = ''

	constructor(private utilityService: UtilityService, private shopifyService: ShopifyService, private prismicService: PrismicService, private renderer: Renderer2) {
		this._loading = true
		this.window = this.utilityService.getWindow()
		if (this.utilityService.isBrowser()) {
			combineLatest([this.shopifyService.getCheckout(), this.utilityService.cartStateObservable, this.prismicService.themeObservable]).subscribe(async (data: any) => {
				if (data[0].state == 'loading') {
					this._loading = true
					return
				}
				this.cart = data[0]
				this._active = data[1]
				this._loading = false
				this.renderer.setStyle(document.body, 'overflow', data[1] ? 'hidden' : 'visible')

				this.checkoutUrl = this.shopifyService.getCheckoutLink()
			})
		}
	}

	@HostListener('window:resize')
	onResize() {
		if (this.utilityService.isBrowser()) {
			this.windowWidth = window.innerWidth
		}
	}

	hideCart() {
		this.utilityService.cartStateSubject.next(false)
	}

	clickedInside($event: any) {
		if (this._active && $event.target.tagName !== 'A') {
			$event.preventDefault()
			$event.stopPropagation()
		}
	}

	remove(item: any) {
		this.shopifyService.removeFromCart(item.lineId)
	}

	gotoCheckout() {
		if (this.utilityService.isBrowser()) {
			this.shopifyService.goToCheckout()
		}
	}
}
