import { Component, OnInit } from '@angular/core'

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
	constructor() {}
	details = {
		name: 'Ned Morris',
		email: 'ned.morris89@gmail.com',
		address: '3/91 Alma St, West Footscray, VIC, 3012 Australia',
	}

	ngOnInit(): void {}
}
