<div class="relative">
	<app-wishlist-toggle (click)="clickedInside($event)" class="wishlist-toggle actionable" [style]="'inline'" [product]="product"></app-wishlist-toggle>

	<div class="wishlist-toggle actionable" *ngIf="removeFromWishlist"
	(click)="removingItem()"
	directiveRemoveFromWishlist
	(emitInWishlist)="remove($event)"
	[product]="product">
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="12" r="11.5" fill="white" stroke="#EDEDED" />
			<path
				d="M8.60841 7.00031C7.6827 7.00031 6.75734 7.35136 6.05415 8.05732C4.64783 9.46914 4.6494 11.7372 6.05415 13.1502L11.776 18.9108H11.7761C11.8334 18.9678 11.9124 19 11.9948 19C12.0773 19 12.1563 18.9678 12.2136 18.9108C14.1238 16.9932 16.0348 15.0769 17.9453 13.1591C19.3516 11.7473 19.3516 9.47814 17.9453 8.06621C16.5389 6.65439 14.2431 6.65439 12.8369 8.06621L12.0045 8.90351L11.1625 8.05701C10.4594 7.35105 9.534 7 8.60829 7L8.60841 7.00031Z"
				fill="black"
			/>
		</svg>
	</div>
	<div class="actionable w-100 position-relative" [routerLink]="['/products/' + product.handle]">
		@if (product.tags.includes('Label~Sale')) {
			<div class="sale-label position-absolute">Sale</div>
		}
		<img
			width="500"
			height="500"
			*ngIf="!slideshow || product.images.length == 1"
			[src]="product.image.src || product.image | ShopifyImageUrl: 500:500:'center'"
			[srcset]="product.image.src || product.image | ShopifySrcSet: 500:500:'center'"
			class="w-100 h-100 cover first"
		/>
		<app-carousel [options]="options" *ngIf="slideshow && product.images.length > 1">
			<div *ngFor="let image of product.images">
				<img
					width="500"
					height="500"
					[src]="image.src || image | ShopifyImageUrl: 500:500:'center'"
					[srcset]="image.src || image | ShopifySrcSet: 500:500:'center'"
					class="w-100 h-100 cover first"
				/>
			</div>
		</app-carousel>
		<ng-container *ngTemplateOutlet="bottom; context: { product: product }"></ng-container>
	</div>

	<ng-template #bottom>
		<div class="row">
			<div class="col-sm d-flex pt-2">
				<a class="title text-uppercase" [routerLink]="['/products/' + product.handle]">{{ product.title }}</a>
			</div>

			<div class="col-xl col-12 pt-1 justify-content-xl-end justify-content-start align-items-center d-flex">
				@for (variant of related; track variant.id; let first = $first) {
					@if (!variant.hidden) {
						<a
							*ngIf="variant.color"
							(click)="navigate(variant.handle)"
							[style.background-color]="variant.color"
							[ngClass]="{ 'ms-md-2': !first }"
							class="me-2 me-md-0 d-block circle bg-{{ variant.color }}"
						></a>
					}
				}
			</div>

			<div class="col-12 order-md-2 order-1">
				<ng-container *ngIf="!product.compare_at_min_price">
					@if (product.prices !== undefined) {
						<!-- I.e. the modern Mercury endpoints (search/collection) which have a 'prices' object for currency conversions -->
						<div *ngIf="currencyCode && environmentCurrencyCode" class="price">
							<!-- Overly complicated, but it seems with NZD sometimes product.prices[currencyCode] starts as uninitalised -->
							{{ (currencyCode !== environmentCurrencyCode ? (product.prices[currencyCode] ? product.prices[currencyCode].price : product.min_price) : product.min_price) | currency:currencyCode }}
						</div>
					}
					@else if (product) {
						<!-- I.e. the older /api/shopify Mercury endpoints, where min_price has trusted currency conversion already -->
						<div class="price">{{ product.min_price | currency:currencyCode }}</div>
					}
				</ng-container>
				<ng-container *ngIf="product.compare_at_min_price">
					<div class="price pricevoid">{{ product.compare_at_min_price | currency }}</div>
					<div class="price sale">{{ product.min_price | currency:currencyCode }}</div>
				</ng-container>
			</div>

			<div class="col-12 order-3 pt-2 mt-auto" *ngIf="displayAddToCart">
				<app-add-to-cart @fade *ngIf="product" [showWishlistToggle]="false" [product]="product" [selectedVariant]="product.variants[0]" [quantity]="1"> </app-add-to-cart>
			</div>
		</div>
	</ng-template>
</div>
