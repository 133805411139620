import { Component, OnInit } from '@angular/core'
import { MailingAddress, MailingAddressInput, ShopifyService } from 'src/app/services/shopify.service'
import { AllowedAddresses } from '../../../environments/allowedAddresses'

const mockCustomerDetails = {
	customer: {
	  email: 'john.doe@example.com',
	  firstName: 'John',
	  lastName: 'Doe',
	  id: '123456789',
	  defaultAddress: {
		address1: '123 Main St',
		city: 'Anytown',
		country: 'United States',
		firstName: 'John',
		lastName: 'Doe',
		// ... other default address fields ...
	  },
	  addresses: {
		edges: [
		  {
			node: {
			  address1: '456 Secondary St',
			  city: 'Sometown',
			  country: 'United States',
			  firstName: 'Jane',
			  lastName: 'Doe',
			  // ... other address fields ...
			},
		  },
		  // ... additional address nodes ...
		],
	  },
	  orders: {
		edges: [
		  {
			node: {
			  id: 'order_123',
			  orderNumber: 'ORD123',
			  fulfillmentStatus: 'FULFILLED',
			  totalPriceV2: {
				amount: '100.00',
			  },
			  processedAt: '2023-01-01T12:34:56Z',
			},
		  },
		  // ... additional order nodes ...
		],
		pageInfo: {
		  hasPreviousPage: false,
		  hasNextPage: false,
		},
	  },
	},
  };

@Component({
	selector: 'app-addresses',
	templateUrl: './addresses.component.html',
	styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
	loading: boolean = true
	addresses: any
	provinces!: string[]
	editAddress: any
	newAddress: any
	default: any
	countries: any
	allowed: any
	constructor(private shopifyService: ShopifyService) {
		this.allowed = AllowedAddresses.map(x => {
			return { value: x.readable }
		})
	}

	ngOnInit(): void {
		this.loading = true
		this.shopifyService.customerDetails().subscribe(
			(customerData: any) => {
				// this.addresses = customerData.customer.addresses.edges.map((x: any) => x.node)
				console.log('customerData: ', customerData)
				console.log('this.addresses: ', this.addresses)
				this.addresses = mockCustomerDetails.customer.addresses.edges.map((x: any) => x.node)
			},
			err => {
				console.error('Error fetching customer data', err)
			},
			() => {
				this.loading = false
			}
		)
	}

	selectAddress(address: any) {
		this.editAddress = address
		this.selectCountryEvent('Australia')
	}

	selectCountryEvent(value: any) {
		const country = this.allowed.find((x: any) => x.readable == value)
		if (country && country.provinces && country.provinces.length > 0) {
			this.provinces = country.provinces.map((x: any) => {
				return x[0]
			})
			this.editAddress.province = this.provinces[0]
		} else {
			this.editAddress.province = ''
		}
	}

	async updateAddress(address: MailingAddress) {
		let result: any
		let newAddress: MailingAddressInput = {
			address1: address.address1,
			address2: address.address2,
			city: address.city,
			company: address.company,
			country: address.country,
			firstName: address.firstName,
			lastName: address.lastName,
			phone: address.phone,
			province: address.province,
			zip: address.zip,
		}

		if (this.newAddress) {
			// One can only update a default address. 
			// We require an address.id to update default address, which we don't have when we create a new address.
			// So, when we have a new address that is to be the default, we need to await the returned ID of the new address
			// then make a second call to update to default.
			result = await this.shopifyService.customerAddressCreate(newAddress).toPromise()
			if (result && this.default) {
				await this.shopifyService.customerDefaultAddressUpdate(result.data.customerAddressCreate.customerAddress.id).toPromise()
			}
		} else {
			result = await this.shopifyService.customerAddressUpdate(address.id, newAddress).toPromise()
			if (this.default) {
				await this.shopifyService.customerDefaultAddressUpdate(address.id).toPromise()
			}
		}

		this.loading = true
		this.editAddress = null
		this.newAddress = false
	}
}
