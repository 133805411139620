import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'ShopifyImageUrl',
})
export class ShopifyImagePipe implements PipeTransform {
	constructor() {}
	transform(value: string, height: number, width: number, crop?: string): string {
		return this.processCropUrl(this.processSizeUrl(value, width, height), crop)
	}

	processCropUrl(url: string, crop: string | undefined) {
		if (crop) {
			return this.replaceLast(url, '.', '_crop_' + crop + '.')
		}
		return url
	}

	processSizeUrl(url: string, height: number, width: number) {
		if (url) {
			url = this.replaceLast(url, '.', '_' + height + 'x' + width + '.')
		}
		return url
	}

	replaceLast(string: string, substring: string, replacement: string) {
		if (string == undefined) {
			return string
		}
		const index: number = string.lastIndexOf(substring)
		if (index == -1) {
			return string
		}
		return string.substring(0, index) + replacement + string.substring(index + substring.length)
	}
}

@Pipe({
	name: 'ShopifySrcSet',
})
export class ShopifySrcSetPipe implements PipeTransform {
	constructor() {}
	transform(value: string, height: number, width: number, crop?: string): string {
		let values = []
		for (let x of [1, 1.5]) {
			values.push(this.processCropUrl(this.processSizeUrl(value, width * x, height * x), crop) + ' ' + x + 'x')
		}
		return values.join(', ')
	}

	processCropUrl(url: string, crop: string | undefined) {
		if (crop) {
			return this.replaceLast(url, '.', '_crop_' + crop + '.')
		}
		return url
	}

	processSizeUrl(url: string, height: number, width: number) {
		if (url) {
			url = this.replaceLast(url, '.', '_' + height + 'x' + width + '.')
		}
		return url
	}

	replaceLast(string: string, substring: string, replacement: string) {
		if (string == undefined) {
			return string
		}
		const index: number = string.lastIndexOf(substring)
		if (index == -1) {
			return string
		}
		return string.substring(0, index) + replacement + string.substring(index + substring.length)
	}
}
