import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PrismicService } from 'src/app/services/prismic.service'

@Component({
	selector: 'app-flexible-content',
	templateUrl: './flexible-content.component.html',
	styleUrls: ['./flexible-content.component.scss'],
	animations: [
		trigger('flyInOut', [
			state('in', style({ opacity: 1, transform: 'translateY(0)' })),
			transition('void => *', [style({ opacity: 0, transform: 'translateY(100%)' }), animate(200)]),
			transition('* => void', [animate(200, style({ opacity: 0, transform: 'translateY(100%)' }))]),
		]),
	],
})
export class FlexibleContentComponent implements OnInit {
	@Input() uid: string = ''
	@Input() silentError: boolean = true
	content: any = []
	loading: boolean = true

	constructor(private route: ActivatedRoute, private prismicService: PrismicService) {}

	ngOnInit(): void {
		if (this.uid) {
			this.prismicService
				.getPage(this.uid, false)
				.then(data => {
					this.content = data.content.data.body_html
					this.loading = false
				})
				.catch(err => {
					if (this.silentError) {
						console.log('error')
					}
				})
		} else {
			this.route.data.subscribe((data: any) => {
				this.content = data.content?.data?.body ? data.content.data.body : []
				this.loading = false
			})
		}
	}
}
