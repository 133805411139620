import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router'
import { PrismicService } from 'src/app/services/prismic.service'

@Injectable({ providedIn: 'root' })
export class FlexibleContentResolver implements Resolve<any> {
	constructor(private prismicService: PrismicService, private router: Router, private route: ActivatedRoute) {}

	async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const handle = route.paramMap.get('handle') as string
		let uid = handle || 'home'
		return await this.prismicService.getPage(uid, true).catch(err => {
			console.log(err)
			this.router.navigate(['/not-found'])
		})
	}
}
