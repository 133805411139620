<table class="table table-bordered w-100 d-none d-md-block">
	<thead>
		<tr>
			<th scope="col d-flex "><span class="px-md-5 py-3">Order</span></th>
			<th scope="col d-flex  "><span class="px-md-5 py-3">Date</span></th>
			<th scope="col d-flex  "><span class="px-md-5 py-3">Payment status</span></th>
			<th scope="col d-flex  "><span class="px-md-5 py-3">Fulfillment status</span></th>
			<th scope="col d-flex  "><span class="px-md-5 py-3">Tracking number</span></th>
			<th scope="col d-flex  "><span class="px-md-5 py-3">Total</span></th>
			<th scope="col d-flex  "><span class="px-md-5 py-3">Reorder</span></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let order of orders">
			<th scope="row">
				<a class="text-decoration-underline p-3">{{ order.orderNumber }}</a>
			</th>

			<td>
				<h6 class="p-3">{{ order.processedAt | date: 'longDate' }}</h6>
			</td>
			<td>
				<h6 class="p-3">{{ order.paymentStatus }}</h6>
			</td>
			<td>
				<h6 class="p-3">{{ order.fulfillmentStatus }}</h6>
			</td>
			<td>
				<h6 class="p-3">{{ order.trackingNumber }}</h6>
			</td>
			<td>
				<h6 class="p-3">{{ order.totalPrice | currency: 'NZD' }}</h6>
			</td>
			<td>
				<button class="btn btn-sm py-2 px-5 btn-primary text-uppercase">{{ order.reOrder }}</button>
			</td>
		</tr>
	</tbody>
</table>
