import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { takeUntil } from 'rxjs/operators'
import { emailValidator } from 'src/app/common/email.validator'
import { Unsub } from 'src/app/common/unsub.class'
import { PrismicService } from 'src/app/services/prismic.service'
import { ShopifyService } from 'src/app/services/shopify.service'

@Component({
	selector: 'app-account-register',
	templateUrl: './account-register.component.html',
	styleUrls: ['./account-register.component.scss'],
})
export class AccountRegisterComponent extends Unsub implements OnInit {
	error?: any;
	errorMessage?: string;
	loading: boolean = false;
	success: boolean = false;
	settings: any;

	registerForm!: FormGroup;

	constructor(
		private shopifyService: ShopifyService,
		private prismicService: PrismicService,
		private formBuilder: FormBuilder
	) {
		super();
	}

	get isFormValid(): boolean {
		return this.registerForm.invalid && this.registerForm.touched
	}

	get formControls() {
		return this.registerForm.controls;
	}

	showValidation(controlName: string): boolean {
		const control = this.formControls[controlName];
		return control.invalid && control.touched;
	}

	ngOnInit(): void {
		this.prismicService.getAccountSettings().then((data) => {
		  this.settings = data;
		});
	  
		this.registerForm = this.formBuilder.group({
		  firstName: ['', [Validators.required, Validators.minLength(2)]],
		  lastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]],
		  email: ['', [Validators.required, Validators.minLength(8), emailValidator()]],
		  password: ['', [Validators.required, Validators.minLength(8)]],
		});
	}
	  
	submit() {
		this.loading = true;
	  
		if (this.registerForm.valid) {
		  this.shopifyService.customerCreate(this.registerForm.value)
			.pipe(
			  takeUntil(this.unsubscribe$)
			)
			.subscribe(
			  (response: any) => {
				this.loading = false;
				if (response.customerCreate.userErrors.length > 0) {
					if (response.customerCreate.userErrors[0].message.includes('We have sent an email')) {
						this.success = true
					} else {
						this.errorMessage = response.customerCreate.userErrors[0].message
					}
				} else if (response.customerCreate.customer) {
					sessionStorage.setItem('userId', response.customerCreate.customer.id)
					this.success = true
				}
			  },
			  (err: Error) => {
				this.errorMessage = JSON.parse(err.message);
			  }
			);
		}
	}

	  
}
