import { Injectable } from '@angular/core'
import * as Prismic from '@prismicio/client'
import * as prismicH from '@prismicio/client'
import { PrismicDocument } from '@prismicio/client'
import { Observable, ReplaySubject } from 'rxjs'
import { production } from '../../environments/environment'
import { EnvironmentService } from './environment.service'
import { AsyncApiCallHelperService } from './macrotask.service'
import { SeoService } from './seo.service'
import { PrismicSEOImage } from '../classes/prismic'
var apiEndpoint = 'https://loandco.cdn.prismic.io/api/v2'

@Injectable({
	providedIn: 'root',
})
export class PrismicService {
	client: any
	themeSubject: ReplaySubject<any> = new ReplaySubject<any>(1)
	themeObservable: Observable<any> = this.themeSubject.asObservable()

	menuSubject: ReplaySubject<any> = new ReplaySubject<any>(1)
	menuObservable: Observable<any> = this.menuSubject.asObservable()

	environment: any

	constructor(private seoService: SeoService, private processor: AsyncApiCallHelperService, private environmentService: EnvironmentService) {
		this.environment = this.environmentService.environment
		this.client = Prismic.createClient(apiEndpoint)
	}

	crop(url: string, maxW?: string, maxH?: string, w?: string, h?: string, crop?: string) {
		return url + `${crop ? '&crop=' + crop : ''}${maxW ? '&max-w=' + maxW : ''}${maxH ? '&max-h=' + maxH : ''}${h ? '&h=' + h : ''}${w ? '&w-h=' + w : ''}`
	}

	cropDpr(url: string, maxW?: string, maxH?: string, w?: string, h?: string, crop?: string) {
		let urls: string[] = []
		for (let i = 1; i < 4; i++) {
			urls.push(`${this.crop(url, maxW, maxH, w, h, crop)}&dpr=${i} ${i}x`)
		}
		return urls.join(',')
	}

	linkConverter(link: any) {
		return prismicH.asLink(link, { linkResolver: this.linkResolver });
	}

	async getNextAndPreviousPosts(id: string) {
		const prev = await this.processor
			.doTask(
				this.client.getByType('article', {
					lang: await this.environment.lang,
					pageSize: 1,
					after: id,
					orderings: [{ field: 'document.first_publication_date', direction: 'desc' }],
				})
			)
			.toPromise()
			.then((data: any) => data.results[0])
		const next = await this.processor
			.doTask(
				this.client.getByType('article', {
					lang: await this.environment.lang,
					pageSize: 1,
					after: id,
					orderings: [{ field: 'document.first_publication_date', direction: 'asc' }],
				})
			)
			.toPromise()
			.then((data: any) => data.results[0])
		return { prev, next }
	}

	linkResolver(doc: any) {
		if (doc.type == 'page' && doc.uid == 'home') return '/'
		if (doc.type === 'page') return '/pages/' + doc.uid
		if (doc.type === 'article') return '/blogs/journal/' + doc.uid
		if (doc.type === 'journal') return '/blogs/journal'
		return doc.type + '/' + doc.uid
	}

	async getPage(uid: string, seoContent: boolean = true) {
		return await this.processor
			.doTask(
				this.client.getByUID('page', uid, {
					lang: await this.environment.lang,
				})
			)
			.toPromise()
			.then(async (document: any) => {
				if (seoContent) {
					await this.processPrismicSeo(document)
				}
				return document
			})
			.catch(async err => {})
	}

	async getBlog() {
		return await this.processor
			.doTask(
				this.client.getSingle('journal', {
					lang: await this.environment.lang,
				})
			)
			.toPromise()
			.then(async (document: any) => {
				await this.processPrismicSeo(document)
				return document
			})
	}

	async getPreviewDocument() {
		return await this.processor
			.doTask(
				this.client.resolvePreviewURL({
					linkResolver: this.linkResolver,
					defaultURL: '/',
				})
			)
			.toPromise()
	}

	async getDocumentById(id: string) {
		return await this.processor.doTask(this.client.getByID(id, { lang: await this.environment.lang })).toPromise()
	}

	async getArticles(page = 0) {
		return await this.processor.doTask(this.client.getByType('article', { lang: await this.environment.lang, pageSize: 20, page })).toPromise()
	}

	async getByTag(tag: string, page = 0) {
		return await this.processor
			.doTask(
				this.client.getByTag(tag, {
					page,
					pageSize: 20,
					lang: await this.environment.lang,
					orderings: [{
						field: 'document.first_publication_date',
						direction: 'desc',
					}],
				})
			)
			.toPromise()
	}

	async getArticle(uid: string) {
		return await this.processor
			.doTask(this.client.getByUID('article', uid, { lang: await this.environment.lang }))
			.toPromise()
			.then(async (document: any) => {
				await this.processPrismicSeo(document)
				return document
			})
	}

	async getProductEnrichment(uid: string) {
		return await this.processor.doTask(this.client.getByUID('product_enrichment', uid, { lang: await this.environment.lang })).toPromise()
	}

	async getQuizSettings() {
		return await this.processor.doTask(this.client.getSingle('quiz', { lang: await this.environment.lang })).toPromise()
	}

	async getAccountSettings() {
		return await this.processor.doTask(this.client.getSingle('account', { lang: await this.environment.lang })).toPromise()
	}

	async getThemeSettings() {
		return await this.processor
			.doTask(this.client.getSingle('theme_settings', { lang: await this.environment.lang }))
			.toPromise()
			.then((theme: any) => {
				this.themeSubject.next(theme.data)
			})
			.catch(err => {
				console.error('Error fetching theme settings', err)
				if (production) {
					this.themeSubject.next({ maintenance: true })
				}
			})
	}

	async processPrismicSeo(document: PrismicDocument<Record<string, any>, string, string>) {
		console.log('Prismic SEO', document?.data?.seo_title)
		let image = '';
		if (document.data.seo_image as PrismicSEOImage) {
			image = document.data.seo_image.url
		}

		if (document?.data?.seo_title) {
			this.seoService.updateSEO(document.data.seo_title, document.data.seo_description, image)
		}
	}
}
