import { Component } from '@angular/core'
import { ShopifyService } from 'src/app/services/shopify.service'
import { UtilityService } from 'src/app/services/utility.service'
import { EnvironmentService } from '../../../services/environment.service'

@Component({
	selector: 'app-cart-toggle',
	templateUrl: './cart-toggle.component.html',
	styleUrls: ['./cart-toggle.component.scss'],
})
export class CartToggleComponent {
	country: any
	i: number = 0
	constructor(protected utilityService: UtilityService, private environmentService: EnvironmentService, private shopifyService: ShopifyService) {
		this.environmentService.observableCountry.subscribe((country: any) => {
			this.country = country
		})
		this.shopifyService.checkoutObservable.subscribe(cart => {
			if (cart?.lines?.edges?.length) {
				this.i = cart.lines.edges.length
			} else {
				this.i = 0
			}
		})
	}

	toggleCart() {
		this.utilityService.toggleCart()
	}
}
