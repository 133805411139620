import { Component, Renderer2 } from '@angular/core'
import { UtilityService } from 'src/app/services/utility.service'

@Component({
	selector: 'app-mobile-menu-toggle',
	templateUrl: './mobile-menu-toggle.component.html',
	styleUrls: ['./mobile-menu-toggle.component.scss'],
})
export class MobileMenuToggleComponent {
	menuOpen: boolean = false

	constructor(private utilityService: UtilityService, private renderer: Renderer2) {
		this.utilityService.menuStateObservable.subscribe((menusCurrentStateIsThis: boolean) => {
			this.menuOpen = menusCurrentStateIsThis
			this.renderer.setStyle(document.body, 'overflow', menusCurrentStateIsThis ? 'hidden' : 'visible')

		})
	}

	showMenu() {
		this.utilityService.toggleMenu(true)
	}

	hideMenu() {
		this.utilityService.toggleMenu(false)
	}

	toggleMenu() {
		this.menuOpen = !this.menuOpen
		if (this.menuOpen) {
			this.showMenu()
		} else {
			this.hideMenu()
		}
	}
}
