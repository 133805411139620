import { HttpClient } from '@angular/common/http'
import { AfterContentChecked, Component, ElementRef, HostListener, Input, OnInit, Output, Renderer2, ViewChild, EventEmitter, SimpleChanges } from '@angular/core'
import { from, Observable, Subject, Subscription } from 'rxjs'
import { debounceTime, switchMap } from 'rxjs/operators'
import { EnvironmentService } from 'src/app/services/environment.service'
import { UtilityService } from 'src/app/services/utility.service'
import { fade } from '../../animations'
import { CurrencyCode } from 'src/app/classes/product'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
	animations: [fade],
})
export class SearchInputComponent implements OnInit, AfterContentChecked {
	// Standalone search is for the /search route. It controls:
	// 1. Styling of the input itself (e.g. different grid layout, input background color, etc.)
	// 2. Enables infinite scrolling instead of a see more anchor button
	// 3. Automatically searches for the query string in the URL
	@Input() standaloneSearch: boolean = false

	@Input() sortValue: string = ''

	@Output() emitResults: EventEmitter<any> = new EventEmitter()
	@Output() emitQuery: EventEmitter<string> = new EventEmitter()
	@Output() emitLoading: EventEmitter<boolean> = new EventEmitter()

	show: boolean = false
	searchTerm!: string
	results: any = {}
	pageNo: number = 0
	environment
	searchSubject = new Subject()
	searchObservable = this.searchSubject.asObservable()
	subscriptionSearch: Subscription
	@ViewChild('mask') mask!: ElementRef
	@ViewChild('input') input!: ElementRef
	@ViewChild('inputFocus') inputFocus!: ElementRef
	_loading: boolean = false

	get loading(): boolean { return this._loading }

	set loading(value: boolean) {
		this._loading = value
		this.emitLoading.emit(value)
	}

	constructor(private environmentService: EnvironmentService, private utilityService: UtilityService, private http: HttpClient, private renderer: Renderer2, private route: ActivatedRoute) {
		this.environment = this.environmentService.environment
		this.environmentService.observableEnvironment.subscribe((data: any) => {
			this.environment = data
		})
		this.utilityService.searchStateObservable.subscribe(state => {
			this.show = state !== null
			if (state && !this.standaloneSearch) {
				setTimeout(() => {
					this.inputFocus.nativeElement.focus()
				}, 50)
				this.renderer.addClass(document.body, 'overflow-hidden')
			} else {
				this.renderer.removeClass(document.body, 'overflow-hidden')
			}
		})
		this.subscriptionSearch = this.searchObservable
			.pipe(debounceTime(300))
			.pipe(
				switchMap(() => from(this.utilityService.getCurrency())),
				switchMap((currencyCode) => this.runSearch(undefined, this.sortValue, 20, 20 * this.pageNo, currencyCode as CurrencyCode)))
			.subscribe(
				async (results: any) => {
					this.loading = true

					if (this.results.search === this.searchTerm) {
						this.results.products = this.results.products.concat(results.products)
					} else {
						this.results = {}
						this.results = results
						this.pageNo = 0
					}

					this.emitResults.emit(this.results)

					if (!this.standaloneSearch) {
						if (this.utilityService.getWindow()!?.innerWidth < 844) {
							this.results.products = this.results.products.slice(0, 10)
						} else {
							this.results.products = this.results.products.slice(0, 12)
						}
					}

					this.loading = false
				},
				err => {
					this.loading = false
					console.log(`Could not find products for search term: ${this.searchTerm}`, err)
					this.results = {}
				}
			)
	}

	runSearch(filters?: any, sort?: string, take?: number, skip?: number, currency?: CurrencyCode) {
		if (this.searchTerm?.length > 0) {
			return this.http.get('https://mercury.plutocracy.io/search/query/' + this.searchTerm, {
				params: {
					...(sort && { sort }),
					...(this.environment.bypassToken && { bypassToken: this.environment.bypassToken }),
					...(filters?.length > 0 && { query: JSON.stringify(filters) }),
					...(take && { take }),
					...(skip && { skip }),
					...(currency && { currency }),
				},
			})
		}
		return new Observable()
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['sortValue']) {
			this.results = {}
			this.search()
		}
	}

	ngAfterContentChecked() {
		this.onResize()
	}

	ngOnInit() {
		if (this.standaloneSearch) {
			// We *don't* need to unsubscribe from this as the Router destroys
			// the injected ActivatedRoute for us
			this.route.queryParams.subscribe(params => {
				if (params['q']) {
					this.searchTerm = params['q'];
					this.onQueryChange(this.searchTerm);
					this.search();
				}
			});
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		if (this.mask?.nativeElement && this.input?.nativeElement) {
			this.mask.nativeElement.style.bottom = '-' + (document.body.clientHeight - (this.mask.nativeElement.offsetTop + this.input.nativeElement.clientHeight)) + 'px'
		}
	}

	onQueryChange(value: string): void {
		this.emitQuery.emit(value);
		this.search();
	}

	hideSearch() {
		this.utilityService.hideSearch()
	}

	search() {
		try {
			this.loading = true

			if (this.searchTerm?.length > 0) {
				this.searchSubject.next(this.searchTerm)
			} else {
				this.results = {}
			}
		} catch (e) {
			console.log(e)
		}
	}

	clickedInside($event: Event) {
		if (this.show) {
			$event.preventDefault()
			$event.stopPropagation()
		}
	}

	loadNextPage() {
		if (this.results && !this.loading) {
			if (this.results?.facets?.count > this.results?.products?.length) {
				this.pageNo++
				this.searchSubject.next(this.searchTerm);
			}
		}
	}

	@HostListener('document:click', ['$event'])
	clickedOutside($event: any) {
		if (this.show) {
			this.hideSearch()
		}
	}
}
