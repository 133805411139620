<section class="mt-5 mb-5">
    <span class="fw-bold">Hello {{ accountInfo.firstName || 'there' }}!</span><br/>
    View a snapshot of your recent account activity and update your account information.
</section>

<section class="container p-0">
    <div class="row mt-3">
        <div class="col-md-4 mb-4">
            <section class="p-5 border h-100">
                <p class="fw-bold fs-6">Account information</p>
			    <hr/>
                <p class="fs-6 mb-4">{{ accountInfo.name }} <br/> {{ accountInfo.email }}</p>
                <a class="link-dark text-decoration-underline fs-6" routerLink="../change-password">Change Password</a>
            </section>
        </div>
        <div class="col-md-4 mb-4">
            <section class="p-5 border h-100">
                <p class="fw-bold fs-6">Addresses</p>
			    <hr/>
                <p class="fs-6 mb-4">Manage your shipping and billing addresses.</p>
                <a class="link-dark text-decoration-underline fs-6" href="#" routerLink="../account-addresses">Edit Addresses</a>
            </section>
        </div>
        <div class="col-md-4 mb-4">
            <section class="p-5 border h-100">
                <p class="fw-bold fs-6">Default Shipping Addresses</p>
			    <hr/>
                <p class="fs-6 mb-4">
                    {{ defaultAddress?.firstName }} {{ defaultAddress?.lastName }}<br/>
                    {{ defaultAddress?.address1 }} <br/>
                    {{ defaultAddress?.address2 }} <br/>
                    {{ defaultAddress?.country }} <br/>
                </p>
                <a class="link-dark text-decoration-underline fs-6" href="#" routerLink="../account-addresses">Edit Addresses</a>
            </section>
        </div>
    </div>

    <div class="row ps-2 pe-2">
        <app-recent-orders class="p-0" [orders]="orders" [showViewAllBtn]="true"
        (viewOrder)="viewOrder($event)" (viewAllOrders)="viewAllOrders()"></app-recent-orders>
    </div>
</section>