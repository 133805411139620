import { gql } from 'graphql-request';

export const gqlcheckoutFragment = gql`
    fragment CartFields on Cart {
        id
        lines(first: 50) {
            edges {
                node {
                    id
                    quantity
                    attributes {
                        key
                        value
                    }
                    merchandise {
                        ... on ProductVariant {
                            id
                            title
                            price {
                                amount
                                currencyCode
                            }
                            compareAtPrice {
                                amount
                                currencyCode
                            }
                            image {
                                url
                                altText
                                width
                                height
                            }
                            product {
                                ... on Product {
                                    id
                                    title
                                    handle
                                    vendor
                                    metafields(
                                        identifiers: [
                                            {
                                                namespace: "product"
                                                key: "preorder"
                                            }
                                            {
                                                namespace: "product"
                                                key: "backorder"
                                            }
                                        ]
                                    ) {
                                        namespace
                                        key
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        cost {
            totalAmount {
                amount
                currencyCode
            }
        }
        checkoutUrl
    }
`;

// https://shopify.dev/docs/api/storefront/2024-07/mutations/cartCreate
export const cartCreate = gql`
    # graphql
    ${gqlcheckoutFragment}
    mutation cartCreate($cartInput: CartInput!) {
        cartCreate(input: $cartInput) {
            cart {
                ...CartFields
            }
            userErrors {
                code
                field
                message
            }
        }
    }
`;

// https://shopify.dev/docs/api/storefront/2024-07/mutations/cartBuyerIdentityUpdate
export const cartBuyerIdentityUpdate = ((countryCode?: string) => {
    return gql`
        # graphql
        ${gqlcheckoutFragment}
        mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!)${countryCode ? ` @inContext(country: ${countryCode})` : ''} {
            cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
                cart {
                    ...CartFields
                    buyerIdentity {
                        countryCode
                    }
                }
                userErrors {
                    field
                    message
                }
            }
        }
    `
});

// https://shopify.dev/docs/api/storefront/2024-07/mutations/customerAccessTokenDelete
export const customerAccessTokenDelete = gql`
    # graphql
    mutation customerAccessTokenDelete($customerAccessToken: String!) {
        customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
            deletedAccessToken
            deletedCustomerAccessTokenId
            userErrors {
                field
                message
            }
        }
    }
`;

// https://shopify.dev/docs/api/storefront/2024-07/mutations/cartLinesAdd
export const cartLinesAdd = ((countryCode?: string) => {
    return gql`
        # graphql
        ${gqlcheckoutFragment}
        mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!)${countryCode ? ` @inContext(country: ${countryCode})` : ''} {
            cartLinesAdd(cartId: $cartId, lines: $lines) {
                cart {
                    ...CartFields
                }
                userErrors {
                    code
                    field
                    message
                }
            }
        }
    `;
});

export const getCart = ((countryCode?:string) => {
    return gql`
        # graphql
        ${gqlcheckoutFragment}
        query getCheckout($cartId: ID!)${countryCode ? ` @inContext(country: ${countryCode})` : ''} {
            cart(id: $cartId) {
                ...CartFields
                buyerIdentity {
                    countryCode
                }
            }
        }
    `;
});


// https://shopify.dev/docs/api/storefront/2024-07/mutations/cartLinesRemove
export const cartLinesRemove = ((countryCode?:string) => {
    return gql`
        # graphql
        ${gqlcheckoutFragment}
        mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!)${countryCode ? ` @inContext(country: ${countryCode})` : ''} {
            cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                cart {
                    ...CartFields
                }
                userErrors {
                    field
                    message
                }
            }
        }
    `;
});

// https://shopify.dev/docs/api/storefront/2024-07/mutations/cartLinesUpdate
export const checkoutLineItemsUpdate = ((countryCode?:string) => {
    return gql`
        # graphql
        ${gqlcheckoutFragment}
        mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!)${countryCode ? ` @inContext(country: ${countryCode})` : ''} {
            cartLinesUpdate(cartId: $cartId, lines: $lines) {
                cart {
                    ...CartFields
                }
                userErrors {
                    field
                    message
                }
            }
        }
    `;
});
