<div class="content white text-center" *ngIf="window.innerWidth >= 800">
	<div class="d-flex flex-row text-uppercase fw-bold border-top border-left border-right">
		<div class="h3 px-4 border-right p-3 mb-0">369 points</div>
		<div class="h4 px-4 mb-0 p-3 d-flex align-items-center">YOUR BEAN COUNTER BALANCE</div>
	</div>
	<!--<div class="d-flex flex-row row gx-0 options border-bottom border-right" [ngClass]="{ active: selected }">
		<div
			(click)="selected = option"
			[ngClass]="{ active: selected == option }"
			class="option col-12 col-md-4 border-left border-top actionable"
			*ngFor="let option of options"
		>
			<div class="d-flex flex-row flex-md-column">
				<div class="icon ratio ratio-1x1 my-auto">
					<div class="image d-flex">
						<img class="m-auto p-3 p-md-0" [src]="option.icon" />
					</div>
				</div>
				<div
					class="d-flex text-uppercase justify-content-center align-items-center white py-2 fw-bold border-md-top"
				>
					{{ option.name }}
				</div>
			</div>
		</div>
	</div>
</div>-->
<div class="mt-4 white" *ngIf="window.innerWidth < 800">
	<div class="d-flex text-uppercase fw-bold border-top border-left border-right">
		<div class="h3-mob p-3 mb-0 d-flex align-items-center icon">369</div>
		<div class="fs-12 px-4 mb-0 p-3 d-flex align-items-center">YOUR BEAN COUNTER BALANCE</div>
	</div>

	<div class="content px-x text-center">
		<div class="gx-0 border-bottom border-right">
			<div class="text col-12 border-left border-top actionable" *ngFor="let option of options">
				<div class="row">
					<div class="d-flex my-auto">
						<img class="image icon p-3 border-right" [src]="option.icon" />
						<div class="d-flex text-uppercase justify-content-center align-items-center text p-4 fw-bold">
							{{ option.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
