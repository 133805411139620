import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { takeUntil } from 'rxjs/operators'
import { Unsub } from 'src/app/common/unsub.class'
import { CustomerStoreService } from 'src/app/services/customer.store'
import { Address } from '../3-account-addresses/account-addresses.component'
import { Order } from '../recent-orders/recent-orders.component'
import { ShopifyService } from 'src/app/services/shopify.service'

@Component({
	selector: 'app-account-dashboard',
	templateUrl: './account-dashboard.component.html',
	styleUrls: ['./account-dashboard.component.scss'],
})
export class AccountDashboardComponent extends Unsub implements OnInit {
	loading = false

	accountInfo = { firstName: '', name: '', email: '' }
	address = {}
	defaultAddress!: Address
	orders: Order[] = []

	constructor(private router: Router, private customerStoreService: CustomerStoreService, private shopifyService: ShopifyService) {
		super()
	}

	ngOnInit(): void {
		this.loading = true

		this.customerStoreService.customerData$.pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
			if (!data) {
				return
			}
			this.accountInfo = {
				firstName: data?.firstName,
				name: data?.firstName + ' ' + data?.lastName,
				email: data?.email,
			}
			this.address = data?.addresses[0]
			this.defaultAddress = data?.defaultAddress
			this.orders = data.orders
			this.loading = false
		})
	}

	viewOrder(order: Order): void {
		this.router.navigate(['/accounts/my-orders/order', { order: JSON.stringify(order) }])
	}

	viewAllOrders(): void {
		this.router.navigate(['/accounts/my-orders'])
	}
}
