import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, Optional, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core'
import { REQUEST } from '../../express.tokens'
import { Observable, ReplaySubject } from 'rxjs'
import { defaultDevEnvironment, environmentAU, environmentUS, hasDefaultDevEnvironment } from 'src/environments/environment'
import { LocalStorageService } from 'src/app/services/localstorage.service'
import { SERVER_ENVIRONMENT } from './environment.token'

import type { Environment } from 'src/environments/environment'

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	subjectCountry = new ReplaySubject<any>(1)
	observableCountry = this.subjectCountry.asObservable() as any

	subjectEnvironment = new ReplaySubject<any>(1)
	observableEnvironment: Observable<Environment> = this.subjectEnvironment.asObservable()

	environment: any
	constructor(
		@Optional() @Inject(SERVER_ENVIRONMENT) public override: string,
		@Inject(DOCUMENT) private document: any,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Optional() @Inject(REQUEST) private request: any,
		private http: HttpClient,
		private transferState: TransferState,
		private localStorage: LocalStorageService,
	) {
		const headers = (this.request && this.request.headers) || {};

		// Make an HTTP request to get country information
		if (this.isBrowser()) {
			this.http.get('https://get.geojs.io/v1/ip/country.json').subscribe(
				(data: any) => {
					// { "name": string; "country_3": string; "country": string; "ip": string; }
					this.subjectCountry.next(data)
				},
				err => {
					this.subjectCountry.next({ country: 'AU', name: 'Australia' }) // Default to AU on error
				}
			)
		}

		const OVERRIDE_HOSTNAME = makeStateKey<string>('OVERRIDE_HOSTNAME')

		// Check if serverValue is in TransferState, if not, set it
		let serverValue = this.transferState.get(OVERRIDE_HOSTNAME, null)
		if (!serverValue) {
			if (this.request) {
				this.transferState.set(OVERRIDE_HOSTNAME, this.override)
			}
			serverValue = this.transferState.get(OVERRIDE_HOSTNAME, null)
		}

		// Initialize hostname
		let hostname = serverValue
		if (!hostname) {
			if (isPlatformBrowser(this.platformId)) {
				hostname = this.document.location.hostname
			}
		}

		// Check if hostname includes '.au' and set environment accordingly

		if ((hasDefaultDevEnvironment && defaultDevEnvironment.country === 'AU') || hostname?.includes('.au')) {
			console.log('AU SET')
			this.subjectEnvironment.next(environmentAU)
		} else {
			console.log('US SET')
			this.subjectEnvironment.next(environmentUS)
		}

		// Subscribe to observableEnvironment
		this.observableEnvironment.subscribe((data: any) => {
			this.environment = data
		})
	}

	isBrowser() {
		return isPlatformBrowser(this.platformId)
	}

	updateCountry(country: string): void {
		this.subjectCountry.next({ country })
	}
}
