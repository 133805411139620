import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { PrismicService } from 'src/app/services/prismic.service'

@Component({
	selector: 'app-preview',
	templateUrl: './preview.component.html',
})
export class PreviewComponent {
	constructor(private route: ActivatedRoute, private prismicService: PrismicService, private router: Router) {
		this.route.queryParams.subscribe(async data => {
			const result = await this.prismicService.getPreviewDocument()
			this.router.navigate([result])
		})
	}
}
