import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core'
import { Subscription } from 'rxjs'
import { take } from 'rxjs/operators'
import { UtilityService } from '../../services/utility.service'
import { WishlistService } from '../../services/wishlist.service'

@Directive({
	selector: '[directiveAddToWishlist]',
})
export class DirectiveAddToWishlist implements OnDestroy {
	@Input() product!: any
	@Output() emitInWishlist = new EventEmitter<boolean>()
	id: any
	email: any
	subscriptionWishlist!: Subscription

	constructor(private wishlistService: WishlistService, private utilityService: UtilityService) {
		this.id = this.wishlistService.getIdFromStorage()
		this.email = this.wishlistService.getEmailFromStorage()
	}

	ngOnDestroy() {
		this.subscriptionWishlist?.unsubscribe()
	}

	@HostListener('click')
	clickHandler() {
		this.subscriptionWishlist = this.wishlistService
			.addToWishlist(this.product, null, this.email, this.id)
			.pipe(take(1))
			.subscribe(
				(wishlist: any) => {
					this.wishlistService.setWishlistInLocalStorage(wishlist)
					this.wishlistService.setIdInStorage(wishlist?.wishlistId)
					this.utilityService.setWishlistCount(wishlist?.events?.length ? wishlist.events.length : 0)
					this.emitInWishlist.emit(true)
				},
				err => {
					console.error(err?.error?.message)
				},
				() => {}
			)
	}
}
