import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs'
import { filter, finalize, mapTo, takeUntil, tap } from 'rxjs/operators'
import { SEO } from '../../environments/seo.au'
import { EnvironmentService } from './environment.service'
@Injectable({
	providedIn: 'root',
})
export class SeoService implements OnDestroy {
	environment
	private unsubscribe$ = new Subject<void>()

	private seoTitle: string = '';
	private seoDescription: string = '';
	private seoImage: string = '';

	private canonicalHreflangSubscription: Subscription;

	constructor(@Inject(DOCUMENT) private document: Document, private meta: Meta, private title: Title, private router: Router, private environmentService: EnvironmentService) {
		this.environment = this.environmentService.environment

		// These two attributes are sensitive to NavigationEnd being complete
		this.canonicalHreflangSubscription = this.setCanonicalAndHreflang().subscribe();
	}

	private updateHreflang() {
		for (const url of SEO.reflangs) {
			for (const code of url.countryCodes) {
				this.meta.updateTag({ rel: 'alternate', hreflang: code, href: url.url + this.router.url.split('?')[0] }, `hreflang="${code}"`);
			}
		}
	}

	private setCanonicalAndHreflang(): Observable<void> {
		return this.router.events.pipe(
			takeUntil(this.unsubscribe$),
			filter(event => event instanceof NavigationEnd),
			tap(() => {
				// Code for handling NavigationEnd events
				let link = this.document.querySelector('[rel=canonical]')
				if (!link) {
					const head = this.document.getElementsByTagName('head')[0]
					link = this.document.createElement('link')
					head.appendChild(link)
				}

				this.updateHreflang();

				of(this.environment.siteUrl)
					.pipe(
						tap(siteUrl => {
							// May want to consider using router.parseUrl() instead?
							const urlWithoutQueryParams = this.router.url.split('?')[0];

							link?.setAttribute('rel', 'canonical')
							link?.setAttribute('href', `https://${siteUrl}${urlWithoutQueryParams}`)
						})
					)
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe() // Subscribe to ensure siteUrl is processed
			}),
			mapTo(undefined) // Emit a value to satisfy combineLatest
		)
	}

	updateSEO(title: string, description: string, image?: string) {
		this.seoTitle = title;
		this.seoDescription = description;
		this.seoImage = image ?? '';

		this.applySeoChanges()
	}

	private applySeoChanges() {
		if (this.seoTitle) {
		  this.title.setTitle(`${this.seoTitle} | Lo & Co`);
		}

		this.meta.updateTag({ name: 'description', content: this.seoDescription });
		this.meta.updateTag({ property: 'og:title', content: this.seoTitle });
		this.meta.updateTag({ property: 'og:site_name', content: this.environment.siteName });
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		this.meta.updateTag({ property: 'og:description', content: this.seoDescription });
		this.meta.updateTag({ property: 'og:image', content: this.seoImage });

		this.meta.updateTag({ property: 'twitter:image', content: this.seoImage });
		this.meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
		this.meta.updateTag({
			name: 'twitter:title',
			content: this.seoTitle || (this.environment.siteName as string),
		});
		this.meta.updateTag({ name: 'twitter:description', content: this.seoDescription });
	  }

	ngOnDestroy() {
		this.unsubscribe$.next()
		this.unsubscribe$.complete()

		if (this.canonicalHreflangSubscription) {
			this.canonicalHreflangSubscription.unsubscribe()
		  }
	}

	unsubscribe() {
		this.unsubscribe$.next()
	}
}
