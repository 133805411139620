import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser'
import * as prismicH from '@prismicio/client'
import { PrismicService } from 'src/app/services/prismic.service'

@Pipe({
	name: 'asRichText',
})
export class PrismicRichTextAsHtmlPipe implements PipeTransform {
	constructor(private sanitiser: DomSanitizer, private prismicService: PrismicService) {}
	transform(value: any, CustomSerialiser?: any): SafeHtml {
		try {
			if (typeof CustomSerialiser === 'function') {
				return this.sanitiser.bypassSecurityTrustHtml(prismicH.asHTML(value, { serializer: CustomSerialiser }) as string)
			}
			return this.sanitiser.bypassSecurityTrustHtml(prismicH.asHTML(value, { linkResolver: this.prismicService.linkResolver }) as string)
		} catch {
			console.error('asRichText | Not prismic rich text' + value)
			return value
		}
	}
}

@Pipe({
	name: 'handleize',
})
export class HandleizePipe implements PipeTransform {
	transform(value: string): string {
		try {
			return value.toLowerCase().split(' ').join('-')
		} catch {
			console.error('Handleize | Not a string' + value)
			return value
		}
	}
}

@Pipe({
	name: 'sanitiseURL',
})
export class SantiseURLPipe implements PipeTransform {
	constructor(private sanitiser: DomSanitizer) {}
	transform(value: string): SafeUrl {
		return this.sanitiser.bypassSecurityTrustResourceUrl(value)
	}
}

@Pipe({
	name: 'asText',
})
export class PrismicRichTextAsTextPipe implements PipeTransform {
	transform(value: any): string | null {
		try {
			return prismicH.asText(value)
		} catch {
			console.error('asText | Not prismic rich text' + value)
			return value
		}
	}
}

@Pipe({
	name: 'sanitise',
})
export class SantisePipe implements PipeTransform {
	constructor(private sanitiser: DomSanitizer) {}
	transform(value: string): SafeHtml {
		return this.sanitiser.bypassSecurityTrustHtml(value)
	}
}

@Pipe({
	name: 'PrismicImageUrl',
})
export class PrismicImagePipe implements PipeTransform {
	constructor() {}
	transform(value: string, height: number, width: number, crop?: string): string {
		return this.processSizeUrl(value, width, height)
	}

	processSizeUrl(url: string, height: number, width: number) {
		if (url) {
			url = this.replaceLast(url, '.', '_' + height + 'x' + width + '.')
		}
		return url
	}

	replaceLast(string: string, substring: string, replacement: string) {
		if (string == undefined) {
			return string
		}
		const index: number = string.lastIndexOf(substring)
		if (index == -1) {
			return string
		}
		return string.substring(0, index) + replacement + string.substring(index + substring.length)
	}
}
