<div class="" *ngIf="open" @megaMenu>
	<div [style.top.px]="offsetHeight || 0" class="mobi-nav olive justify-content-start px-4">
		<div class="closing-tag d-none d-lg-block actionable" (click)="menuClose()">
			<svg width="16" height="1" viewBox="0 0 16 1" fill="none" xmlns="http://www.w3.org/2000/svg">
				<line x1="16" y1="0.5" x2="-8.22803e-08" y2="0.499997" stroke="white" />
			</svg>
		</div>

		<div class="col-12 nav-inner col-lg-6 offset-lg-6 col-md-10 offset-md-2 pt-md-5">
			<nav @linkList class="navbar navbar-light" (click)="clickedInside($event)">
				<ul class="navbar-nav mx-auto">
					<li *ngFor="let link of theme.body2; let first = first" class="animated-link nav-item border-btm">
						<a
							*ngIf="!link.items || link.items.length == 0"
							[prismicLinkObject]="link.primary.link"
							(click)="menuClose()"
							class="nav-link nav-link-parent white"
							routerlinkactive="router-link-active"
							>{{ link.primary.menu_title }}
						</a>

						<div (click)="linkOpen(link)" *ngIf="link.items.length > 0" class="d-flex align-items-center actionable">
							<a class="nav-link nav-link-parent white">{{ link.primary.menu_title }} </a>
							<svg
								[ngClass]="{ active: link.active }"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								class="ms-auto d-flex me-2"
							>
								<rect x="23.5" y="0.5" width="23" height="23" rx="11.5" transform="rotate(90 23.5 0.5)" stroke="#EDEDED" />
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M16.2354 10.8716L15.2377 9.88477L12.0001 13.0875L8.76241 9.88477L7.76476 10.8716L12.0001 15.0612L16.2354 10.8716Z"
									fill="white"
								/>
							</svg>
						</div>
						<div *ngIf="link?.items.length > 0 && link?.active" @linkList class="flyout-menu">
							<div class="row">
								<div
									*ngFor="let childlink of link.items; let i = index"
									class="col-6 col-xl-5 flyoutlink mb-md-4 mb-3 animated-link actionable"
									(click)="menuClose()"
									[prismicLinkObject]="childlink.link"
								>
									<prismic-image *ngIf="childlink.link_image" [image]="childlink.link_image"> </prismic-image>

									<a class="nav-link-child pt-2" routerlinkactive="router-link-active">
										{{ childlink.link_text }}
									</a>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<div class="row pt-3">
					<div class="col-12 col-lg-8">
						<div class="row">
							<div class="col-6 ps-3" *ngFor="let link of theme.sub_menu">
								<li class="animated-link mb-2 socials">
									<a [prismicLinkObject]="link.link" (click)="menuClose()" class="white">{{ link.link_text }}</a>
								</li>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</div>
</div>
