import { Component, Input, OnChanges } from '@angular/core'
import { WishlistService } from 'src/app/services/wishlist.service'

@Component({
	selector: 'app-wishlist-toggle',
	templateUrl: './wishlist-toggle.component.html',
	styleUrls: ['./wishlist-toggle.component.scss'],
})
export class WishlistToggleComponent implements OnChanges {
	@Input() product: any
	@Input() style: string = ''
	inWishlist: boolean = false

	constructor(private wishlistService: WishlistService) {}

	ngOnChanges() {
		this.inWishlist = this.wishlistService.itemInWishlist(this.product.handle)
	}

	setInWishlist(itemIsInWishlist: boolean) {
		this.inWishlist = itemIsInWishlist
	}
}
